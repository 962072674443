import { Box, Button, Container, Paper, Typography } from '@mui/material';

const SaveBar = ({
  disableSaveCampaign,
  isFormDirty,
}) => {
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        py: 2,
        backgroundColor: '#005170',
      }}
      elevation={3}
    >
      <Container maxWidth='lg'>
        <Box display='flex' justifyContent='center'>
          <Button
            type="submit"
            disabled={disableSaveCampaign}
            sx={{
              py: 1,
              backgroundColor: 'white',
              color: 'inherit',
              '&:hover': { backgroundColor: 'whitesmoke' },
              fontWeight: 'bold',
            }}
            fullWidth
            variant='contained'
          >
            Save changes{' '}
            {isFormDirty && (
              <Typography
                sx={{ marginLeft: 1, color: 'red', fontWeight: 'bold' }}
              >
                (Unsaved Changes Detected)
              </Typography>
            )}
          </Button>
        </Box>
      </Container>
    </Paper>
  );
};

export default SaveBar;
