/* GENERAL IMPORTS */

import React from 'react';

/* MUI IMPORTS */

import {
  Box,
  Dialog,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';

const BranchModal = ({ open, handleClose, branchList, handleBranchSelect }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box p={2}>
        <Typography variant='h6'>Branch Submissions</Typography>
        <List>
          {branchList.map((branch, i) => (
            <React.Fragment key={i}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleBranchSelect(branch)}>
                  <ListItemText primary={branch} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        <Box mt={2}>
          <Button fullWidth variant='outlined' onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default BranchModal;
