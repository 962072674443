import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

export const CheckProductsSaved = ({
  handleClose,
  openModal,
  handleProdOpen,
}) => {
  const handleYes = () => {
    handleProdOpen(false);
  };
  return (
    <Dialog open={openModal} onClose={handleClose}>
      <DialogTitle align='center'>Have you saved your changes?</DialogTitle>
      <DialogContent>
        <DialogContentText>Any unsaved changes will be lost.</DialogContentText>
        <DialogContentText
          fontWeight='bold'
          mt={2}
          sx={{ textTransform: 'uppercase' }}
        >
          Ensure to save your changes on the next screen too.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
      >
        <Button onClick={() => handleYes()} variant='contained' fullWidth>
          Yes
        </Button>
        <Button
          onClick={handleClose}
          variant='outlined'
          color='primary'
          fullWidth
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
