import { Box, IconButton, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import { REACT_APP_URL } from "../helpers";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const CampConfigFormDateStatusMaxResultsOptions = ({
  values,
  handleChange,
  setIsFormDirty,
  touched,
  errors,
  statuses,
  duplicateCampaignSettings,
  enqueueSnackbar
}) => {
  return (
    <>
      <Box display='flex' flexDirection='column'>
        <TextField
          sx={{ mb: 3 }}
          label='Start Date/Time'
          type='datetime-local'
          value={moment(values.startDateTime).format(
            'YYYY-MM-DDTHH:mm'
          )}
          onChange={(e) => { handleChange('startDateTime')(e); setIsFormDirty(true); }}
          id={values?.campaignId?.toString()}
        />
        <TextField
          sx={{ mb: 3 }}
          label='End Date/Time'
          type='datetime-local'
          value={moment(values.endDateTime).format(
            'YYYY-MM-DDTHH:mm'
          )}
          onChange={(e) => { handleChange('endDateTime')(e); setIsFormDirty(true); }}
          id={values?.campaignId?.toString()}
        />
      </Box>

      <Box display='flex'>
        <TextField
          fullWidth
          value={values.campUrl}
          error={(touched.campUrl && Boolean(errors.campUrl)) || duplicateCampaignSettings.warningOptions.toggleText}
          helperText={(touched.campUrl && errors.campUrl) || (duplicateCampaignSettings.warningOptions.toggleText && duplicateCampaignSettings.warningOptions.warningDuplicateUrlText)}
          onChange={(e) => { handleChange('campUrl')(e); setIsFormDirty(true); }}
          label='Campaign URL'
          variant='outlined'
          // required
          id={values?.campaignId?.toString()}
          InputProps={{
            startAdornment: REACT_APP_URL,
            style: { whiteSpace: 'nowrap' },
            endAdornment: (
              <IconButton
                color='primary'
                onClick={() => {
                  enqueueSnackbar('Campaign URL copied to clipboard!', { variant: 'success' })
                  navigator.clipboard.writeText(
                    REACT_APP_URL + values.campUrl
                  );
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            ),
          }}
          disabled={values.campaignId !== ''}
        />
      </Box>

      <Box my={2}>
        <TextField
          select
          fullWidth
          label='Campaign Status'
          value={values.campStatus}
          onChange={(e) => { handleChange('campStatus')(e); setIsFormDirty(true); }}
          id={values?.campaignId?.toString()}
        >
          {statuses?.length > 0 ? (
            statuses.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.description}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Statuses Found</MenuItem>
          )}
        </TextField>
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          label='Max Results Wanted'
          placeholder='If left blank or 0, there is no cut off point'
          value={values?.campMaxResults || ''}
          onChange={(e) => { handleChange('campMaxResults')(e); setIsFormDirty(true); }}
          type='number'
        />
      </Box>
    </>
  )
}