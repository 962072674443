import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash'; // cool kids know _ is low-dash
import { AddCircle, Queue, DeleteSweep } from '@mui/icons-material';
import {
  Box,
  Typography,
  Button,
  Input,
  TextField,
  Dialog,
  Card,
  Tooltip
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AddProductStyles } from '../../../styles/addProductModal';
import { randomId } from '../../helpers';

export const AddProduct = ({
  handleClose,
  open,
  addNewProduct,
  priceOptions,
  campType,
  enqueueSnackbar,
  products,
  searchPlaceholderProduct,
  setSearchPlaceholderProduct,
}) => {

  const { REACT_APP_CLOUD_BUCKET_URL } = process.env;
  const classes = AddProductStyles();

  const [localImages, setLocalImages] = useState([{ id: uuidv4(), imageNumber: 0, image: '' }]);
  const [localPriceOptions, setLocalPriceOptions] = useState([{ value: '' }]); // setting prices locally to then set into the actual data on handle
  const [newProduct, setNewProduct] = useState({
    // push into products
    images: [
      // {
      // required
      // id: uuidv4(),
      // imageNumber: 0,
      // image: "",
      // },
    ],
    productCategory: '',
    altCode: '',
    descriptionTitle: '', // required
    descriptionText: '',
    pricingText: '',
    pricingOptions: {
      answerNodes: 0, // required
      values: [
        // {
        //   // required
        //   // id: uuidv4(),
        //   // items: "",
        // },
        '',
      ],
    },
    colour: ''
  });
  const [validation, setValidation] = useState({
    productCategory: false,
    altCode: false,
    colour: false,
    descriptionTitle: {
      show: false,
      duplicate: false
    },
    priceOptions: {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false
    }
  })

  const validateProduct = useCallback((product) => {
    let tempValidation = { ...validation }
    if (product.altCode.length === 0) {
      tempValidation = { ...tempValidation, altCode: true }
    }

    if (product.colour.length === 0) {
      tempValidation = { ...tempValidation, colour: true }
    }

    if (product.productCategory.length === 0) {
      tempValidation = { ...tempValidation, productCategory: true }
    }

    if (product.descriptionTitle.length > 0) {
      const checkForSameName = products.filter((tempProd) => tempProd.descriptionTitle === product.descriptionTitle && !tempProd.removed)

      if (checkForSameName.length > 0) {
        tempValidation = { ...tempValidation, descriptionTitle: { show: true, duplicate: true } }
      }
    } else if (campType === 'Consumer' && product.descriptionTitle.length === 0) {
      tempValidation = { ...tempValidation, descriptionTitle: { show: true, duplicate: false } }
    }

    let priceOptionError = false;
    let validationPriceOptions = { ...tempValidation.priceOptions };

    if (campType === 'Consumer') {
      let tempLocalPriceOptions = [...localPriceOptions]
      if (tempLocalPriceOptions.length < priceOptions) {

        for (let i; tempLocalPriceOptions.length < priceOptions; i++) {
          tempLocalPriceOptions.push({ value: '' });
        }
      }
      tempLocalPriceOptions.forEach((val, index) => {
        if (val.value === '') {
          validationPriceOptions = { ...validationPriceOptions, [index]: true }
        } else {
          validationPriceOptions = { ...validationPriceOptions, [index]: false }
        }
      })
      priceOptionError = Object.values(validationPriceOptions).some((item) => item === true)
    }

    if (tempValidation.altCode
      || tempValidation.colour
      || tempValidation.productCategory
      || tempValidation.descriptionTitle.show
      || priceOptionError) {
      setValidation({ ...tempValidation, priceOptions: validationPriceOptions })

      const errorMessage =
        `Error when adding new product - errors with the following field(s): 
                      ${tempValidation.productCategory ? '\nProduct Category' : ''} 
                      ${tempValidation.descriptionTitle.show ? '\nTitle Description' : ''} 
                      ${tempValidation.altCode ? '\nAlt Code' : ''}
                      ${tempValidation.colour ? '\nColour' : ''}
                      ${priceOptionError ? 'Product Options' : ''}`

      enqueueSnackbar(errorMessage, { variant: 'error' })
      return false
    } else {
      return true
    }
  }, [campType, enqueueSnackbar, localPriceOptions, priceOptions, products, validation])

  useEffect(() => {
    if (campType === 'Consumer') {
      // 3 being the minimum
      if (localPriceOptions.length < 3) {
        if (priceOptions > 0) {
          let values = [];
          for (let i; i <= priceOptions; i++) {
            values.push({ value: '' });
          }
          setLocalPriceOptions(values)
        }
      }
    } else {
      return
    }
  }, [campType, localPriceOptions.length, priceOptions])

  useEffect(() => {
    // the useeffect renders twice in local to find bugs (because of strict mode).
    // in staging/prod, the renders should only happen once.
    // this will be seen by how many times the enqueueSnackbar is shown/rendered - Andrew
    if (searchPlaceholderProduct) {
      if (newProduct.productID === searchPlaceholderProduct.productID) {
        return
      }

      setNewProduct({ ...searchPlaceholderProduct })
      setLocalImages([...searchPlaceholderProduct.images])
      validateProduct(searchPlaceholderProduct)

    } else {
      setNewProduct(
        {
          // push into products
          images: [
            // {
            // required
            // id: uuidv4(),
            // imageNumber: 0,
            // image: "",
            // },
          ],
          productCategory: '',
          altCode: '',
          descriptionTitle: '', // required
          descriptionText: '',
          pricingText: '',
          pricingOptions: {
            answerNodes: 0, // required
            values: [
              // {
              //   // required
              //   // id: uuidv4(),
              //   // items: "",
              // },
              '',
            ],
          },
          colour: ''
        }
      )
      setLocalImages({ id: uuidv4(), imageNumber: 0, image: '' })
    }
  }, [searchPlaceholderProduct, newProduct, localImages, validateProduct])

  useEffect(() => {
    manageProductOptions();
  }, [addNewProduct]);

  const manageClose = () => {
    handleClose();
    resetProduct();
    if (localImages.length > 0) {
      setLocalImages([{ id: uuidv4(), imageNumber: 0, image: '' }]);
      const tempLocalPriceOptions = [...localPriceOptions];
      tempLocalPriceOptions.map((priceOption) => {
        if (priceOption.value.length > 0) {
          priceOption.value = '';
        }
        return priceOption;
      });
    } // on handle close, reset add product stuff.
  }

  const manageProductOptions = () => {
    let productPricingOptions = { ...newProduct.pricingOptions };
    let tempProductOptions = productPricingOptions?.values
      ? [...productPricingOptions.values]
      : [];

    let tempLocalPrices =
      localPriceOptions.length > 0 ? [...localPriceOptions] : [];

    if (tempProductOptions?.length === 0) {
      for (let i = 0; i < priceOptions; i++) {
        tempProductOptions.push('');
      }
    }

    if (tempLocalPrices?.length === 0) {
      for (let i = 0; i < priceOptions; i++) {
        tempLocalPrices.push({ value: '' });
      }
    }

    if (tempProductOptions?.length > 0) {
      for (let i = 0; i < tempProductOptions?.length; i++) {
        if (tempProductOptions?.length < priceOptions) {
          tempProductOptions.push(
            //   {
            //   // required
            //   id: uuidv4(),
            //   items: "",
            // }
            ''
          );
        }
      }
    }

    if (tempLocalPrices?.length > 0) {
      for (let i = 0; i < priceOptions; i++) {
        if (tempLocalPrices.length < priceOptions) {
          tempLocalPrices.push({ value: '' });
        }
      }
    }

    setLocalPriceOptions([...tempLocalPrices]);

    productPricingOptions.values = [...tempProductOptions];

    setNewProduct({
      ...newProduct,
      pricingOptions: { ...productPricingOptions },
    });
  };

  const addPictureField = () => {
    const tempLocalPictures = localImages.length > 0 ? [...localImages] : [];
    tempLocalPictures.push({ image: '', imageNumber: 0, id: uuidv4() }); // can remove id when pushing campain
    tempLocalPictures?.forEach((item, index) => {
      item.imageNumber = index;
    });
    setLocalImages([...tempLocalPictures]);
    setNewProduct({ ...newProduct, images: [...tempLocalPictures] });
  };

  const addMultiplePictureFields = (e) => {
    const importedFiles = [...e.target.files]
    const tempLocalPictures = localImages?.length > 0 ? [...localImages] : [];

    if (tempLocalPictures.length <= 1) {
      let counter = 0
      if (tempLocalPictures.length === 1) {
        if (tempLocalPictures[0]?.image?.length === 0) {
          tempLocalPictures.pop();
        } else if (tempLocalPictures[0]?.name?.length === 0) {
          tempLocalPictures.pop();
        }
      };

      for (const file of importedFiles) {
        tempLocalPictures.push({
          id: randomId(),
          imageNumber: counter,
          image: URL.createObjectURL(file),
        });
        counter = counter++;
      };
    }

    if (tempLocalPictures.length > 5) {
      enqueueSnackbar('More than 5 products added, defaulting product selection to 5.', { variant: 'info' })
      tempLocalPictures.length = 5;
    }

    setLocalImages([...tempLocalPictures]);
    setNewProduct({ ...newProduct, images: tempLocalPictures })

  }

  const removeCurrentItem = (e, item) => {
    const tempLocalImages = [...localImages];
    if (tempLocalImages.length === 1) {
      setLocalImages([
        { id: _.cloneDeep(uuidv4()), imageNumber: 0, image: '' },
      ])
      setNewProduct({ ...newProduct, images: [{ id: _.cloneDeep(uuidv4()), imageNumber: 0, image: '' }] })
      return
    }

    const newImages = [...tempLocalImages].filter(
      (image, _index) => image.id !== item.id && item
    );

    setLocalImages(newImages.length > 0 ? newImages : { id: _.cloneDeep(uuidv4()), imageNumber: 0, image: '' });

    setNewProduct({ ...newProduct, images: newImages });
  };

  const handleNewProduct = (e) => {
    if (e.target.name === 'productImage') {
      if (e.target.files) {
        setNewProduct({
          ...newProduct,
          [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
      }
    } else if (e.target.name === 'image') {
      if (e.target.files) {
        const id = e.target.id;
        const tempLocalPictures = localImages?.length > 0 ? [...localImages] : [];

        // inner images array
        const tempExtraPictures = newProduct.images.length > 0 ? [...newProduct.images] : [];

        if (tempExtraPictures.length > 0) {
          // will need to loop through and create seperate products with if more than one image etc...

          tempExtraPictures?.forEach((item, index) => {
            if (item.id === id) {
              item.imageNumber = index;
              item.image = URL.createObjectURL(e.target.files[0]);
              // item.value = e.target.files[0].name;
            }
          });
        }

        if (tempExtraPictures.length === 0) {
          tempExtraPictures.push({
            id: id,
            imageNumber: 0,
            image: URL.createObjectURL(e.target.files[0]),
          });
        }

        // add local images state
        if (tempLocalPictures.length > 0) {
          tempLocalPictures?.forEach((item, index) => {
            if (item.id === id) {
              item.imageNumber = index;
              item.image = URL.createObjectURL(e.target.files[0]);
            }
          });
        }

        if (tempLocalPictures.length === 0) {
          tempLocalPictures.push({
            id: id,
            imageNumber: 0,
            image: URL.createObjectURL(e.target.files[0]),
          });
        }

        setLocalImages([...tempLocalPictures]);

        const tempProduct = {
          ...newProduct,
          images: [...tempLocalPictures].slice(),
        };

        setNewProduct(tempProduct);
      }
    } else if (e.target.name === 'items') {
      const id = e.target.id; // index string
      const tempPriceOptions = { ...newProduct.pricingOptions };
      const tempLocalPrices =
        localPriceOptions.length > 0 ? [...localPriceOptions] : [];
      if (tempLocalPrices.length > 0) {
        tempLocalPrices.forEach((price, index) => {
          if (id === index.toString()) {
            price.value = e.target.value;
          }
        });
      } else {
        tempLocalPrices.push({ value: e.target.value });
      }

      const pricesToCopy = [];
      tempLocalPrices.forEach((item, index) => {
        pricesToCopy.push(item.value);
      });

      setLocalPriceOptions([...tempLocalPrices]);

      tempPriceOptions.values = [...pricesToCopy];

      const itemToUpdate = [...tempPriceOptions.values].map((item, index) => {
        if (index.toString() === id) {
          return (item = e.target.value);
        } else {
          return item;
        }
      });

      // validate each individual price option
      let validationPriceOptions = { ...validation.priceOptions };
      const priceOptionLocation = itemToUpdate.findIndex((_item, ind) => {
        if (ind === parseInt(id)) {
          return ind
        }
        else {
          return -1
        }
      })

      if (priceOptionLocation !== -1) {
        // get price option value
        itemToUpdate.forEach((item, ind) => {
          validationPriceOptions = { ...validationPriceOptions, [ind]: item === '' || item.length === 0 ? true : false }
        })
      }

      setValidation({ ...validation, priceOptions: { ...validationPriceOptions } })

      setNewProduct({
        ...newProduct,
        pricingOptions: { ...tempPriceOptions },
      });
    } else if (e.target.name === 'altCode') {
      if (newProduct.altCode !== e.target.value && e.target.value.length > 0) {
        setValidation({ ...validation, altCode: false })
      } else if (e.target.value.length === 0) {
        setValidation({ ...validation, altCode: true })
      }
      setNewProduct({ ...newProduct, [e.target.name]: e.target.value.replace(/\s/g, '') });
    } else if (e.target.name === 'colour') {
      setNewProduct({ ...newProduct, [e.target.name]: e.target.value.replace(/[^a-zA-Z]+/g, '') });
      if (newProduct.altCode !== e.target.value && e.target.value.length > 0) {
        setValidation({ ...validation, colour: false })
      } else if (e.target.value.length === 0) {
        setValidation({ ...validation, colour: true })
      }
    }
    else if (e.target.name === 'productCategory') {
      setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
      if (newProduct.altCode !== e.target.value && e.target.value.length > 0) {
        setValidation({ ...validation, productCategory: false })
      } else if (e.target.value.length === 0) {
        setValidation({ ...validation, productCategory: true })
      }
    } else if (e.target.name === 'descriptionTitle') {
      const checkDescriptionTitles = products.filter((tempProd) => tempProd.descriptionTitle === e.target.value && !tempProd.removed)

      if (checkDescriptionTitles && checkDescriptionTitles.length > 0) {
        setValidation({ ...validation, descriptionTitle: { show: true, duplicate: true } })
      } else {
        setValidation({ ...validation, descriptionTitle: { show: false, duplicate: false } })
      }

      setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
    } else {
      setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
    }

    // show toast to show product added etc...
  };

  const resetProductImages = () => {
    setLocalImages([{ id: uuidv4(), imageNumber: 0, image: '' }])
    setNewProduct({ ...newProduct, images: [] })
  }

  const resetProduct = () => {
    setNewProduct({
      // push into products
      images: [
        {
          // required
          // id: uuidv4(),
          // imageNumber: 0,
          // image: "",
        },
      ],
      productCategory: '',
      altCode: '',
      descriptionTitle: '', // required
      descriptionText: '',
      pricingText: '',
      pricingOptions: {
        answerNodes: 0, // required
        values: [
          // {
          //   // required
          //   // id: uuidv4(),
          //   // items: "",
          // },
          '',
        ],
      },
      colour: ''
    });
    setLocalImages([{ id: uuidv4(), imageNumber: 0, image: '' }]);
    setSearchPlaceholderProduct({});
    if (campType === 'Consumer') {
      setLocalPriceOptions([]);
    }
    setValidation({ ...validation, altCode: false, colour: false, productCategory: false, descriptionTitle: false, pricingOptions: { '0': false, '1': false, '2': false, '3': false, '4': false } })
  };

  return (
    <>
      <Dialog open={open} onClose={manageClose} fullWidth>
        <Box
          display='flex'
          flexDirection='column'
          gap='1em'
          alignItems='center'
          style={{ overflowY: 'auto' }}
          p='1em'
        >
          <Typography variant='h4'>Add product</Typography>
          <Box display='flex' alignItems='center'>
            <Box
              display='flex'
              flexDirection='column'
              gap='1em'
              alignItems={'center'}
            >
              <Box>
                <Typography variant='h6'>Product details</Typography>
              </Box>
              <TextField
                fullWidth
                required
                label='Product Category'
                name='productCategory'
                value={newProduct?.productCategory}
                onChange={handleNewProduct}
                error={validation.productCategory}
                helperText={validation.productCategory && 'Product category required'}
              />
              <TextField
                fullWidth
                required
                label='Alt Code'
                name='altCode'
                value={newProduct?.altCode}
                onChange={handleNewProduct}
                error={validation.altCode}
                helperText={validation.altCode && 'Altcode required'}
              />
              <TextField
                fullWidth
                required
                label='Title Description'
                name='descriptionTitle'
                value={newProduct.descriptionTitle}
                onChange={handleNewProduct}
                error={validation.descriptionTitle.show}
                helperText={validation.descriptionTitle.show && validation.descriptionTitle.duplicate ? 'This title already exists in the current campaign, please try another' : validation.descriptionTitle.show && !validation.descriptionTitle.duplicate ? 'Please insert a title description' : ''}
              />
              <TextField
                fullWidth
                label='Item Description'
                name='descriptionText'
                multiline
                rows={4}
                value={newProduct.descriptionText}
                onChange={handleNewProduct}
                inputProps={{ maxLength: 250 }}
              />
              <TextField
                fullWidth
                required
                label='Item Colour'
                name='colour'
                value={newProduct.colour}
                onChange={handleNewProduct}
                error={validation.colour}
                helperText={validation.colour && 'Colour required'}
              />
              <TextField
                fullWidth
                label='Product Price'
                name='pricingText'
                value={newProduct.pricingText}
                onChange={handleNewProduct}
              />
              <Card
                variant='outlined'
                style={{
                  textAlign: 'center',
                  margin: '1em 0',
                  backgroundColor: '#FAFAFA',
                }}
              >
                <Box>
                  <Typography variant='h6' style={{ margin: '1em 0' }}>
                    Manage product images
                  </Typography>
                </Box>
                {localImages?.length > 0 ? (
                  localImages?.map((i, index) => (
                    <Card style={{ padding: '1em', margin: '1em' }} key={index}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                      >
                        {i.image || i.name ? (
                          <img src={i.image ? i.image : `${REACT_APP_CLOUD_BUCKET_URL}${i.name}`} alt='' className={classes.picture} />
                        ) : ''}
                        <Input
                          id={i.id}
                          type='file'
                          name='image'
                          onChange={handleNewProduct}
                        />
                      </Box>
                      <Button onClick={(e) => removeCurrentItem(e, i)}>
                        Remove
                      </Button>
                    </Card>
                  ))
                ) : (
                  <Input
                    key={uuidv4()}
                    id={uuidv4()}
                    type='file'
                    name='image'
                    onChange={handleNewProduct}
                  />
                )}
                <Box
                  display={'flex'}
                  justifyContent='center'
                  marginY='1em'
                  gap={'1em'}
                >
                  <Tooltip title="Add multiple products">
                    <Button
                      variant='outlined'
                      component="label"
                      disabled={localImages?.length > 1}
                    >
                      <Queue />
                      <input
                        hidden
                        type='file'
                        name='image'
                        onChange={(e) => addMultiplePictureFields(e)}
                        onClick={(event) => { event.target.value = null }}
                        multiple="multiple"
                      // inputProps={{ multiple: true }}
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add a product">
                    <Button
                      onClick={() => addPictureField()}
                      variant='outlined'
                      color='success'
                      disabled={localImages?.length === 5}
                    >
                      <AddCircle />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Remove all pictures">
                    <Button
                      onClick={() => resetProductImages()}
                      variant='outlined'
                      color='error'
                      disabled={localImages && localImages.length === 1 && localImages[0].image === ''}
                    >
                      <DeleteSweep />
                    </Button>
                  </Tooltip>
                </Box>
              </Card>
              {campType === 'Consumer' && (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='1em'
                  alignItems='center'
                  width="100%"
                >
                  <Box>
                    <Typography variant='h6'>
                      Manage product feature options
                    </Typography>
                  </Box>
                  {localPriceOptions.length > 0 &&
                    localPriceOptions.map(
                      (
                        option, // set in the campaign config ...
                        index
                      ) => (
                        <TextField
                          fullWidth
                          key={index}
                          id={index.toString()}
                          required
                          label='Product Feature Option'
                          name='items'
                          value={option.value}
                          onChange={(e) => handleNewProduct(e)}
                          helperText={validation.priceOptions[index] ? 'Insert a pricing option' : ''}
                          error={validation.priceOptions[index]}
                        />
                      )
                    )}
                </Box>
              )}
              <Box sx={{ display: "flex", width: "100%", gap: "1em" }}>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    const isValid = validateProduct(newProduct)
                    if (isValid) {
                      addNewProduct(newProduct, resetProduct)
                    } else {
                      return
                    }
                  }}
                  variant='contained'
                  color='primary'
                >
                  Add product
                </Button>
                <Button sx={{ width: "100%" }} onClick={manageClose} variant='outlined' color='primary'>
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Box >
      </Dialog >
    </>
  );
};
