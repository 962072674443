import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Box
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { dateVariants, params, questionSorter, REACT_APP_API_BASE_URI } from '../../helpers';
import { DeleteQuestion } from './deleteQuestion';

export const ManageQuestions = ({
  open,
  handleClose,
  campData,
  localQuestions,
  fetchAllQuestions,
  setFormikFields,
  enqueueSnackbar
}) => {
  const [newQuestion, setNewQuestion] = useState({
    value: '', // required - the actual question
    type: 0, // required - type of question -  1 text field? - 2 multiple choice ? - 3 bar scale from 0 - 100
    answers: { answers: [] /* answers array of type string */ }, // maybe append an id?
  });
  // State for the 'select question' and 'select question type' textfields
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [updatingQuestion, setUpdatingQuestion] = useState(false);
  const [questionType, setQuestionType] = useState(1);
  const [localAnswers, setLocalAnswers] = useState([
    {
      value: '',
    },
  ]);
  const [globalQuestions, setGlobalQuestions] = useState([]);
  const [sortedGlobalQuestions, setSortedGlobalQuestions] = useState([]);
  const [globalQuestionNames, setGlobalQuestionNames] = useState([]);
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(false);
  const [campaignQuestionTypes, setCampaignQuestionTypes] = useState([]);
  const [dateVariant, setDateVariant] = useState('')

  const [dupQuestion, setDupQuestion] = useState(false);

  const handleDeleteModalOpen = () => {
    setDeleteQuestionModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteQuestionModal(false);
  };

  const getCampaignQuestions = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}campaignQuestions`,
        params
      );
      response.data.response.forEach((item) => {
        item.answers = JSON.parse(item.answer);
      });

      setGlobalQuestions([...response.data.response]);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error when getting campaign questions.', { variant: 'error' })
    }
  };

  const getCampaignQuestionTypes = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}campaignQuestionTypes`,
        params
      );

      setCampaignQuestionTypes([...response.data.response]);
    } catch (err) {
      enqueueSnackbar('Error when fetching campaign question types.', { variant: 'error' })
      console.error(err);
    }
  };

  const postQuestion = async (question) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}postQuestion`,
        question,
        params
      );

      if (response.status === 200) {
        enqueueSnackbar('Question added.', { variant: 'success' })
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error when adding question.', { variant: 'error' })
    }
  };

  const updateQuestion = async (question) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_BASE_URI}putQuestion`,
        question,
        params
      );

      if (response.status === 200) {
        enqueueSnackbar('Question updated.', { variant: 'success' })
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error when updating question.', { variant: 'error' })
    }
  };

  // formik the answers/questions?
  const checkForAnswerDuplicates = () => {
    const tempLocalAnswers = [...localAnswers];
    let duplicateAnswersExist = false;

    const localAnswerValues = tempLocalAnswers.map((ans) => ans.value);

    // call some function with callback function as argument
    duplicateAnswersExist = localAnswerValues.some((element, index) => {
      return localAnswerValues.indexOf(element) !== index;
    });

    const emptyAnswerExists = localAnswerValues.some((ele, index) =>
      ele.length === 0 ? true : false
    );

    if (emptyAnswerExists && duplicateAnswersExist) {
      return true;
    } else if (!emptyAnswerExists && duplicateAnswersExist) {
      return duplicateAnswersExist;
    } else if (emptyAnswerExists && !duplicateAnswersExist) {
      return emptyAnswerExists;
    } else {
      return false;
    }
  };

  const checkForQuestionNameDuplicatesAndUpdate = (e) => {
    let duplicateQuestionName;

    if (updatingQuestion) {
      duplicateQuestionName = [...globalQuestionNames].filter(question => question.id !== selectedQuestion.id).some((element, index) => element.value === e.target.value);
    } else {
      // else if a new question then do the checks below...
      duplicateQuestionName = localQuestions.some((element, index) => element.value === e.target.value);
    }

    duplicateQuestionName ? setDupQuestion(true) : setDupQuestion(false)

    if (newQuestion.type === 7) {
      setNewQuestion({ ...newQuestion, value: e.target.value, variant: dateVariant })
    } else {
      const { variant, ...rest } = newQuestion;
      setNewQuestion({ ...rest, value: e.target.value })
    }

    return duplicateQuestionName;
  }

  const addNewQuestions = async () => {
    if (dupQuestion) {
      enqueueSnackbar('Please provide a unique question name.', { variant: 'error' })
      return;
    }

    let currentNewQuestion = { ...newQuestion }; // copy so i can change data
    const tempLocalAnswers = [...localAnswers];
    let updatedQuestions = [];

    let duplicateAnswersExist = checkForAnswerDuplicates();
    // if there are no answer duplicates and the length of the question is fine... then proceed to add or update.
    if (currentNewQuestion.value.length > 0 && !duplicateAnswersExist) {
      if (currentNewQuestion.type === 2 || currentNewQuestion.type === 5) {
        currentNewQuestion.answers.answers = [];
        tempLocalAnswers.forEach((item) => {
          currentNewQuestion.answers.answers.push(item.value);
        });
      }

      if (campData.questions.length > 0) {
        // if there are more than 0 selected questions for a campaign...

        const checkItems = [...campData.questions].some(
          (item) => currentNewQuestion.value === item.value
        );

        // check if the value is equal to any of the selected questions...

        // if it is equal to any of the selected questions ...
        if (checkItems) {
          // then update...
          updatedQuestions = [...campData.questions].map((item, index) =>
            currentNewQuestion.value === item.value
              ? (item = currentNewQuestion)
              : item
          );
        }
      }

      // if there are no selected questions ... thne leave and push to api.
      if (campData.questions.length === 0) {
        updatedQuestions = [];
        // updatedQuestions.push({ ...currentNewQuestion });
      }

      const localCampData = { ...campData };
      localCampData.questions = [...updatedQuestions];

      setFormikFields('questions', updatedQuestions)

      const questionToPost = { question: { ...currentNewQuestion } };

      // destructure 'answer' from object and re-assign.
      const { answer, exists, ...q } = questionToPost.question;
      questionToPost.question = { ...q };

      // Check if question id exists...
      const CheckQuestionExists = localQuestions.some(
        (globQuestion) => globQuestion.id === questionToPost.question.id
      );

      // if the current question does not exist within the questions, then post.
      if (!CheckQuestionExists) {
        await postQuestion(questionToPost).then(() => {
          fetchAllQuestions();
        });
      } else {
        await updateQuestion(questionToPost).then(() => {
          fetchAllQuestions();
          setUpdatingQuestion(false);
        });
      }

      // reset after post
      resetQuestionInput();
      setQuestionType(1);
      // setLocalQuestions([...updatedQuestions]);
      setLocalAnswers([
        {
          value: '',
        },
      ]);
    } else {
      // throw error
      enqueueSnackbar('Error, check question fields.', { variant: 'error' })
    }
  };

  const handleMultipleChoiceAnswer = (e) => {
    const globalIndex = e.target.id;
    let tempLocalAnswers = [...localAnswers];
    if (tempLocalAnswers.length > 0) {
      tempLocalAnswers.forEach((item, index) => {
        if (index.toString() === globalIndex) {
          item.value = e.target.value;
        }
      });
    } else {
      tempLocalAnswers.push({ value: '' });
    }
    setLocalAnswers([...tempLocalAnswers]);
    // then on save - set the local answers to the campData.
  };

  const removeMultipleChoiceAnswer = (e, id) => {
    const tempLocalAnswers = [...localAnswers];
    tempLocalAnswers.map((item, index) =>
      index === id ? tempLocalAnswers.splice(index, 1) : item
    );
    setLocalAnswers([...tempLocalAnswers]);
  };

  const addMultipleChoiceAnswer = (e) => {
    // temp local answer array ...
    const tempLocalAnswers = [...localAnswers];
    tempLocalAnswers.push({ value: '' });
    setLocalAnswers([...tempLocalAnswers]);

    // set theses answers to the setNewQuestion answers
  };

  const resetQuestionInput = () => {
    setNewQuestion({
      value: '', // required - the actual question
      type: 1, // required - type of question -  1 text field? - 2 multiple choice ? - 3 bar scale from 0 - 100
      answers: { answers: [] /* answers array of type string */ }, // maybe append an id?
    });
    setQuestionType(1);
    setSelectedQuestion('');
  }

  const managePreExistingQuestionsOnClick = (indexParam, { type }) => {
    const questionsTemp = [...localQuestions];
    setQuestionType(type);

    const selectQuestion = questionsTemp.filter(
      (item, index) => index === indexParam && item
    )[0]; // hopefully shows the correct item?

    selectQuestion.exists = true;

    let tempLocalAnswers = localAnswers.length > 0 ? [...localAnswers] : [];
    newQuestion.answers?.answers.forEach((item, index) => {
      tempLocalAnswers.push({ value: item }); // works when i click twice, useEffect?
    });

    setLocalAnswers(tempLocalAnswers);
    setQuestionType(selectQuestion.type); // reset question type on select to text
    setNewQuestion({ ...selectQuestion });
    setUpdatingQuestion(true);

    if (selectQuestion.type === 7) {
      // when stored on backend and sent to front end... store here
      setDateVariant(selectQuestion.variant)
    }
  };

  const questionTypeOnClick = (e, value) => {
    if (value !== 2 || value !== 5) {
      setNewQuestion({ ...newQuestion, type: value });
      return;
    }

    const answersTemp = { ...newQuestion.answers };
    const tempAnswerData =
      answersTemp.answers?.length > 0 ? [...answersTemp.answers] : [];

    if (
      tempAnswerData.length === 0 ||
      tempAnswerData.length < campData.typePriceOption
    ) {
      for (let i = 0; i < campData.typePriceOption; i++) {
        tempAnswerData.push('');
      }
    } else if (tempAnswerData.length > campData.typePriceOption) {
      for (let i = 0; i > campData.typePriceOption; i--) {
        tempAnswerData.pop();
      }
    }
    answersTemp.answers = [...tempAnswerData];
    setNewQuestion({ ...newQuestion, type: value, answers: { ...answersTemp } });

    // temp local answer array ...
    const tempLocalAnswers = [...localAnswers];

    if (tempLocalAnswers.length < 3) {
      tempLocalAnswers.push({ value: '' });
    } else if (tempLocalAnswers.length > 3) {
      tempLocalAnswers.pop();
    }

    setLocalAnswers([...tempLocalAnswers]);
    // set theses answers to the setNewQuestion answers
  };

  useEffect(() => {
    fetchAllQuestions();
    getCampaignQuestionTypes();
  }, []);

  useEffect(() => {
    if (globalQuestions.length > 0) {
      const sortedList = [...globalQuestions].sort(questionSorter);
      const sortedNameList = [...globalQuestions].map((globQuestions) => { return { value: globQuestions.value, id: globQuestions.id } }).sort(questionSorter);
      setGlobalQuestionNames(sortedNameList);
      setSortedGlobalQuestions(sortedList);
    }
  }, [globalQuestions]);

  useEffect(() => {
    resetQuestionInput();
  }, [handleClose]);

  useEffect(() => {
    getCampaignQuestions();
  }, [localQuestions]);

  useEffect(() => {
    let tempLocalAnswers = localAnswers.length > 0 ? [...localAnswers] : [];
    if (tempLocalAnswers.length > 0) {
      tempLocalAnswers = []; // this causes the newQuestion to reset when entering a question
    }

    if (typeof newQuestion?.answers !== 'undefined') {
      newQuestion.answers?.answers?.forEach((item, index) => {
        tempLocalAnswers.push({ value: item }); // works when i click twice, useEffect?
      });
    } else if (newQuestion?.answer?.answers.length > 0) {
      newQuestion.answer?.answers?.forEach((item, index) => {
        tempLocalAnswers.push({ value: item });
      });
    }
    setLocalAnswers([...tempLocalAnswers]);
  }, [newQuestion]);

  return (
    <>
      {' '}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Box padding={3}>
          <Box marginBottom='1em'>
            <Typography variant='h4' textAlign='center'>
              Manage Questions
            </Typography>
          </Box>
          <Box marginBottom='1em'>
            <TextField
              fullWidth
              label='Select Question to Update'
              select
              value={selectedQuestion || ''}
              onChange={(e) => setSelectedQuestion(e.target.value)}
            >
              {sortedGlobalQuestions.length > 0 ? (
                sortedGlobalQuestions.map((question, index) => (
                  <MenuItem
                    key={index}
                    id={index.toString()}
                    value={question}
                    onClick={() => managePreExistingQuestionsOnClick(index, question)}
                  >
                    {`${question.value} - ${campaignQuestionTypes[question.type - 1]?.description
                      }`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Questions Found</MenuItem>
              )}
            </TextField>
          </Box>
          <Box marginBottom='1em'>
            <Typography>Or enter a new question</Typography>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label='Enter question'
                value={newQuestion.value}
                error={newQuestion.value.length === 0 || dupQuestion ? true : false}
                helperText={newQuestion.value.length === 0 ? 'Please enter a valid question' : dupQuestion ? 'Please insert a unique question name.' : ' '}
                onChange={(e) => { checkForQuestionNameDuplicatesAndUpdate(e) }
                }
              />
            </FormControl>
          </Box>

          <Box>
            {(newQuestion.type === 7) && (
              <>
                <Box margin='1em 0'>
                  <TextField
                    fullWidth
                    label='Select Date Variant'
                    select
                    value={dateVariant || ''}
                    onChange={(e) => {
                      setDateVariant(e.target.value);
                      setNewQuestion({ ...newQuestion, variant: e.target.value })
                    }}
                  >
                    {dateVariants && dateVariants.length > 0 ? (
                      dateVariants.map((variant, index) => (
                        <MenuItem
                          key={variant.id}
                          id={variant.id}
                          value={variant.variant}
                          onClick={(e) => setDateVariant(e.target.value)}
                        >
                          {`${variant.variant}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Variants</MenuItem>
                    )}
                  </TextField>
                </Box>
              </>
            )}
          </Box>

          <Box marginY={'1em'}>
            <TextField
              fullWidth
              label='Select Question Type'
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              select
            >
              {campaignQuestionTypes.map((qType, index) => (<MenuItem key={qType.id} value={qType.id} onClick={e => questionTypeOnClick(e, qType.id)}>{qType.description}</MenuItem>))}
            </TextField>
          </Box>
          <Box marginY={'1em'}>
            {/* text input */}

            {/* multiple choice */}
            {(newQuestion.type === 2 || newQuestion.type === 5) && (
              <>
                {localAnswers?.length > 0 && // too add to...
                  localAnswers.map((answer, index) =>
                    <Box key={index.toString()} display='flex'>
                      <TextField
                        fullWidth
                        placeholder={`answer ${index + 1}`}
                        id={index.toString()}
                        value={answer.value}
                        onChange={(e) => handleMultipleChoiceAnswer(e)}
                        error={
                          answer.value.length === 0
                            ? true
                            : checkForAnswerDuplicates()
                              ? true
                              : false
                        }
                        helperText={
                          answer.value.length === 0
                            ? 'Please enter an answer'
                            : checkForAnswerDuplicates()
                              ? 'Duplicate/empty answers found. Please check your answers.'
                              : ' '
                        }
                      />
                      <Button
                        onClick={(e) => removeMultipleChoiceAnswer(e, index)}
                        style={{ marginBottom: '2em' }}
                      >
                        <Delete fontSize='large' />
                      </Button>
                    </Box>)}
                <Button onClick={(e) => addMultipleChoiceAnswer(e)}>
                  Add Answer
                </Button>
              </>
            )}
          </Box>
          <Box>
            <Box display='flex' gap='1em'>
              <Button fullWidth variant='contained' onClick={addNewQuestions}>
                Save Question
              </Button>
              <Button
                fullWidth
                variant='contained'
                color='error'
                onClick={handleDeleteModalOpen}
                disabled={
                  selectedQuestion?.value?.length === 0 ||
                    selectedQuestion?.value === undefined
                    ? true
                    : false
                }
              >
                Delete Question
              </Button>
            </Box>
            <Button
              style={{ marginTop: '1em' }}
              fullWidth
              onClick={handleClose}
              variant='outlined'
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog >
      <DeleteQuestion
        fetchAllQuestions={fetchAllQuestions}
        resetQuestionInput={resetQuestionInput}
        openModal={deleteQuestionModal}
        handleClose={handleDeleteModalClose}
        question={selectedQuestion}
        enqueueSnackbar={enqueueSnackbar}
      />
    </>
  );
};
