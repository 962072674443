/* General Imports */

import { useState } from 'react';

/* MUI Imports */

import {
  TableContainer,
  Typography,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import BranchModal from './Modals/BranchModal';

const getCampaignStatus = (value) => {
  if (
    (value.campStatus === 4 &&
      new Date() >= new Date(value.startDateTime) &&
      new Date() < new Date(value.endDateTime)) ||
    value.campStatus === 3
  ) {
    return 'Active';
  } else if (
    (value.campStatus === 4 && new Date() > new Date(value.endDateTime)) ||
    value.campStatus === 1
  ) {
    return 'Ended';
  } else {
    return 'Pending';
  }
};

const CampaignDetails = ({
  campData,
  completions,
  branchCodeList,
  handleBranchSelect,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Campaign Name:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{campData.campName}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Campaign Type:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{campData.campType}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>Start Date:</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {new Date(campData.startDateTime).toLocaleDateString()}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>End Date:</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {new Date(campData.endDateTime).toLocaleDateString()}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Campaign Status:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{getCampaignStatus(campData)}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>
                  No of Submissions:
                </Typography>
              </TableCell>
              <TableCell>
                <Box display='flex'>
                  <Typography>{completions}</Typography>
                  {campData.campType === 'Management' && (
                    <LaunchIcon
                      sx={{ ml: 1, cursor: 'pointer' }}
                      onClick={() => setModalOpen(true)}
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <BranchModal
        open={modalOpen}
        handleClose={handleClose}
        branchList={branchCodeList}
        handleBranchSelect={handleBranchSelect}
      />
    </>
  );
};

export default CampaignDetails;
