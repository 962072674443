import {
  BottomNavigation,
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Reorder, motion } from 'framer-motion';
import { CampProduct } from '../components/CampaignProduct/campaignProduct';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddProduct } from '../components/CampaignProduct/Modals/addProduct';
import { SearchProducts } from '../components/CampaignProduct/Modals/searchProducts';
import _ from 'lodash'; // cool kids know _ is low-dash
import { v4 as uuidv4 } from 'uuid';
import CampaignSnackbar from '../components/globals/CampaignSnackBar';
import { CheckProductsSaved } from '../components/CampaignProduct/Modals/checkProductsSaved';

const CampProducts = ({
  handleProdOpen,
  campData,
  setFormikFields,
  previousProducts,
  currentCampDataStatus,
  setIsFormDirty,
  enqueueSnackbar
}) => {

  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]); // set from campaign url
  const [priceLength, setPriceLength] = useState(3); // three by default.
  const { typePriceOption } = campData;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [openCheckProductsSavedModal, setOpenCheckProductsSavedModal] = useState(false);
  const [searchPlaceholderProduct, setSearchPlaceholderProduct] = useState({});


  useEffect(() => {
    if (
      campData.campType !== 'Consumer' &&
      campData.campType !== 'Management'
    ) {
      enqueueSnackbar('Please ensure that you have selected a campaign type on the config screen, and try again.', { variant: 'error' })
      return;
    } else {
      const backupPriceLength = previousProductPriceRange();
      setPriceLength(backupPriceLength);
      if (campData?.campProducts?.length > 0) {
        setProducts(campData?.campProducts);
      }
    }
  }, []);

  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const previousProductPriceRange = () => {
    // gets previous product price range if pulled from api
    if (products.length > 0) {
      return products[0]?.pricingOptions?.values?.length;
    } else if (products.length > 0 && campData?.typePriceOption !== undefined) {
      return campData?.typePriceOption
        ? campData?.typePriceOption
        : products[0]?.pricingOptions?.values?.length; // or the default set locally...
    } else {
      return campData?.typePriceOption
        ? campData?.typePriceOption
        : campData?.campPriceRankingOptions?.length; // rather than static set of three, return the array length from priceOptions...
    }
  };

  const addNewProduct = (product, resetProduct) => {
    let prod = { ...product };

    prod.productID = _.cloneDeep(uuidv4());

    const tempProducts = [...products];

    const tempImages = [...prod.images];

    tempImages?.forEach((item, index) => {
      item.imageNumber = index;
    });

    tempProducts.push(prod);

    setProducts(tempProducts);

    setFormikFields('campProducts', tempProducts);

    handleModalClose();

    resetProduct();
  };

  const editNewProduct = (product) => {
    const tempProducts = [...products];
    const updatedProducts = tempProducts?.map((item) => {
      if (item.productID === product.productID) {
        item = product;
      }

      return item
    }
      // item.productID === product.productID ? (item = product) : item
    );

    setProducts(...[updatedProducts]);
    // setCampData({ ...campData, campProducts: updatedProducts });
    setFormikFields('campProducts', updatedProducts);
  };

  const deleteNewProduct = (product) => {
    const tempProducts = [...products];
    tempProducts.forEach((prod) => {
      if (prod.productID === product.productID) {
        product.removed = true;
      }
    });

    setProducts(...[tempProducts]);
    // setCampData({ ...campData, campProducts: tempProducts });
    setFormikFields('campProducts', tempProducts);
  };

  const duplicateProduct = (product, allPictures) => {
    let newImages = _.cloneDeep(allPictures); // deep copy via lodash
    newImages.forEach((image) => {
      image.id = '';
    });

    const prod = Object.assign({}, { ...product });

    prod.productID = _.cloneDeep(uuidv4());

    const updatedImages = newImages.map((item) => {
      const newImageId = _.cloneDeep(uuidv4());
      item.id = newImageId;
      return item;
    });

    // because its a duplicate, it will already exist in the list..
    // will proceed to open add product dialog
    setSearchPlaceholderProduct({ ...prod, images: [...updatedImages] })
    setOpenModal(true)

  };

  const searchForProduct = (e, value) => {
    if (!value) return;
    if (previousProducts.length > 0) {

      const tempProducts = _.cloneDeep(previousProducts);
      // const tempProducts = [...previousProducts];
      tempProducts.map((prevProduct) => prevProduct);

      // const itemsToAddToProducts = _.cloneDeep(products); // get current product
      const itemsToAddToProducts = [...products];
      const searchedProduct = tempProducts.filter((tempProduct) => {
        if (tempProduct.descriptionTitle === value.descriptionTitle) {
          if (
            campData.campType === 'Consumer' &&
            tempProduct.pricingOptions?.length !== 0
          ) {
            tempProduct.pricingOptions.values = [];
          }
          tempProduct.productID = uuidv4();
          // return _.cloneDeep(tempProduct);
          return tempProduct;
        }
      })[0];

      if (searchedProduct) {
        const { colour, altCode, descriptionTitle, productCategory } = searchedProduct;
        if (
          colour === '' ||
          altCode === '' ||
          descriptionTitle === '' ||
          products.filter((prod => prod.descriptionTitle === descriptionTitle)) ||
          productCategory === '') {
          setSearchPlaceholderProduct({ ...searchedProduct })
          setOpenModal(true);
        } else {
          itemsToAddToProducts.push(searchedProduct);
          enqueueSnackbar('Search Product Added', { variant: 'success' })
        }
      }

      setProducts([...itemsToAddToProducts]); // add back to state
      setSearchInputValue('');
    }
  };

  const productReorder = (reorder) => {
    setProducts([...reorder]);
    // setCampData({ ...campData, campProducts: [...reorder] });
    setFormikFields('campProducts', reorder);
  };

  const postProducts = () => {
    const tempProducts = [...products].map((product) => {
      product.brand = campData.campBrand; // sets all products with the current camp brand set in config.
      return product;
    });

    if (products.length > 0) {
      // setCampData({ ...campData, campProducts: _.cloneDeep(tempProducts) }); // saving to state locally
      setFormikFields('campProducts', tempProducts);
      enqueueSnackbar('Products Saved.', { variant: 'success' })
      setIsFormDirty(true);
    }
  };

  const handleCheckProductsSavedModalOpen = () => {
    if (currentCampDataStatus !== 3) {
      setOpenCheckProductsSavedModal(true);
    } else {
      handleProdOpen(false);
    }
  };
  const handleCheckProductsSavedModalClose = () => {
    setOpenCheckProductsSavedModal(false);
  };

  return (
    <>
      <Box textAlign='center' marginY={'1em'}>
        <Typography variant='h2'>Campaign Products</Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={
          currentCampDataStatus === 3
            ? {
              width: '100%',
              height: '50px',
              backgroundColor: '#FF4D4D',
              margin: '1em 0',
            }
            : {}
        }
      >
        <Typography variant='h5'>
          {currentCampDataStatus === 3 ? 'Read Only' : ''}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' marginY={'1em'}>
        {previousProducts.length > 0 && (
          <SearchProducts
            disabled={campData.campStatus === 3}
            searchProduct={searchForProduct}
            campType={campData.campType}
            products={previousProducts}
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
            currentCampDataStatus={currentCampDataStatus}
          />
        )}
        <Box>
          <Button
            onClick={handleModalOpen}
            startIcon={<AddCircleIcon />}
            style={{ paddingRight: 0 }}
            disabled={currentCampDataStatus === 3}
          >
            <Typography variant='body'>Add New Product</Typography>
          </Button>
          <Box display={'flex'} flexDirection={'row-reverse'}>
            <Typography variant='body'>
              Total products :{' '}
              {products.filter((product) => !product.removed).length}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box textAlign='center' style={{ backgroundColor: '#FAFAFA' }}>
        <motion.div
          layoutScroll
          style={{ overflow: 'auto', height: '70vh', width: '100%' }}
        >
          <Reorder.Group
            axis='y'
            onReorder={(reorder) => productReorder(reorder)}
            values={products}
            style={{ padding: 0, marginRight: '1em', marginLeft: '1em' }}
          >
            {products?.length > 0 &&
              products?.map(
                (item, index) => {
                  return !item.removed && (
                    <CampProduct
                      currentCampDataStatus={currentCampDataStatus}
                      key={item.productID}
                      globalIndex={index}
                      item={item}
                      editNewProduct={editNewProduct}
                      deleteNewProduct={deleteNewProduct}
                      duplicateProduct={duplicateProduct}
                      priceOptions={
                        campData.typePriceOption
                          ? campData.typePriceOption
                          : item?.pricingOptions?.values?.length
                      }
                      campType={campData.campType}
                      enqueueSnackbar={enqueueSnackbar}
                      products={products}
                    />
                  )
                }
              )}
          </Reorder.Group>
        </motion.div>
      </Box>
      <AddProduct
        open={openModal}
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        addNewProduct={addNewProduct}
        priceOptions={
          campData.typePriceOption > 0
            ? campData.typePriceOption
            : typePriceOption > 0
              ? typePriceOption
              : priceLength
        }
        campType={campData.campType}
        enqueueSnackbar={enqueueSnackbar}
        products={products}
        searchPlaceholderProduct={searchPlaceholderProduct}
        setSearchPlaceholderProduct={setSearchPlaceholderProduct}
      />
      <Container maxWidth='xl '>
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation>
            <Box display='flex' flex={1} justifyContent='center'>
              <Button
                sx={{ marginX: 2, marginY: 1, width: '40%' }}
                fullWidth
                variant='contained'
                onClick={postProducts}
                disabled={currentCampDataStatus == 3}
              >
                Save Products
              </Button>
              <Button
                sx={{ marginX: 2, marginY: 1, width: '40%' }}
                fullWidth
                onClick={handleCheckProductsSavedModalOpen}
                variant='outlined'
              >
                Close
              </Button>
            </Box>
          </BottomNavigation>
        </Paper>
      </Container>
      <CheckProductsSaved
        openModal={openCheckProductsSavedModal}
        handleClose={handleCheckProductsSavedModalClose}
        handleProdOpen={handleProdOpen}
      />
    </>
  );
};
export default CampProducts;
