/* General Imports */

import React from 'react';

/* MUI Imports */

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

/* App Imports */

import ManagementReportRow from './ManagementReportRow';
import ConsumerReportRow from './ConsumerReportRow';

const ReportTable = ({ reportObj, campDocs, campType }) => {
  return (
    <>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell sx={{ minWidth: '350px' }} />
                <TableCell sx={{ minWidth: '170px' }} />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {campType === 'Management'
                ? reportObj?.products?.map((prod, i) => (
                  <ManagementReportRow
                    key={i}
                    product={prod}
                    campDocs={campDocs}
                  />
                ))
                : reportObj?.products?.map((prod, i) => (
                  <ConsumerReportRow
                    key={i}
                    product={prod}
                    campDocs={campDocs}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default ReportTable;