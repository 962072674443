/* MUI Imports */

import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

/* App Imports */

import { TableHeaderCell } from '../../../styles/miscStyles';

const CommentsModal = ({ open, handleClose, comments, type }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      fullWidth
    >
      <Box p={2}>
        <Box>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Comments
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {type === 'Management' ? (
                    <>
                      <TableHeaderCell>Branch Code</TableHeaderCell>
                      <TableHeaderCell>Comment</TableHeaderCell>
                    </>
                  ) : (
                    <>
                      <TableHeaderCell>Submission</TableHeaderCell>
                      <TableHeaderCell>Comment</TableHeaderCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {type === 'Management'
                  ? comments.map((com) => (
                      <TableRow key={com.branchCode}>
                        <TableCell>{com.branchCode}</TableCell>
                        <TableCell>{com.comment}</TableCell>
                      </TableRow>
                    ))
                  : comments.map((com) => (
                      <TableRow key={com.submission}>
                        <TableCell>{com.submission}</TableCell>
                        <TableCell>{com.comment}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={2}>
          <Button fullWidth variant='outlined' onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CommentsModal;
