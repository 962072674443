import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

const ConfirmChange = ({ open, handleClose, setProceedConfirmation }) => {
  const handleProceed = () => {
    setProceedConfirmation(true);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle align='center' sx={{ color: 'error.main' }}>
        You have unsaved changes!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you navigate away from the current campaign now, any unsaved
          changes will be lost.
        </DialogContentText>
        <DialogContentText mt={2}>
          Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
      >
        <Button variant='contained' fullWidth onClick={handleProceed}>
          Yes
        </Button>
        <Button variant='outlined' fullWidth onClick={handleClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChange;
