import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Circle } from '@mui/icons-material';

import ConfirmChange from './Modals/ConfirmChange';
import { addNewCampObject, sorter } from '../helpers';


const CampHeader = ({
  setCampId,
  data,
  setCampData,
  campDataList,
  setCampDataList,
  handleIncomingSelectedQuestions,
  resetCampaign,
  setIsANewCampaign,
  focusOnNewCampaignName,
  handleCurrentStatus,
  handleCurrentStatusLabel,
  isFormDirty,
  setIsFormDirty,
  setFormikValues,
  setFormikFields,
  formikRef,
  formikSetErrors,
  formikSetTouched,
  duplicateCampaignSettings,
  setDuplicateCampaignSettings,
  originalCampStatus,
  setOriginalCampStatus,
  originalSurveyOnly,
  setOriginalSurveyOnly,
  setNewCampaignId,
  campaignNameList,
  setCampaignNameList,
  originalCampStartDate,
  setOriginalCampStartDate,
  setOriginalCampEndDate
}) => {

  // Hack to clear out the autocomplete value
  const [key, setKey] = useState(new Date());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [proceedConfirmation, setProceedConfirmation] = useState(false);
  const [sortedCampDataList, setSortedCampDataList] = useState();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleChange = (newValue) => {
    setSelectedCampaign(newValue);
    if (isFormDirty) {
      setDialogOpen(true);
    } else {
      setProceedConfirmation(true);
    }
  };

  const handleCampaignOnSelect = async () => {
    if (!proceedConfirmation) {
      return;
    }

    let value = {};

    resetCampaign(); //  think this is fixing the not showing when switching campaigns
    formikSetTouched({}, false);
    setIsFormDirty(false);

    if (selectedCampaign.campName === 'Add New Campaign') {
      resetCampaign();
      setIsANewCampaign(true);
      focusOnNewCampaignName();
      setDuplicateCampaignSettings({ ...duplicateCampaignSettings, selectedCampaign: false, duplicated: false });
    }

    if (selectedCampaign !== undefined && selectedCampaign.campName !== 'Add New Campaign') {
      value = { ...selectedCampaign };
      setIsANewCampaign(false);

      // done this to remove selected campaign item out of the validation list.
      // setCampaignNameList(campaignNameList.filter((campD) => campD.campaignId !== value.campaignId));

      setDuplicateCampaignSettings({ ...duplicateCampaignSettings, selectedCampaign: true, duplicated: false });
      value.startDateTime = moment(value.startDateTime).format(
        'YYYY-MM-DDTHH:mm'
      );
      value.endDateTime = moment(value.endDateTime).format(
        'YYYY-MM-DDTHH:mm'
      );

      typeof value.questions === 'string' && (value.questions = JSON.parse(value.questions));

      typeof value.campProducts === 'string' && (JSON.parse(value.campProducts));

      typeof value.campPriceRankingOptions === 'string' && (value.campPriceRankingOptions = JSON.parse(
        value.campPriceRankingOptions
      ));

      typeof value.campProductRankingOption === 'string' && (
        value.campProductRankingOption = JSON.parse(
          value.campProductRankingOption
        ));

      handleCurrentStatus(value);

      value.campaignId === '' && (value.campaignId = uuidv4()); // if no campaign id generate a random one.

      setCampId(value.campaignId);

      const setCampaignValues = async () => {
        if (value) {
          await setFormikValues({
            ...value,
            typePriceOption: value?.campPriceRankingOptions?.length,
            typeProductOption: value?.campProductRankingOption?.length,
            campSurveyOnly: Boolean(value.campSurveyOnly)
          })
        }
      }

      await setCampaignValues();

      setOriginalCampStatus(value.campStatus); // used to show the duplicate option at the correct times when managing a campaign.
      setOriginalSurveyOnly(Boolean(value.campSurveyOnly)); // questionnaire only - used to compare the original value and the new one etc....
      setOriginalCampStartDate(value.startDateTime);
      setOriginalCampEndDate(value.endDateTime);
      setNewCampaignId(0)

      handleIncomingSelectedQuestions([...value.questions]); // current questions already set for an existing campaign
    } else {
      resetCampaign();
    }
    setProceedConfirmation(false);
  }

  const manageCampDataList = () => {
    let sortedList = [...campDataList];
    sortedList = sortedList.sort(sorter);
    sortedList.unshift(addNewCampObject);
    setSortedCampDataList(sortedList);
  }

  useEffect(() => {
    handleCampaignOnSelect();
  }, [proceedConfirmation, selectedCampaign]);

  useEffect(() => {
    manageCampDataList();
  }, [campDataList]);

  useEffect(() => setKey(new Date()), [data]);

  return (
    <>
      <ConfirmChange
        open={dialogOpen}
        handleClose={handleClose}
        setProceedConfirmation={setProceedConfirmation}
      />
      <Box display='flex'>
        {sortedCampDataList && (
          <Autocomplete
            key={key}
            onChange={(e, newValue) => handleChange(newValue)}
            fullWidth
            disablePortal
            disableClearable
            options={sortedCampDataList}
            inputValue={inputValue || ''}
            onInputChange={(e) => setInputValue(e?.target.value)}
            getOptionLabel={(option) => option.campName}
            renderOption={(props, option) =>
              option.campName === 'Add New Campaign' ? (
                <Box component='li' {...props}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {option.campName}
                  </Typography>
                </Box>
              ) : (
                <Box component='li' {...props}>
                  {option.campName}{' '}
                  <Circle
                    sx={{
                      ml: 1,
                      color:
                        handleCurrentStatusLabel(option) === 'Active'
                          ? '#4BD28F'
                          : handleCurrentStatusLabel(option) === 'Ended'
                            ? '#FF4D4D'
                            : '#F2C800',
                    }}
                  />
                </Box>
              )
            }
            renderInput={(params) => (
              <TextField {...params} label='Select Campaign' />
            )}
          />
        )}
      </Box>
    </>
  );
};
export default CampHeader;
