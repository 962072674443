import { createTheme, rgbToHex } from '@mui/material/styles';

const headerFontFamily = ['"Didot"', 'serif'].join(',');

const jonesTheme = (type = 'light') => {
  const white = '#fafafa';
  const black = rgbToHex('rgb(40, 40, 40)');
  const grey = rgbToHex('rgb(141, 143, 142)');

  const red = rgbToHex('rgb(207, 10, 44)');

  const yellow = rgbToHex('rgb(226, 231, 31)');

  return createTheme({
    palette: {
      type,
      primary: {
        main: type === 'dark' ? white : black,
        contrastText: type === 'dark' ? black : white,
      },
      secondary: {
        main: type === 'dark' ? grey : grey,
        contrastText: type === 'dark' ? white : white,
      },
      text: {
        primary: type === 'dark' ? white : black,
      },
      error: {
        main: red,
      },
      custom: {
        brand: {
          dark: black,
          light: white,
        },
        sale: red,
        promotion: yellow,
        trafficLights: {
          red: '#d32f2f',
          amber: '#ffa000',
          green: '#43a047',
        },
      },
    },
    typography: {
      button: {
        fontSize: '106%',
        letterSpacing: 1.1,
      },
      h1: {
        fontFamily: headerFontFamily,
        lineHeight: 0.95,
      },
      h2: {
        fontFamily: headerFontFamily,
        lineHeight: 1,
      },
      h3: {
        fontFamily: headerFontFamily,
        lineHeight: 1.05,
      },
      h4: {
        fontFamily: headerFontFamily,
        lineHeight: 1.15,
      },
      h5: {
        fontFamily: headerFontFamily,
        lineHeight: 1.25,
      },
      h6: {
        fontFamily: headerFontFamily,
        lineHeight: 1.4,
      },
      fontSize: 15,
      boldBodyFontFamily: 'Lato, Roboto, Arial, sans-serif',
      headerFontFamily: headerFontFamily,
      fontFamily: 'Lato, Roboto, Arial, sans-serif',
      useNextVariants: true,
    },
    overrides: {
      MuiTabs: {
        indicator: {
          background: yellow,
        },
      },
    },
  });
};

export default jonesTheme();
export const darkJonesTheme = jonesTheme('dark');
