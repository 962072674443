import { TableCell, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

export const tokenGreen = 'rgb(141, 209, 77)';
export const heartRed = '#ff6d75';

export const TableHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
});

export const StyledRatingStars = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: tokenGreen,
  },
});

export const StyledRatingHearts = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: heartRed,
  },
});
