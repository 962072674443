import { Box, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField } from "@mui/material";
import { CampConfigFormEndingPageOptions } from "./campConfigFormEndingPageOptions";
export const CampConfigFormFeatureOptions = ({
  values,
  currentCampDataStatus,
  handleChange,
  setIsFormDirty,
  formik,
  touched,
  errors,
  manageCampEndingPage,
  handleAddEndingPageImage,
  duplicateCampaignSettings,
  setDuplicateCampaignSettings,
  campId }) => {
  return (
    <Box>
      {/* CAMPAIGN TYPE RADIO BUTTONS */}

      <FormControl error={touched.campType && Boolean(errors.campType)}>
        <Box mt={2} display='flex' alignItems='center'>
          <FormLabel sx={{ marginRight: 2 }}>Campaign Type:</FormLabel>
          <RadioGroup
            value={values.campType}
            onChange={(e) => manageCampEndingPage(e)}
            row
          >
            <FormControlLabel
              value={'Consumer'}
              control={
                <Radio
                  disabled={currentCampDataStatus === 3}
                  name='campType'
                />
              }
              label='Consumer'
              id={values.campaignId?.toString()}
            />
            <FormControlLabel
              value={'Management'}
              control={
                <Radio
                  disabled={currentCampDataStatus === 3}
                  name='campType'
                />
              }
              label='Management'
              id={values.campaignId?.toString()}
            />
          </RadioGroup>
        </Box>
      </FormControl>
      {!Boolean(values.campSurveyOnly) && (
        values.campType === 'Consumer' ? (
          <Box>
            {/* Product Feature Question */}
            <Box mt={2}>
              <Box display='flex'>
                <TextField
                  disabled={currentCampDataStatus === 3}
                  fullWidth
                  label='Product Feature Question'
                  value={values.campProductFeatureQuestion}
                  placeholder='What do you think this product is worth?'
                  onChange={(e) => { handleChange('campProductFeatureQuestion')(e); setIsFormDirty(true); }}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Box display='flex'>
                <TextField
                  // added campId, if it has a value then do not change the product value
                  // changing product feature option for consumer campaigns will skew data
                  disabled={currentCampDataStatus === 3 || campId > 0}
                  fullWidth
                  select
                  label='Product Feature Options'
                  value={values.typePriceOption || 3}
                  onChange={(e) => { handleChange('typePriceOption')(e); setIsFormDirty(true); }}
                >
                  <MenuItem value={3}>Three</MenuItem>
                  <MenuItem value={5}>Five</MenuItem>
                </TextField>
              </Box>
            </Box>
            {/* product ranking options */}
            <Box mt={2} display='flex'>
              <TextField
                disabled={currentCampDataStatus === 3}
                fullWidth
                select
                label='Product Ranking Options'
                value={values.typeProductOption}
                onChange={(e) => { handleChange('typeProductOption')(e); setIsFormDirty(true); }}
              >
                <MenuItem
                  value={3}
                  onClick={(e) => {
                    formik.setFieldValue('typeProductOption', e.target.value);
                  }
                  }
                >
                  Three
                </MenuItem>
                <MenuItem
                  value={5}
                  onClick={(e) => {
                    formik.setFieldValue('typeProductOption', e.target.value);
                  }
                  }
                >
                  Five
                </MenuItem>
              </TextField>
            </Box>
            <Box mt={2}>
              <Box display='flex' justifyContent='space-between'>
                {values.typeProductOption === 3 ? (
                  <>
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking One'
                      placeholder='Dislike'
                      sx={{ width: '30%' }}
                      value={values.campProductRankingOption[0] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[0] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Two'
                      placeholder='Neutral'
                      sx={{ width: '30%' }}
                      value={values.campProductRankingOption[1] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[1] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Three'
                      placeholder='Like'
                      sx={{ width: '30%' }}
                      value={values.campProductRankingOption[2] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[2] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking One'
                      placeholder='Strongly Dislike'
                      sx={{ width: '18%' }}
                      value={values.campProductRankingOption[0] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[0] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Two'
                      placeholder='Dislike'
                      sx={{ width: '18%' }}
                      value={values.campProductRankingOption[1] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[1] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Three'
                      placeholder='Neutral'
                      sx={{ width: '18%' }}
                      value={values.campProductRankingOption[2] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[2] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Four'
                      placeholder='Like'
                      sx={{ width: '18%' }}
                      value={values.campProductRankingOption[3] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[3] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Five'
                      placeholder='Love'
                      sx={{ width: '18%' }}
                      value={values.campProductRankingOption[4] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campProductRankingOption,
                        ];
                        arr[4] = e.target.value;
                        formik.setFieldValue('campProductRankingOption', arr);
                        setIsFormDirty(true);
                      }}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        ) : values.campType === 'Management' ? (
          <Box mt={2}>
            <Box display='flex'>
              <TextField
                disabled={currentCampDataStatus === 3}
                fullWidth
                select
                label='Price Ranking Options'
                value={values.typePriceOption || ''}
                onChange={(e) => { handleChange('typePriceOption')(e); setIsFormDirty(true); }}
              >
                <MenuItem value={3}>Three</MenuItem>
                <MenuItem value={5}>Five</MenuItem>
              </TextField>
            </Box>
            <Box mt={2}>
              <Box display='flex' justifyContent='space-between'>
                {values.typePriceOption === 3 ? (
                  <>
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking One'
                      placeholder='Too Cheap'
                      sx={{ width: '30%' }}
                      value={values.campPriceRankingOptions[0] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[0] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Two'
                      placeholder='Just Right'
                      sx={{ width: '30%' }}
                      value={values.campPriceRankingOptions[1] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[1] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Three'
                      placeholder='Too Expensive'
                      sx={{ width: '30%' }}
                      value={values.campPriceRankingOptions[2] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[2] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking One'
                      placeholder='Far Too Cheap'
                      sx={{ width: '18%' }}
                      value={values.campPriceRankingOptions[0] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[0] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Two'
                      placeholder='Too Cheap'
                      sx={{ width: '18%' }}
                      value={values.campPriceRankingOptions[1] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[1] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Three'
                      placeholder='Just Right'
                      sx={{ width: '18%' }}
                      value={values.campPriceRankingOptions[2] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[2] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Four'
                      placeholder='Too Expensive'
                      sx={{ width: '18%' }}
                      value={values.campPriceRankingOptions[3] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[3] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                    <TextField
                      disabled={currentCampDataStatus === 3}
                      fullWidth
                      label='Ranking Five'
                      placeholder='Far Too Expensive'
                      sx={{ width: '18%' }}
                      value={values.campPriceRankingOptions[4] || ''}
                      onChange={(e) => {
                        const arr = [
                          ...values.campPriceRankingOptions,
                        ];
                        arr[4] = e.target.value;
                        formik.setFieldValue('campPriceRankingOptions', arr);
                        setIsFormDirty(true);
                      }}
                    />
                  </>
                )}
              </Box>
            </Box>

            <Box mt={2} display='flex'>
              <TextField
                disabled={currentCampDataStatus === 3}
                fullWidth
                inputProps={{ type: 'number', min: 0 }}
                value={values.campTokensPerProduct}
                onChange={(e) => { handleChange('campTokensPerProduct')(e); setIsFormDirty(true); }}
                label='Number Of Tokens Per Product'
                variant='outlined'
                id={values.campaignId.toString()}
              />
            </Box>
            <Box mt={2} display='flex' alignItems='center'>
              <FormLabel sx={{ marginRight: 2 }}>
                Number of Tokens Per Campaign:
              </FormLabel>
              <RadioGroup
                value={values.campMaxTokensMethod}
                onChange={(e) => { handleChange('campMaxTokensMethod')(e); setIsFormDirty(true); }}
                row
              >
                <FormControlLabel
                  value={'Calculated'}
                  control={
                    <Radio
                      disabled={currentCampDataStatus === 3}
                      name='maxTokensMethod'
                    />
                  }
                  label='Token x product'
                  id={values.campaignId.toString()}
                />
                <FormControlLabel
                  value={'UserDefined'}
                  control={
                    <Radio
                      disabled={currentCampDataStatus === 3}
                      name='maxTokensMethod'
                    />
                  }
                  label='User Defined total'
                  id={values.campaignId.toString()}
                />
              </RadioGroup>
            </Box>
            {values.campMaxTokensMethod === 'UserDefined' ? (
              <Box mt={2} display='flex'>
                <TextField
                  disabled={currentCampDataStatus === 3}
                  fullWidth
                  value={values.campTokens}
                  inputProps={{ type: 'number', min: 0 }}
                  onChange={(e) => { handleChange('campTokens')(e); setIsFormDirty(true); }}
                  label='Number Of Tokens Per Campaign'
                  variant='outlined'
                  id={values.campaignId.toString()}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        ) : (
          ''
        )
      )}

      <CampConfigFormEndingPageOptions
        values={values}
        formik={formik}
        setIsFormDirty={setIsFormDirty}
        handleChange={handleChange}
        currentCampDataStatus={currentCampDataStatus}
        handleAddEndingPageImage={handleAddEndingPageImage}
        duplicateCampaignSettings={duplicateCampaignSettings}
        setDuplicateCampaignSettings={setDuplicateCampaignSettings}
      />

    </Box>
  )
}