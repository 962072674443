import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  Button,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
} from '@mui/material';

// if management user, then show the user section.
// if consumer, show no sign in process.

const { REACT_APP_TITLE, REACT_APP_VERSION } = process.env;

const Header = () => {
  let { pathname } = useLocation();

  return (
    <AppBar position='static'>
      <Container style={{ minWidth: '100%' }}>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            {`${REACT_APP_TITLE} - ${REACT_APP_VERSION}`}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Typography variant='h5'>
              {`${REACT_APP_TITLE} - ${REACT_APP_VERSION}`}
            </Typography>
          </Box>
          {pathname === '/report_campaign' && (
            <Link to={'/build_campaign'} style={{ textDecoration: 'none' }}>
              <Button
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                }}
              >
                Back to Config
              </Button>
            </Link>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
