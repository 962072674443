import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { Box, Button, Container, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useSnackbar } from 'notistack';
import { default as jonesTheme } from '../themes/jonesTheme';
import { default as paversTheme } from '../themes/paversTheme';

import { RenderMultipleQuestions } from '../components/CampaignJourney/questions/renderMultipleQuestions';
import { RenderSliderQuestion } from '../components/CampaignJourney/questions/renderSliderQuestion';
import { RenderSingleQuestion } from '../components/CampaignJourney/questions/renderSingleQuestion';
import { RenderEmailQuestion } from '../components/CampaignJourney/questions/renderEmailQuestion';

import CampView from './campView';
import CampInactive from './CampInactive';
import CampInvalid from './CampInvalid';

import {
  params,
  validateEmail,
  isInValidDateRange,
  dobRegex,
  externalParams,
  formatUserInputDate,
  postcodeFormat
} from '../components/helpers';

import ActiveCampaignHeader from '../components/ActiveCampaignHeader';
import BranchCodeSelector from '../components/CampaignJourney/BranchCodeSelector';
import CampEnding from '../components/CampaignConfig/campEnding';

import LoadingSpinner from '../components/globals/LoadingSpinner';
import { RenderDateQuestion } from '../components/CampaignJourney/questions/renderDateQuestion';
import { RenderPostcodeQuestion } from '../components/CampaignJourney/questions/renderPostcodeQuestion';

const { REACT_APP_API_BASE_URI } = process.env;

const CampOperational = () => {

  const { enqueueSnackbar } = useSnackbar();

  let { url } = useParams();

  const location = useLocation();
  const state = location.state;

  // for checking if preview...
  // console.log(state);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [productPage, setProductPage] = useState(false);
  const [resultsObj, setResultsObj] = useState(null);
  const [branchCode, setBranchCode] = useState('');
  const [questionResults, setQuestionResults] = useState([]);
  const [maxReached, setMaxReached] = useState(false);
  const [campEndingOpen, setCampEndingOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [backToSummary, setBackToSummary] = useState(false);
  const [formValidation, setFormValidation] = useState({
    emailError: false,
    dobError: false,
    postcodeError: false
  })

  const postOperationalResult = async (resultData) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}postOperationResult`,
        resultData,
        params
      );

      if (response.status === 200) {
        console.log('post operational result successful : ', response.status);
      }

      setMaxReached(response.data.response.maxReached);

    } catch (e) {
      console.error(e);
    }
  };

  const getCampaignById = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}getCampaignByUrl/${url}`,
        params
      );

      if (response.status !== 200) {
        enqueueSnackbar('Error when getting campaign by id.', { variant: 'error' })
      }

      return response.data.camp[0];
    } catch (err) {
      console.error(err);
    }
  };

  const getBrandUrlById = async (brand) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}getBrandUrl/${brand}`,
        params
      );

      if (response.status !== 200) {
        enqueueSnackbar('Error when getting brand url by id.', { variant: 'error' })
      }
      return response.data.response[0].url;
    } catch (err) {
      console.error(err);
    }
  };

  const checkForValidEmail = async (email) => {
    if (email && !email.includes('@')) {
      return false
    }
    try {
      const response = await axios.get(`https://debounce.paversdev.co.uk/valid/${email}`, externalParams)
      setFormValidation({ ...formValidation, emailError: false })
      return true
    } catch (e) {
      setFormValidation({ ...formValidation, emailError: true })
      return false
    }
    // return response
  }

  const checkForValidPostcode = async (postcode) => {
    if (postcode && postcode.length < 5) {
      return false
    }
    try {
      const response = await axios.get(`https://api.postcodes.io/postcodes/${postcode}/validate`)
      let isPostcodeValid = false;
      if (response && response.status === 200) {
        if (response.result) {
          isPostcodeValid = true
          setFormValidation({ ...formValidation, postcodeError: false })
        } else {
          isPostcodeValid = postcodeFormat.test(postcode)
          setFormValidation({ ...formValidation, postcodeError: isPostcodeValid ? false : true })
        }
      }

      if (response && response.status !== 200) {
        isPostcodeValid = postcodeFormat.test(postcode)
        setFormValidation({ ...formValidation, postcodeError: isPostcodeValid ? false : true })
      }

      return isPostcodeValid;
    } catch (e) {
      setFormValidation({ ...formValidation, postcodeError: true })
      return false
    }
  }

  const checkForValidDate = (date) => {
    const isValid = dobRegex.test(date)
    if (!isValid) {
      setFormValidation({ ...formValidation, dobError: true })
      return false
    } else {
      setFormValidation({ ...formValidation, dobError: false })
      return true
    }
  }

  const fetchCampData = async () => {
    const fetchedCampaign = await getCampaignById();
    // setBrand(fetchedCampaign?.brand);

    // data needs a patch over rather than creating a new one, hence using [x] to get most up to date with specific url
    // perhaps a check is needed API side to check URL + campaignID, if they match then PUT/PATCH.

    if (typeof fetchedCampaign.products === 'string') {
      fetchedCampaign.products = JSON.parse(fetchedCampaign.products);
    }

    if (typeof fetchedCampaign.questions === 'string') {
      fetchedCampaign.questions = JSON.parse(fetchedCampaign.questions);
    }

    if (typeof fetchedCampaign.priceRankingOptions === 'string') {
      fetchedCampaign.priceRankingOptions = JSON.parse(
        fetchedCampaign.priceRankingOptions
      );
    }

    fetchedCampaign.redirect = await getBrandUrlById(fetchedCampaign.brand);

    const managedProducts = fetchedCampaign.products.map((prod) => {
      if (typeof prod.images === 'string') {
        prod.images = JSON.parse(prod.images);
      }

      if (typeof prod.pricingOptions === 'string') {
        prod.pricingOptions = JSON.parse(prod.pricingOptions);
      }

      return prod;
    });

    fetchedCampaign.products = [...managedProducts].sort((a, b) => a.prodOrder - b.prodOrder);

    setData(fetchedCampaign);
    setQuestions([...fetchedCampaign.questions]);
    if (!state?.preview) {
      setMaxReached(fetchedCampaign.campMaxReached);
    }
  };

  const checkBranchCode = () => {
    if (data?.type === 'Management') {
      if (branchCode.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isCampaignActive = () => {
    let isActive = false;

    // if has state (like preview status) from config page
    if (data && state) return true;

    // no data
    if (!data) return false;

    // if campaign has ended...
    if (data.status === 1) return false;

    if (maxReached) return false;

    if (!maxReached) isActive = true;

    // if data range is valid...
    if (data.status === 3) isActive = true;

    if (data.status === 4) isInValidDateRange(data.startDateTime, data.endDateTime) ? isActive = true : isActive = false;

    return isActive;
  };

  const submitQuestionResults = async results => {
    if (state === null) { // if its not preview, then post.
      await postOperationalResult(results)
    }
  };

  const validateResultsAndSubmit = async () => {
    const findIncorrectAnswers = questionResults.filter(
      // checks for answered results that are not of requirement...
      (answers) =>
        answers.answer === '' ||
        answers.answer === 0 ||
        answers.answer === undefined
    );

    const tempResults = await Promise.all(questionResults.map(async (r) => {
      if (r.type === 7) { // DOB
        if (r.answer.length === 0) {
          return r
        }
        let formatDate = r.answer;

        if (typeof r.answer !== 'string') (formatDate = formatUserInputDate(r.answer))

        return { ...r, valid: checkForValidDate(formatDate) }
      }
      if (r.type === 6) { // postcode
        if (r.answer.length === 0) {
          return r
        }
        return {
          ...r, answer: r.answer.split(/(?=.{3}$)/).join(' ').toUpperCase(), valid: await checkForValidPostcode(r.answer)
        }
      }

      if (r.type === 4) { // email
        if (r.answer.length === 0) {
          return r
        }
        return {
          ...r, valid: await checkForValidEmail(r.answer)
        }
      }

      return r;
    }))

    if (tempResults.length !== questions.length) {
      // throw error as something is not answered...
      enqueueSnackbar('Please answer all the questions', { variant: 'error' })
      setQuestionResults([...tempResults])
      return;
    }

    const findEmailError = tempResults.some((d) => d.type === 4 && !d.valid)
    const findDobError = tempResults.some((d) => d.type === 7 && !d.valid)
    const findPostCodeError = tempResults.some((d) => d.type === 6 && !d.valid)

    if (findEmailError) {
      enqueueSnackbar('Email error found, please check and try again.', { variant: 'error' })
      setFormValidation({ ...formValidation, emailError: true })
      return;
    }

    if (findDobError) {
      enqueueSnackbar('Date of birth error found, please check and try again.', { variant: 'error' })
      setFormValidation({ ...formValidation, dobError: true })
      return;
    }

    if (findPostCodeError) {
      enqueueSnackbar('Postcode error found, please check your answers and try again.', { variant: 'error' })
      setFormValidation({ ...formValidation, postcodeError: true })
      return;
    }

    if (data.type === 'Management' && !checkBranchCode()) {
      enqueueSnackbar('Error please check your branch code and try again.', { variant: 'error' })
      return;
    }

    if (findIncorrectAnswers.length !== 0) {
      enqueueSnackbar('Please check all your answers and try again.', { variant: 'error' })
      return;
    }


    // if all is good.... proceed to sort data for posting

    if (data.type === 'Management' && checkBranchCode()) {
      setQuestionResults({
        branchCode: branchCode,
        questionResults: [...tempResults],
      });
    } else if (data.type === 'Consumer') {
      setQuestionResults({
        questionResults: [...tempResults],
      });
    }

    // // check if survey only...
    if (Boolean(data.surveyOnly)) {
      let resultsToPost = {};
      if (data.type === 'Management') {
        resultsToPost = { ...resultsObj, products: [], questionResults: tempResults.map(({ type, ...res }) => res), branchCode: branchCode }
      } else {
        resultsToPost = { ...resultsObj, products: [], questionResults: tempResults.map(({ type, ...res }) => res) }
      }
      // if questionnaire only, submit question results.
      // else pass through submit question results to product page.
      await submitQuestionResults(resultsToPost)
        .then(() => data.endingPageEnabled ? setCampEndingOpen(true) : (window.location.href = data.redirect))
    } else {
      if (editable) {
        setEditable(false);
        setBackToSummary(true);
      }

      setProductPage(true);
    }
  };

  const handleSubmit = async () => await validateResultsAndSubmit();

  useEffect(() => {
    // getFromLocalStorage();

    if (editable) {
      setQuestionResults([...resultsObj.questionResults]);
    }

    if (data?.length > 0) return;

    const fetchData = async () => {
      await fetchCampData().then((res) => setLoading(false));
    }

    fetchData();

  }, []);

  useEffect(() => {
    if (editable) {
      setQuestionResults([...resultsObj.questionResults]);
    }
  }, [editable])

  useEffect(() => {
    const res = data?.products.map((prod) => ({
      productID: prod.productID,
      comment: '',
      productRank: 0,
      tokenAnswer: 0,
      chosenOption: '',
      descriptionText: prod.descriptionText,
      descriptionTitle: prod.descriptionTitle,
      image: prod.images[0].name, // get main image for current product...
      pricingOptions:
        data.type === 'Consumer'
          ? prod.pricingOptions
          : { values: data.priceRankingOptions },
      pricingText: prod.pricingText,
    }));
    setResultsObj((prev) => ({
      ...prev,
      campName: data?.name,
      campUrl: data?.url,
      campType: data?.type,
      products: res,
    }));

    if (data?.questions?.length > 0 && questionResults.length === 0) {
      setQuestions([...data.questions]);
    }

  }, [data]);

  const theme = data?.brand === 1 ? paversTheme : jonesTheme;

  // If there is not a valid campaign...
  if (!data && !loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ActiveCampaignHeader
          brand={data?.brand === 1 ? 'Pavers' : 'Jones Bootmaker'}
          isPreview={state}
        />
        <Container>
          <CampInvalid />
        </Container>
      </ThemeProvider>
    )
  }

  // Else if the campaign is not active...
  if (isCampaignActive() === false && !loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ActiveCampaignHeader
          brand={data?.brand === 1 ? 'Pavers' : 'Jones Bootmaker'}
          isPreview={state}
        />
        <Container>
          <CampInactive brand={data.brand} redirect={data.redirect} />
        </Container>
      </ThemeProvider>
    )
  }

  if (data?.surveyOnly && campEndingOpen) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ActiveCampaignHeader
          brand={data.brand === 1 ? 'Pavers' : 'Jones Bootmaker'}
          isPreview={state}
        />
        <CampEnding
          handlePageOpen={setCampEndingOpen}
          campData={data}
          maxReached={maxReached}
          isPreview={state}
        />
      </ThemeProvider>
    )
  }

  if (loading) {
    return (
      <LoadingSpinner />
    )
  }

  // Else all is good :)
  // *todo* creating a wrapping container with header and container as used multiple times for different things above.
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ActiveCampaignHeader
        brand={data?.brand === 1 ? 'Pavers' : 'Jones Bootmaker'}
        isPreview={state}
      />
      <Container>
        {productPage ? (
          <CampView
            campData={data}
            resultsObj={resultsObj}
            setResultsObj={setResultsObj}
            questionResults={questionResults}
            isPreview={state?.preview ? state.preview : false}
            campEndingOpen={campEndingOpen}
            setCampEndingOpen={setCampEndingOpen}
            postOperationalResult={postOperationalResult}
            maxReached={maxReached}
            setProductPage={setProductPage}
            productPage={productPage}
            editable={editable}
            setEditable={setEditable}
            backToSummary={backToSummary}
            enqueueSnackbar={enqueueSnackbar}
          />
        ) : isCampaignActive() ? (
          <>
            {data.type === 'Management' ? (
              <Box mt={2}>
                <Typography variant='h3' component='h1' textAlign={'center'}>
                  {data.name}
                </Typography>
              </Box>
            ) : (
              ''
            )}
            {!productPage && (
              <Box textAlign={'center'}>
                {data.type === 'Consumer' ? (
                  <Box marginY='2em'>
                    <Typography>Please complete the questions below. </Typography>
                    {!data.surveyOnly && (
                      <>
                        <Typography>
                          Then click on the Start Product Review button to rate each
                          product, by allocating a number of hearts to it.
                        </Typography>
                        <Typography>
                          Once you have rated each product, click on the Next button
                          to go to the next product until the survey is complete.{' '}
                        </Typography>
                      </>
                    )}
                  </Box>
                ) : (
                  ''
                )}
                <Box marginY='2em'>
                  <Typography variant='h4'>Questionnaire</Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  gap='2em'
                >
                  {/* branch selector */}

                  {data.type === 'Management' && (
                    <BranchCodeSelector
                      branchCode={branchCode}
                      setBranchCode={setBranchCode}
                      checkBranchCode={checkBranchCode}
                      url={data.url}
                    />
                  )}

                  {questions.map((question, index) => (
                    <Box
                      key={index}
                      display='flex'
                      alignItems='center'
                      gap='1em'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography variant='h5'>
                        {index + 1}. {question.value}
                      </Typography>
                      {(question.type === 1) && (
                        <RenderSingleQuestion
                          question={question}
                          setQuestions={setQuestions}
                          allQuestions={questions}
                          setQuestionResults={setQuestionResults}
                          questionResults={questionResults}
                          questionIndex={questions.indexOf(question)}
                          editable={editable}
                          resultsObj={resultsObj}
                        />
                      )}
                      {(question.type === 2 || question.type === 5) && (
                        <RenderMultipleQuestions
                          type={question.type}
                          question={question}
                          setQuestions={setQuestions}
                          allQuestions={questions}
                          setQuestionResults={setQuestionResults}
                          questionResults={questionResults}
                          questionIndex={questions.indexOf(question)}
                          editable={editable}
                          resultsObj={resultsObj}
                        />
                      )}
                      {question.type === 3 && (
                        <>
                          <RenderSliderQuestion
                            question={question}
                            setQuestions={setQuestions}
                            allQuestions={questions}
                            setQuestionResults={setQuestionResults}
                            questionResults={questionResults}
                            questionIndex={questions.indexOf(question)}
                            editable={editable}
                            resultsObj={resultsObj}
                          />
                        </>
                      )}
                      {question.type === 4 && (
                        <RenderEmailQuestion
                          question={question}
                          setQuestions={setQuestions}
                          allQuestions={questions}
                          setQuestionResults={setQuestionResults}
                          questionResults={questionResults}
                          questionIndex={questions.indexOf(question)}
                          validateEmail={validateEmail}
                          editable={editable}
                          resultsObj={resultsObj}
                          formValidation={formValidation}
                          setFormValidation={setFormValidation}
                          checkForValidEmail={checkForValidEmail}
                        />
                      )}

                      {question.type === 6 && (
                        <RenderPostcodeQuestion
                          question={question}
                          setQuestions={setQuestions}
                          allQuestions={questions}
                          setQuestionResults={setQuestionResults}
                          questionResults={questionResults}
                          questionIndex={questions.indexOf(question)}
                          editable={editable}
                          resultsObj={resultsObj}
                          formValidation={formValidation}
                          setFormValidation={setFormValidation}
                          checkForValidPostcode={checkForValidPostcode}
                        />
                      )}

                      {question.type === 7 && (
                        <RenderDateQuestion
                          question={question}
                          setQuestions={setQuestions}
                          allQuestions={questions}
                          setQuestionResults={setQuestionResults}
                          questionResults={questionResults}
                          questionIndex={questions.indexOf(question)}
                          editable={editable}
                          resultsObj={resultsObj}
                          formValidation={formValidation}
                          setFormValidation={setFormValidation}
                          checkForValidDate={checkForValidDate}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
                <Box mt={2}>
                  {/* go to product and view data */}
                  <Button variant='contained' onClick={(e) => handleSubmit()}>
                    {Boolean(data.surveyOnly) ? 'Submit' : editable ? 'Resume Product Review' : 'Start Product Review'}
                  </Button>
                </Box>
              </Box>
            )}

          </>
        ) : (
          ''
        )}
      </Container>
    </ThemeProvider>
  );
};
export default CampOperational;