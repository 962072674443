import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import CommentsModal from './Modals/CommentsModal';
import HeartsModal from './Modals/HeartsModal';
import {
  StyledRatingHearts,
  heartRed,
  tokenGreen,
} from '../../styles/miscStyles';

const { REACT_APP_CLOUD_BUCKET_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  picture: {
    width: '80px',
    // height: '125px',
    '@media (max-width: 512px)': {
      width: '80px',
      // height: '125px',
    },
  },
}));

function ConsumerReportRow({ product, campDocs }) {
  const location = useLocation();
  const { campData } = location.state;

  const classes = useStyles();

  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openHeartsModal, setOpenHeartsModal] = useState(false);

  const [pricingOptions, setPricingOptions] = useState([]);
  const [maxHearts, setMaxHearts] = useState(0);
  const [totalAssignedHearts, setTotalAssignedHearts] = useState(0);

  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);

  const [hearts, setHearts] = useState([]);

  useEffect(() => {
    // create array of comment objects
    const comments = campDocs.map((doc, i) => {
      return {
        submission: i + 1,
        comment: doc.products.filter(
          (p) => p.productID === product.productID
        )[0].comment,
      };
    });
    const filteredComments = comments.filter((c) => c.comment !== '');
    setComments(filteredComments);

    // tally up total no of hearts
    const assignedHearts = campDocs
      .map(
        (doc) =>
          doc.products.filter((prod) => prod.productID === product.productID)[0]
            .productRank
      )
      .reduce((a, c) => a + c, 0);
    // console.log(test, 'map results');
    setTotalAssignedHearts(assignedHearts);
  }, [campDocs, product]);

  useEffect(() => {
    setCommentCount(comments.length);
  }, [comments]);

  const handleCommentModalOpen = () => {
    setOpenCommentModal(true);
  };

  const handleCommentModalClose = () => {
    setOpenCommentModal(false);
  };

  // create array of heart results
  const handleHeartsModalOpen = () => {
    const temp = campDocs.map((doc, i) => {
      return {
        submission: i + 1,
        hearts: doc.products.filter((p) => p.productID === product.productID)[0]
          .productRank,
      };
    });
    setHearts(temp);
    setOpenHeartsModal(true);
  };

  const handleHeartsModalClose = () => {
    setOpenHeartsModal(false);
  };

  useEffect(() => {
    const pricingOptions = [...product.pricingOptions.values];
    setPricingOptions(pricingOptions);
  }, [product]);

  useEffect(() => {
    setMaxHearts(campData.campProductRankingOption.length);
  }, [campData]);

  return (
    <>
      <TableRow>
        <TableCell>
          <img
            src={`${REACT_APP_CLOUD_BUCKET_URL}${product.image}`}
            className={classes.picture}
            alt=''
          />
        </TableCell>
        <TableCell sx={{ maxWidth: '150px' }}>
          {product.descriptionTitle}
        </TableCell>
        <TableCell>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h6'>{product.pricingText}</Typography>
            <Typography>Product Price</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box display='flex' justifyContent='space-between'>
            {pricingOptions.map((opt, i) => (
              <Grid item xs={4} key={i}>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  sx={{
                    color:
                      i === 0 ? heartRed : i === 1 ? 'inherit' : tokenGreen,
                  }}
                >
                  <Typography variant='h6'>
                    {product.optionResults[opt].toFixed(2)}%
                  </Typography>
                  <Typography>{opt}</Typography>
                </Box>
              </Grid>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            onClick={() => handleHeartsModalOpen()}
            sx={{ cursor: 'pointer' }}
          >
            <StyledRatingHearts
              max={maxHearts}
              value={product.rankResult}
              readOnly
              precision={0.5}
              icon={<FavoriteIcon fontSize='inherit' />}
              emptyIcon={<FavoriteBorderIcon fontSize='inherit' />}
            />
            <Box
              color={heartRed}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <Typography fontSize={12} sx={{ my: 0.5 }}>
                Average Likes
              </Typography>
              <Typography fontWeight='bold'>
                Total Likes: {totalAssignedHearts}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Button onClick={() => handleCommentModalOpen()} variant='contained'>
            Comments ({commentCount})
          </Button>
        </TableCell>
      </TableRow>

      <CommentsModal
        open={openCommentModal}
        handleClose={handleCommentModalClose}
        comments={comments}
        type='Consumer'
      />
      <HeartsModal
        open={openHeartsModal}
        handleClose={handleHeartsModalClose}
        hearts={hearts}
        max={maxHearts}
      />
    </>
  );
}

export default ConsumerReportRow;
