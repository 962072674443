import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_LOGIN_PASS } = process.env;

export const Login = ({
  openModal,
  handleClose,
  setIsAuthenticated,
  enqueueSnackbar
}) => {
  const navigate = useNavigate();

  const [loginInput, setLoginInput] = useState('');
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (validation) {
      setTimeout(() => {
        setValidation(false);
      }, 3000);
    }
  }, [validation]);

  const handleLogin = () => {
    if (loginInput === REACT_APP_LOGIN_PASS.toString()) {
      enqueueSnackbar('Login successful!', { variant: 'success' })
      setTimeout(() => {
        navigate('/build_campaign');
      }, 1250);
      setIsAuthenticated(true);
    } else {
      enqueueSnackbar('Incorrect password, try again.', { variant: 'error' })
      setValidation(true);
      setIsAuthenticated(false);
    }
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose}>
        <Box display='flex' flexDirection='column' p={6} m={6} gap='2em'>
          <Box>
            <Typography variant='h4' textAlign={'center'} marginBottom='0.5em'>
              Configuration Login
            </Typography>
          </Box>
          <form onSubmit={(e) => e.preventDefault()}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                type="text"
                name="email"
                value="...to stop chrome warning from showing..."
                autoComplete="username email"
                style={{ display: "none" }}
              />
              <TextField
                value={loginInput}
                onChange={(e) => setLoginInput(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
                error={validation}
                helperText={validation ? 'Incorrect password, try again.' : ' '}
                placeholder='Enter Password'
                type='password'
                autoComplete='new-password'
              />
            </FormControl>
          </form>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            gap={'1em'}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleLogin()}
              style={{
                paddingLeft: '2em',
                paddingRight: '2em',
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
              }}
            >
              Login
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => handleClose()}
              style={{
                paddingLeft: '2em',
                paddingRight: '2em',
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
