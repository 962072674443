import { Box, Button, Container, Dialog, Typography } from '@mui/material';
import { useState } from 'react';

import ProductsTable from '../components/ManagementSummary/ProductsTable';
import QuestionsTable from '../components/ManagementSummary/QuestionsTable';

const ManagementSummary = ({
  resultsObj,
  summaryOpen,
  setSummaryOpen,
  questions,
  tokenLimit,
  redirect,
  goToEditProduct,
  submitResults,
  isPreview,
  productPage,
  setProductPage,
  editable,
  setEditable
}) => {

  const [questionsPage, setQuestionsPage] = useState(false);

  const showQuestionsPage = () => setQuestionsPage(true);

  const hideQuestionsPage = () => setQuestionsPage(false);

  const handleClose = () => setSummaryOpen(false);

  return (
    <Dialog onClose={handleClose} open={summaryOpen} fullScreen>
      <Container maxWidth='lg'>
        <Box my={4}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='h4'>Campaign Summary</Typography>
          </Box>

          <Box mt={2}>
            <Typography variant='h6' sx={{ my: 2 }}>
              Questionnaire Summary
            </Typography>
            <QuestionsTable
              questions={questions}
              answers={resultsObj.questionResults}
              resultsObj={resultsObj}
              productPage={productPage}
              setProductPage={setProductPage}
              questionsPage={questionsPage}
              showQuestionsPage={showQuestionsPage}
              hideQuestionsPage={hideQuestionsPage}
              editable={editable}
              setEditable={setEditable}
            />
          </Box>

          <Box mt={2}>
            <Typography variant='h6' sx={{ my: 2 }}>
              Product Rating Summary
            </Typography>
            <ProductsTable
              resultsObj={resultsObj}
              tokenLimit={tokenLimit}
              goToEditProduct={goToEditProduct}
            />
          </Box>

          <Box display='flex' gap={2} mt={2}>
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Go Back
            </Button>
            <Button
              fullWidth
              variant='contained'
              onClick={() => {
                if (!isPreview) {
                  submitResults();
                }
                window.location.href = redirect;
              }}
            >
              Finish and Submit Results
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default ManagementSummary;
