import { createTheme, rgbToHex } from '@mui/material/styles';
import { red as muiRed } from '@mui/material/colors';

const headerFontFamily = ['"Austin Roman"', 'serif'].join(',');

// RGB values taken from `PAVERS BRAND GUIDELINES.pdf` page 14-15
const paversTheme = (type = 'light') => {
  const white = '#fff';

  const darkBlue = rgbToHex('rgb(0, 81, 112)');
  const lightBlue = rgbToHex('rgb(216, 223, 231)');

  const darkBrown = rgbToHex('rgb(120, 105, 109)');
  const lightBrown = rgbToHex('rgb(223, 221, 216)');

  const darkGrey = rgbToHex('rgb(116, 117, 120)');
  const lightGrey = rgbToHex('rgb(201, 200, 198)');

  const red = rgbToHex('rgb(207, 10, 44)');

  const yellow = rgbToHex('rgb(226, 231, 31)');

  return createTheme({
    palette: {
      type,
      primary: {
        main: type === 'dark' ? lightBlue : darkBlue,
        contrastText: type === 'dark' ? darkBlue : lightBlue,
      },
      secondary: {
        main: type === 'dark' ? lightBrown : darkBrown,
        contrastText: type === 'dark' ? lightGrey : lightBrown,
      },
      text: {
        primary: type === 'dark' ? white : darkBlue,
      },
      error: {
        main: type === 'dark' ? muiRed[500] : red,
      },
      background: {
        // default: type === 'dark' ? darkBlue : lightBlue,
        default: type === 'dark' ? darkBlue : white,
        paper: type === 'dark' ? darkGrey : white,
      },
      custom: {
        brand: {
          dark: darkBlue,
          light: lightBlue,
        },
        womens: {
          dark: darkBrown,
          light: lightBrown,
        },
        mens: {
          dark: darkGrey,
          light: lightGrey,
        },
        sale: red,
        promotion: yellow,
        trafficLights: {
          red: '#d32f2f',
          amber: '#ffa000',
          green: '#43a047',
        },
      },
    },
    typography: {
      button: {
        fontSize: '106%',
        letterSpacing: 1.1,
      },
      h1: {
        fontFamily: headerFontFamily,
        letterSpacing: '0.018em',
        lineHeight: 0.85,
      },
      h2: {
        fontFamily: headerFontFamily,
        letterSpacing: '0.018em',
        lineHeight: 0.9,
      },
      h3: {
        fontFamily: headerFontFamily,
        letterSpacing: '0.018em',
        lineHeight: 1,
      },
      h4: {
        fontFamily: headerFontFamily,
        letterSpacing: '0.018em',
        lineHeight: 1.1,
      },
      h5: {
        fontFamily: headerFontFamily,
        letterSpacing: '0.018em',
        lineHeight: 1.2,
      },
      h6: {
        fontFamily: headerFontFamily,
        letterSpacing: '0.018em',
        lineHeight: 1.4,
      },
      fontSize: 15,
      headerFontFamily: headerFontFamily,
      headerLightFontFamily: '"Austin Light", serif',
      boldBodyFontFamily: '"Futura Md BT", sans-serif',
      fontFamily: 'Futura, Roboto, Arial, sans-serif',
      useNextVariants: true,
    },
    overrides: {
      MuiTabs: {
        indicator: {
          background: yellow,
        },
      },
    },
  });
};

export default paversTheme();
export const darkPaversTheme = paversTheme('dark');
