/* General Imports */

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

/* MUI Imports */

import { Box, Container, Typography, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

/* App imports */

import ReportTable from '../components/Reporting/reportTable';
import CampaignDetails from '../components/Reporting/CampaignDetails';
import SingleBranchView from '../components/Reporting/SingleBranchView';
import { params } from '../components/helpers';
import ExportControl from '../components/Reporting/ExportControl';

const { REACT_APP_API_BASE_URI } = process.env;

function Report() {
  const [reportObj, setReportObj] = useState(null);
  const [campDocs, setCampDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchCodeList, setBranchCodeList] = useState([]);

  const [singleBranchOpen, setSingleBranchOpen] = useState(false);
  const [singleBranchData, setSingleBranchData] = useState(null);

  const location = useLocation();
  const { campData, localQuestions } = location.state || { campData: { campUrl: '' } };

  useEffect(() => {
    if (campData.campUrl) {
      // get report object
      const fetcher = async () => {
        try {
          setIsLoading(true);
          const res = await axios.get(
            `${REACT_APP_API_BASE_URI}getOperationByUrl/${campData.campUrl}`,
            params
          );
          // console.log(res.data, 'fetcher');
          setReportObj(res.data.response);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          console.log(err.toJSON());
        }
      };
      // get campDocs
      const docFetcher = async () => {
        try {
          setIsLoading(true);
          const res = await axios.get(
            `${REACT_APP_API_BASE_URI}getReportingList/${campData.campUrl}`,
            params
          );
          setCampDocs(res.data.response);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          console.log(err.toJSON());
        }
      };

      fetcher();
      docFetcher();
    }
  }, [campData.campUrl]);

  useEffect(() => {
    const branches = campDocs.map((doc) => doc.branchCode);
    setBranchCodeList(branches);
  }, [campDocs]);

  // useEffect(() => {
  //   console.log('Report Object:', reportObj);
  //   console.log('Camp Docs:', campDocs);
  //   console.log('Camp Data:', campData);
  // }, [reportObj, campDocs, campData]);

  const handleClose = () => {
    setSingleBranchOpen(false);
  };

  const handleBranchSelect = (branch) => {
    const selectedBranch = campDocs.filter(
      (doc) => doc.branchCode === branch
    )[0];
    setSingleBranchData(selectedBranch);
    setSingleBranchOpen(true);
  };

  if (!campData.campUrl) {
    return (
      <Container maxWidth='lg' sx={{ mt: 2 }}>
        <Typography variant='h4'>No Campaign Selected</Typography>
      </Container>
    );
  }

  return (
    <>
      <SingleBranchView
        open={singleBranchOpen}
        handleClose={handleClose}
        campData={campData}
        questions={campData.questions}
        resultsObj={singleBranchData}
      />
      <Container maxWidth='lg' sx={{ mt: 2 }}>
        <Box my={3}>
          <Typography variant='h4' align='center'>
            Campaign Results
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CampaignDetails
              campData={campData}
              completions={campDocs.length}
              branchCodeList={branchCodeList}
              handleBranchSelect={handleBranchSelect}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              height='100%'
            >
              <ExportControl
                localQuestions={localQuestions}
                questions={campData.questions}
                campDocs={campDocs}
                campName={campData.campName}
                campType={campData.campType}
                questionnaireOnly={campData.campSurveyOnly}
                campProducts={campData.campProducts}
              />
            </Box>
          </Grid>
        </Grid>

        {isLoading ? (
          <Box display='flex' justifyContent='center' mt={2}>
            <CircularProgress />
          </Box>
        ) : reportObj ? (
          <Box my={2}>
            <ReportTable
              reportObj={reportObj}
              campDocs={campDocs}
              campType={campData.campType}
            />
          </Box>
        ) : (
          <Box mt={2}>
            <Typography variant='h6' color='error.main'>
              No results to display
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
}
export default Report;
