import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem, TextField } from "@mui/material";
import { currentDate } from "../helpers";

export const CampConfigFormBrand = ({
  values,
  touched,
  handleChange,
  setIsFormDirty,
  errors,
  newCampaignRef,
  currentCampDataStatus,
  duplicateCampaignSettings,
  originalCampStartDate,
  originalCampEndDate,
  originalCampStatus,
  handleDuplicateCampaignOnToggle,
  campaignBrands }) => {

  const manageShowDuplicateOption = () => {
    // if is a not a selected campaign...
    if (!duplicateCampaignSettings.selectedCampaign) {
      return false;
    }

    // if ended...
    if (originalCampStatus === 1) {
      return true;
    }

    // after the campaign has been done and ended...
    if (originalCampStatus === 4 && Date.parse(originalCampEndDate) <= Date.parse(currentDate)) {
      return true;
    }

    // pending campaign...
    if (originalCampStatus === 4 && Date.parse(originalCampStartDate) > Date.parse(currentDate)) {
      return true;
    }

    return false;
  }

  return (
    <Box display='flex' flexDirection={'column'} mt={2}>
      {
        manageShowDuplicateOption() &&
        (
          <Box mt={2} display='flex'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={currentCampDataStatus === 3}
                    checked={duplicateCampaignSettings.duplicated}
                    onChange={(e) => handleDuplicateCampaignOnToggle(e)}
                  />
                }
                label='Duplicate campaign?:'
                labelPlacement='start'
                sx={{ marginLeft: 0 }}
              />
            </FormGroup>
          </Box>
        )
      }

      <Box my={2}>
        <TextField
          select
          fullWidth
          label='Campaign Brand'
          value={values.campBrand?.toString()}
          onChange={(e) => { handleChange('campBrand')(e); setIsFormDirty(true); }}
          disabled={currentCampDataStatus === 3}
        >
          {campaignBrands ? (campaignBrands.map((brand) => <MenuItem key={brand.id} value={brand.id}> {brand.description} </MenuItem>)) : <MenuItem disabled>No Brands Found</MenuItem>}
        </TextField>
      </Box>
    </Box>
  )
}