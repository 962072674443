/* MUI Imports */

import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

/* App Imports */

import { TableHeaderCell } from '../../../styles/miscStyles';
import { StyledRatingStars } from '../../../styles/miscStyles';

const TokensModal = ({ open, handleClose, tokens, max }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      fullWidth
    >
      <Box p={2}>
        <Box>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Tokens
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Branch Code</TableHeaderCell>
                  <TableHeaderCell>No. of Tokens</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tokens.map((token) => (
                  <TableRow key={token.branchCode}>
                    <TableCell>{token.branchCode}</TableCell>
                    <TableCell>
                      <StyledRatingStars
                        max={max}
                        value={token.tokens}
                        readOnly
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={2}>
          <Button fullWidth variant='outlined' onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TokensModal;
