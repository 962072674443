import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export const RenderPostcodeQuestion = ({
  question,
  setQuestions,
  allQuestions,
  setQuestionResults,
  questionResults,
  questionIndex,
  editable,
  resultsObj,
  formValidation,
  setFormValidation,
  checkForValidPostcode
}) => {

  const [value, setValue] = useState('');

  useEffect(() => {
    if (editable) {
      if (resultsObj.questionResults?.length > 0) {
        const match = resultsObj.questionResults.filter((item) => item.id === question.id)[0];
        setValue(match.answer);
      }
      setQuestionResults([...resultsObj.questionResults]);
    }
  }, [])

  const handleAnswer = (currentValue) => {
    // once added, push selectedAnswer to a form array within the parent component. Then on save in the parent, it will be pushed to the API.
    if (formValidation.postcodeError) {
      setFormValidation({ ...formValidation, postcodeError: false })
    }

    const questionResultsTemp =
      questionResults.length > 0 ? [...questionResults] : [];
    if (questionResultsTemp.length > 0) {
      const filterQuestionResults = questionResultsTemp.filter(
        (qu) => qu.id === question.id
      );
      if (filterQuestionResults.length > 0) {
        filterQuestionResults[0].answer = currentValue.replace(/\s/g, '');
        questionResultsTemp.forEach((item) => {
          if (item.id === filterQuestionResults[0].id) {
            item = { ...filterQuestionResults[0] };
            item.type = question.type;
            item.question = question.value;
          }
        });
      } else {
        const selectedAnswerBase = { questionNumber: 0, answer: '' };
        selectedAnswerBase.id = question.id;
        selectedAnswerBase.type = question.type;
        selectedAnswerBase.questionNumber = questionIndex;
        selectedAnswerBase.question = question.value;
        selectedAnswerBase.answer = currentValue.replace(/\s/g, '');
        selectedAnswerBase.answers = [];
        questionResultsTemp.push(selectedAnswerBase);
      }
    } else {
      const selectedAnswerBase = { questionNumber: 0, answer: '' };
      selectedAnswerBase.id = question.id;
      selectedAnswerBase.type = question.type;
      selectedAnswerBase.questionNumber = questionIndex;
      selectedAnswerBase.question = question.value;
      selectedAnswerBase.answer = currentValue.replace(/\s/g, '');
      selectedAnswerBase.answers = [];
      questionResultsTemp.push(selectedAnswerBase);
    }
    setQuestionResults([...questionResultsTemp]); // currently assigns data to the question results in parent.
  };

  useEffect(() => {
    handleAnswer(value);
  }, [value]);

  const { postcodeError } = formValidation

  return (
    <TextField
      value={value}
      error={postcodeError}
      helperText={postcodeError ? 'Please enter a valid postcode' : ''}
      onChange={async (e) => setValue(e.target.value)}
    />
  );
};
