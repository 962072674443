import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import logoPavers from '../assets/pavers_large_image_v2.png';
import logoJones from '../assets/Jones_large_image.jpg'

const CampInactive = ({ brand, redirect }) => {
  React.useEffect(() => setTimeout(() => window.location.href = redirect, 10000), [])
  return (
    <>
      <Box display="flex" flexDirection="column" gap="7em">
        <Box component={Paper} p={2} mt={2}>
          <Typography textAlign="center" fontSize="1.5em"><b>This campaign is inactive</b></Typography>
          <Typography textAlign="center" fontSize="1.2em">
            This could be because it has not started
            yet, it has ended, or we've received the maximum number of responses.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <img src={brand === 1 ? logoPavers : logoJones} alt={brand === 1 ? 'Pavers Logo' : 'Jones Logo'} width={brand === 1 ? "50%" : "70%"} />
        </Box>
      </Box>
    </>
  );
};
export default CampInactive;
