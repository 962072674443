import { AddCircle, DeleteSweep, Queue, RestartAlt } from '@mui/icons-material';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  Card,
  Tooltip,
} from '@mui/material';
import { Reorder, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AddProductStyles } from '../../../styles/addProductModal';
import { CampaignPictures } from '../CampaignPictures/campaignPictures';
import ScrollableFeed from 'react-scrollable-feed';
import { randomId } from '../../helpers';

export const EditProduct = ({
  handleClose,
  openModal,
  editNewProduct,
  item,
  priceOptions,
  campType,
  enqueueSnackbar,
  products
}) => {
  const classes = AddProductStyles();
  const [editProduct, setEditProduct] = useState({});
  const [editImages, setEditImages] = useState([]);
  const [editPriceOptions, setEditPriceOptions] = useState(item.pricingOptions);
  const [validation, setValidation] = useState({
    productCategory: false,
    altCode: false,
    colour: false,
    descriptionTitle: { show: false, duplicate: false },
    priceOptions: {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false
    }
  })
  const [imagesToRemove, setImagesToRemove] = useState([]);

  const manageProductOptions = () => {
    let tempProductOptions =
      editPriceOptions?.values.length > 0 ? [...editPriceOptions?.values] : [];
    let tempValidation = { ...validation };

    if (tempProductOptions?.length === 0) {
      for (let i = 0; i < priceOptions; i++) {
        tempProductOptions.push('');
      }
    }

    if (tempProductOptions?.length > 0) {
      for (let i = 0; i < tempProductOptions?.length; i++) {
        if (tempProductOptions?.length < priceOptions) {
          tempProductOptions.push('');
        }
      }

      let tempPriceOptions = { ...validation.priceOptions };

      tempProductOptions.forEach((option, index) => {
        if (option === '') {
          tempPriceOptions = { ...tempPriceOptions, [index]: true }
        } else {
          tempPriceOptions = { ...tempPriceOptions, [index]: false }
        }
      })

      tempValidation = { ...tempValidation, priceOptions: tempPriceOptions }
    }

    setValidation({ ...tempValidation })
    setEditPriceOptions({
      ...editPriceOptions,
      values: [...tempProductOptions],
    });
  };

  useEffect(() => {
    setEditProduct(item);
    setEditImages(item.images);

    // create a remove array that will be appended to the end of a product list when making campaign updates.
    // these images will be updated/removed when making updates
    setImagesToRemove(item.images ? [...item.images.filter((img) => img.remove)] : [])
  }, [item]);

  useEffect(() => {
    manageProductOptions();
  }, []);

  useEffect(() => {
    if (openModal) {
      editPriceOptionsWarning();
    }
  }, [openModal]);

  const editPriceOptionsWarning = () => {
    if (campType === 'Consumer') {
      let tempEditPriceOptions = [];
      if (editPriceOptions.values.length > 0) {
        tempEditPriceOptions = editPriceOptions.values.filter(
          (priceOption) => priceOption === ''
        );
      }
      if (tempEditPriceOptions.length > 0) {
        enqueueSnackbar('Please enter a value for all product feature options', { variant: 'info' })
        return;
      }
    }
    return;
  };

  const removeCurrentItem = (e, item) => {
    // const newImages = [...editImages].filter(
    //   (image, index) => image.id !== item.id && item
    // );

    let newImages = [...editImages];
    let removeImagesTemp = imagesToRemove.length > 0 ? [...imagesToRemove] : []

    // if no name (if not replacing the existing name, then remove from the list)
    // if its a new locally made image and its removed, then remove it from the list completely
    if (!item.name) {
      newImages = newImages.filter((img) => img.id !== item.id);
    } else {

      for (const newImg of newImages) {
        if (newImg.id === item.id && newImg.name) {
          removeImagesTemp.push({ ...newImg, remove: true, imageNumber: -1 })
          setImagesToRemove(removeImagesTemp)
        }
      }

      newImages = newImages.filter((img) => img.id !== item.id);
    }

    setEditImages(newImages.length > 0 ? newImages : [{
      id: randomId(),
      imageNumber: 0,
      image: '',
    }]);
    setEditProduct({
      ...editProduct, images: newImages.length > 0 ? newImages : [{
        id: randomId(),
        imageNumber: 0,
        image: '',
      }]
    });
  };

  const handleEditProduct = (e) => {
    const id = e.target.id;
    if (e.target.name === 'image') {
      if (e.target.files && e.target.files[0]) {
        // used to specifically edit image pictures
        const tempExtraPictures = [...editImages];
        if (tempExtraPictures.length === 1) {
          tempExtraPictures?.forEach((item, index) => {
            if (item.id === id) {
              item.image = URL.createObjectURL(e.target.files[0]);
            } else {
              item.id = uuidv4();
              item.imageNumber = index;
              item.image = URL.createObjectURL(e.target.files[0]);
            }
          });
        } else if (tempExtraPictures.length > 1) {
          tempExtraPictures?.forEach((item, index) => {
            if (item.id === id) {
              item.image = URL.createObjectURL(e.target.files[0]);
            }
          });
        } else {
          tempExtraPictures.push({
            items: {
              id: id,
              imageNumber: 0,
              image: URL.createObjectURL(e.target.files[0]),
            },
          });
        }

        setEditProduct({
          ...editProduct,
          images: [...tempExtraPictures],
        });
      } else {
        // used to maneurover the position of input, to change the position of the item
        const tempExtraPictures = [...editImages];
        tempExtraPictures?.forEach((item, index) => {
          if (item.id === id) {
            item.imageNumber = index;
          }
          if (item.id !== id) {
            item.imageNumber = index;
          }
        });

        setEditProduct({
          ...editProduct,
          images: [...tempExtraPictures],
        });
      }
    } else if (e.target.name === 'items') {
      // price options
      const id = e.target.id;

      let wholePriceOptions = { ...editPriceOptions };
      const tempPriceOptions = [...wholePriceOptions.values];

      const itemToUpdate = [...tempPriceOptions].map((item, index) => {
        if (index.toString() === id) {
          return (item = e.target.value);
        } else {
          return item;
        }
      });

      wholePriceOptions.values = [...itemToUpdate];

      // validate each individual price option
      let validationPriceOptions = { ...validation.priceOptions };
      const priceOptionLocation = itemToUpdate.findIndex((_item, ind) => {
        if (ind === parseInt(id)) {
          return ind
        }
        else {
          return -1
        }
      })

      if (priceOptionLocation !== -1) {
        // get price option value
        const priceOptionValue = itemToUpdate.filter((item, ind) => ind === parseInt(id))[0]
        if (priceOptionValue !== '' || priceOptionValue.length > 0) {
          validationPriceOptions = { ...validationPriceOptions, [id]: false }
        } else {
          validationPriceOptions = { ...validationPriceOptions, [id]: true }
        }
      }

      setValidation({ ...validation, priceOptions: { ...validationPriceOptions } })



      setEditProduct({
        ...editProduct,
        pricingOptions: { ...wholePriceOptions },
      });
      setEditPriceOptions({ ...wholePriceOptions });
    } else if (e.target.name === 'altCode') {
      setEditProduct({ ...editProduct, [e.target.name]: e.target.value.replace(/\s/g, '') });
      if (editProduct.altCode !== e.target.value && e.target.value.length > 0) {
        setValidation({ ...validation, altCode: false })
      } else if (e.target.value.length === 0) {
        setValidation({ ...validation, altCode: true })
      }
    } else if (e.target.name === 'colour') {
      setEditProduct({ ...editProduct, [e.target.name]: e.target.value.replace(/[^a-zA-Z]+/g, '') });
      if (editProduct.altCode !== e.target.value && e.target.value.length > 0) {
        setValidation({ ...validation, colour: false })
      } else if (e.target.value.length === 0) {
        setValidation({ ...validation, colour: true })
      }
    }
    else if (e.target.name === 'productCategory') {
      setEditProduct({ ...editProduct, [e.target.name]: e.target.value });
      if (editProduct.altCode !== e.target.value && e.target.value.length > 0) {
        setValidation({ ...validation, productCategory: false })
      } else if (e.target.value.length === 0) {
        setValidation({ ...validation, productCategory: true })
      }
    }
    else if (e.target.name === 'descriptionTitle') {
      setEditProduct({ ...editProduct, [e.target.name]: e.target.value });
      const checkDescriptionTitles = products.filter((tempProd) => tempProd.descriptionTitle === e.target.value && !tempProd.removed && tempProd.productID !== editProduct.productID)

      if (checkDescriptionTitles && checkDescriptionTitles.length > 0) {
        setValidation({ ...validation, descriptionTitle: { show: true, duplicate: true } })
      } else {
        setValidation({ ...validation, descriptionTitle: { show: false, duplicate: false } })
      }

      setEditProduct({ ...editProduct, [e.target.name]: e.target.value });
    }
    else {
      setEditProduct({ ...editProduct, [e.target.name]: e.target.value });
    }

    // show toast to show product added etc...
  };

  const handleEditOnClick = () => {
    let sortImages = []
    let removeImages = []

    if (editImages?.length > 0) {
      for (const img of editImages) {
        if (img.remove) {
          removeImages.push(img)
        } else {
          sortImages.push(img)
        }
      }

      sortImages = sortImages.map((img, index) => {
        return { ...img, imageNumber: index }
      })

      for (const rImages of imagesToRemove) {
        // add remove images back in to the sorted images 
        // so when updating, the remove ones can go through the delete process in the API.
        sortImages.push(rImages)
      }
    }

    editNewProduct({ ...editProduct, images: sortImages });
    handleClose();
  };

  const addPictureField = () => {
    const tempExtraPictures = editImages.length > 0 ? [...editImages] : [];
    tempExtraPictures.push({
      image: '',
      imageNumber: tempExtraPictures.filter((img) => !img.remove).length,
      id: uuidv4(),
    });

    setEditImages([...tempExtraPictures]);
    setEditProduct({
      ...editProduct,
      images: [...tempExtraPictures],
    });
  };

  const addMultiplePictureFields = (e) => {
    const importedFiles = [...e.target.files]

    // check if there is a remove tag.
    const editImagesTemp = editImages.filter((img) => !img.remove)?.length > 0 ? [...editImages.filter((img) => !img.remove)] : [];
    const removedImages = editImages.filter((img) => img.remove);

    if (editImagesTemp.length <= 1) {
      if (editImagesTemp.length === 1) {
        if (editImagesTemp[0]?.image?.length === 0 || editImagesTemp[0]?.name?.length === 0) {
          // // have to consider the remove/deleted tag on edited products being added here
          // perhaps also check if it has a name already??
          // if it has a name and wants to be updated then add remove tag etc rather than pop...
          if (editImagesTemp[0].name) {
            editImagesTemp[0].remove = true
          } else {
            editImagesTemp.pop();
          }
        }
      };

      for (const file of importedFiles) {
        editImagesTemp.push({
          id: randomId(),
          // imageNumber: counter,
          imageNumber: editImagesTemp.length,
          image: URL.createObjectURL(file),
        });
        // counter++;
      };
    }


    // have to consider the remove/deleted tag on edited products being added here
    if (editImagesTemp.length > 5) {
      editImagesTemp.length = 5;
      enqueueSnackbar('More than 5 products added, defaulting product selection to 5.', { variant: 'info' })
    }

    if (removedImages.length > 0) {
      for (const fileToRemove of removedImages) {
        editImagesTemp.push({ ...fileToRemove })
      }
    }


    setEditImages([...editImagesTemp]);
    setEditProduct({ ...editProduct, images: [...editImagesTemp] })
  }

  const resetProduct = () => {
    setEditImages([{ id: uuidv4(), imageNumber: 0, image: editProduct.image }])
    setEditProduct({ ...editProduct, images: [] })
    if (campType === 'Consumer') {
      setEditPriceOptions([]);
    }
    setValidation({ ...validation, altCode: false, colour: false, productCategory: false, descriptionTitle: false, pricingOptions: { '0': false, '1': false, '2': false, '3': false, '4': false } })
  };

  const resetProductImages = () => {
    // reverts to what it was previously
    setEditImages([...item.images])
    setEditProduct({ ...editProduct, images: item.images })

    if (imagesToRemove && imagesToRemove?.length > 0) {
      let tempRemoveImages = [...imagesToRemove];
      for (const img of item.images) {
        const exists = tempRemoveImages.find((i) => i.id === img.id);
        if (exists) {
          tempRemoveImages = tempRemoveImages.filter((i) => i.id !== img.id)
        }
      }
      setImagesToRemove(tempRemoveImages)
    }
  }

  const clearProductImages = () => {
    // clears images so can add new ones quickly
    const tempEditImages = [...editImages]
    setEditImages([{ id: uuidv4(), imageNumber: 0, image: '' }])
    setEditProduct({ ...editProduct, images: [] })
    setImagesToRemove(tempEditImages.map((i) => {
      return { ...i, remove: true, imageNumber: -1 }
    }))
  }

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} fullWidth>
        <Box display='flex'
          flexDirection='column'
          gap='1em'
          alignItems='center'
          style={{ overflowY: 'auto' }}
          p='1em'>
          <Typography variant='h4' style={{ margin: '1em 0' }}>
            Edit product
          </Typography>
          <Box display='flex' alignItems='center'>
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box display={'flex'} flexDirection='column' gap='1em' width='100%'>
                <Typography variant='h6'>Product details</Typography>
                <TextField
                  fullWidth
                  required
                  label='Product Category'
                  name='productCategory'
                  value={editProduct?.productCategory}
                  onChange={handleEditProduct}
                  error={validation.productCategory}
                  helperText={validation.productCategory && 'Product category required'}
                />
                <TextField
                  fullWidth
                  required
                  label='Alt Code'
                  name='altCode'
                  value={editProduct?.altCode}
                  onChange={handleEditProduct}
                  error={validation.altCode}
                  helperText={validation.altCode && 'Altcode needed'}
                />
                <Box>
                  <TextField
                    fullWidth
                    label='Title Description'
                    name='descriptionTitle'
                    value={editProduct.descriptionTitle}
                    onChange={handleEditProduct}
                    error={validation.descriptionTitle.show}
                    helperText={validation.descriptionTitle.show && validation.descriptionTitle.duplicate ? 'This title already exists, please try another' : validation.descriptionTitle.show && !validation.descriptionTitle.duplicate ? 'Please insert a title description' : ''}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label='Item Description'
                    name='descriptionText'
                    multiline
                    rows={4}
                    value={editProduct.descriptionText}
                    onChange={handleEditProduct}
                    inputProps={{ maxLength: 350 }}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    required
                    label='Item Colour'
                    name='colour'
                    value={editProduct.colour}
                    onChange={handleEditProduct}
                    error={validation.colour}
                    helperText={validation.colour && 'Colour needed'}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label='Product Price'
                    name='pricingText'
                    value={editProduct.pricingText}
                    onChange={handleEditProduct}
                  />
                </Box>
              </Box>
              <Card
                variant='outlined'
                style={{
                  textAlign: 'center',
                  margin: '1em 0',
                  backgroundColor: '#FAFAFA',
                }}
              >
                <Typography variant='h6' margin='1em 0'>
                  Manage product images
                </Typography>
                <motion.div
                  layoutScroll
                  style={{ overflow: 'none', width: '100%' }}
                >
                  <Reorder.Group
                    values={editImages}
                    onReorder={setEditImages}
                    className={classes.regroupContainer}
                  >
                    <ScrollableFeed>
                      {editImages?.map((item, index) => {
                        return !item.remove && <CampaignPictures
                          key={item.id}
                          item={item}
                          index={index}
                          name='image'
                          handleEditProduct={handleEditProduct}
                          removeItem={removeCurrentItem}
                          classes={classes}
                        />
                      })}
                    </ScrollableFeed>
                  </Reorder.Group>
                </motion.div>
                <Box
                  display={'flex'}
                  justifyContent='center'
                  marginY='1em'
                  gap={'1em'}
                >
                  <Tooltip title="Add multiple products">
                    <Button
                      variant='outlined'
                      component="label"
                      disabled={editImages.filter((img) => !img.remove).length > 1}
                    >
                      <Queue />
                      <input
                        hidden
                        type='file'
                        name='image'
                        onChange={(e) => addMultiplePictureFields(e)}
                        onClick={(event) => { event.target.value = null }}
                        multiple="multiple"
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add a product">
                    <Button
                      onClick={() => addPictureField()}
                      variant='outlined'
                      color='success'
                      disabled={editImages?.filter((img) => !img.remove).length === 5}
                    >
                      <AddCircle />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Remove all pictures">
                    <Button
                      onClick={clearProductImages}
                      variant='outlined'
                      color='error'
                      disabled={editImages && editImages.length === 1 && (editImages[0].image === '' || editImages[0].name === '')}
                    >
                      <DeleteSweep />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Reset images">
                    <Button
                      variant='outlined'
                      component="label"
                      onClick={resetProductImages}
                    >
                      <RestartAlt />
                    </Button>
                  </Tooltip>
                </Box>
              </Card>
              {campType === 'Consumer' && (
                <Box display='flex' flexDirection='column' gap='1em' width="100%">
                  <Typography variant='h6'>Product Feature Options</Typography>
                  {editPriceOptions?.values?.length > 0 &&
                    editPriceOptions?.values?.map(
                      (
                        value,
                        index
                      ) => (
                        <TextField
                          fullWidth
                          key={index}
                          id={index.toString()}
                          required
                          label={`Product Feature Option ${index + 1}`}
                          name='items'
                          value={value}
                          onChange={(e) => handleEditProduct(e, 'items')}
                          error={validation.priceOptions[index]}
                          helperText={validation.priceOptions[index] ? 'Insert a pricing option' : ''}
                        />
                      )
                    )}
                </Box>
              )}
              <Box sx={{ mt: "1em", display: "flex", width: "100%", gap: "1em" }}>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    let tempValidation = { ...validation }
                    if (editProduct.altCode.length === 0) {
                      tempValidation = { ...tempValidation, altCode: true }
                    }

                    if (editProduct.colour.length === 0) {
                      tempValidation = { ...tempValidation, colour: true }
                    }

                    if (editProduct.productCategory.length === 0) {
                      tempValidation = { ...tempValidation, productCategory: true }
                    }

                    if (editProduct.descriptionTitle.length > 0) {
                      const checkForSameName = products.filter((tempProd) => tempProd.descriptionTitle === editProduct.descriptionTitle && !tempProd.removed && tempProd.productID !== editProduct.productID)

                      if (checkForSameName.length > 0) {
                        tempValidation = { ...tempValidation, descriptionTitle: { show: true, duplicate: true } }
                      }
                    } else if (editProduct.descriptionTitle.length === 0) {
                      tempValidation = { ...tempValidation, descriptionTitle: { show: true, duplicate: false } }
                    }

                    let priceOptionError = false;
                    if (campType === 'Consumer') {
                      priceOptionError = Object.values(tempValidation.priceOptions).some((item) => item === true)
                    }

                    // || tempValidation.priceOptions - for below
                    if (tempValidation.altCode || tempValidation.colour || tempValidation.productCategory || tempValidation.descriptionTitle.show || priceOptionError) {
                      setValidation({ ...tempValidation })

                      const errorMessage =
                        `Error when editing product - errors with the following field(s): 
                      ${tempValidation.productCategory ? '\nProduct Category' : ''} 
                      ${tempValidation.descriptionTitle.show ? '\nTitle Description' : ''} 
                      ${tempValidation.altCode ? '\nAlt Code' : ''}
                      ${tempValidation.colour ? '\nColour' : ''}
                      ${priceOptionError ? '\nProduct Options' : ''}
                      `

                      enqueueSnackbar(errorMessage, { variant: 'error' })
                      return
                    }

                    handleEditOnClick()
                  }}
                  variant='contained'
                  color='primary'
                >
                  Save changes
                </Button>
                <Button
                  sx={{ width: "100%" }}
                  onClick={(e) => {
                    handleClose(e)
                  }} variant='outlined'>
                  Close
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
