import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableHeaderCell } from '../../styles/miscStyles';

const QuestionsTable = ({ questions, answers, setProductPage, setEditable }) => {

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Question</TableHeaderCell>
            <TableHeaderCell>Answer</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((q) => (
            <TableRow key={q.id}>
              <TableCell>{q.value}</TableCell>
              <TableCell>
                {answers.find((a) => a.id === q.id)?.answer}
              </TableCell>
              <TableCell>
                <Button onClick={() => { setProductPage(false); setEditable(true) }}>
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionsTable;
