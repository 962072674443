import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const QuestionManager = ({
  localQuestions,
  campaignQuestionTypes,
  selectedQuestions,
  setSelectedQuestions,
  handleQuestionModalOpen,
  disabled,
  setIsFormDirty
}) => (
  <Box my={2}>
    {localQuestions && localQuestions.length > 0 && (
      <Autocomplete
        disabled={disabled}
        multiple
        fullWidth={true}
        id='question-manager'
        options={localQuestions.sort((a, b) =>
          a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1
        )} // all questions
        value={selectedQuestions} // pre-selected questions
        disableCloseOnSelect
        getOptionLabel={(option) => option.value || ''}
        onChange={(_event, options) => {
          setIsFormDirty(true);
          setSelectedQuestions(options);
        }}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id ? true : false
        }
        renderOption={(props, option, { selected }) => {
          return (
            <li key={option.id} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                key={option.id}
              />
              {`${option.value} - ${campaignQuestionTypes[option.type - 1]?.description
                }`}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Campaign Questions'
          />
        )}
      />
    )}

    <Button
      disabled={disabled}
      fullWidth
      variant='contained'
      sx={{ marginTop: 2, marginBottom: 2 }}
      onClick={handleQuestionModalOpen}
    >
      {'Add & Manage Questions'}
    </Button>
  </Box>
);
