import React, { useEffect, useState } from 'react';
import { animate, useMotionValue, Reorder } from 'framer-motion';
import { Box } from '@mui/system';
import { Button, Card, Grid, Typography } from '@mui/material';
import { EditProduct } from './Modals/editProduct';
import { DeleteProduct } from './Modals/deleteProduct';
import { CampaignPicturesView } from './CampaignPictures/CampaignPicturesView2';
import {
  ControlPointDuplicate,
  Delete,
  DragIndicator,
  Edit,
} from '@mui/icons-material';

const inactiveShadow = '0px 0px 0px rgba(0,0,0,0.8)';

const useRaisedShadow = (value) => {
  const boxShadow = useMotionValue(inactiveShadow);
  useEffect(() => {
    let isActive = false;
    value.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, '5px 5px 10px rgba(0,0,0,0.3)');
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [value, boxShadow]);

  return boxShadow;
};

export const CampProduct = ({
  item,
  editNewProduct,
  deleteNewProduct,
  duplicateProduct,
  priceOptions,
  campType,
  globalIndex,
  currentCampDataStatus,
  enqueueSnackbar,
  products
}) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [itemPictures, setItemPictures] = useState([]);
  const [productToDuplicate, setProductToDuplicate] = useState();
  const [trimmedDescription, setTrimmedDescription] = useState('');

  const [mainImage, setMainImage] = useState(null);
  const [secondaryImages, setSecondaryImages] = useState(null);

  useEffect(() => {
    // updated filter to consider 'removed' images
    let formatImages = []
    if (typeof item.images === 'string') {
      formatImages = JSON.parse(item.images)
    } else {
      formatImages = item.images;
    }

    setItemPictures(formatImages.filter((img) => !img.remove));

    // added by Mike to limit the length of the description as it appears in the products table
    if (item.descriptionText.length > 200) {
      const trimmed =
        item.descriptionText.split(' ').slice(0, 50).join(' ') + '...';
      setTrimmedDescription(trimmed);
    } else {
      setTrimmedDescription(item.descriptionText);
    }

    // on every item change, set duplicate object to that item when clicked...
    // setProductToDuplicate(_.cloneDeep(item));
    setProductToDuplicate({ ...item });
  }, [item]);

  // To extract the primary and, if they exist, secondary images
  useEffect(() => {
    if (itemPictures.length === 1) {
      setMainImage(itemPictures[0]);
      // reset secondary images
      setSecondaryImages(null);
    } else if (itemPictures.length >= 2) {
      const [first, ...rest] = itemPictures;
      setMainImage(first);
      setSecondaryImages(rest);
    } else {
      setMainImage(null)
      setSecondaryImages(null)
    }
  }, [itemPictures]);

  const handleEditModalOpen = () => {
    setOpenEditModal(true);
  };
  const handleEditModalClose = (e, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    setOpenEditModal(false);
  };

  const handleDeleteModalOpen = () => {
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Reorder.Item
        key={item} // productID crashes it.
        value={item}
        id={globalIndex}
        style={{
          boxShadow,
          y,
          listStyleType: 'none',
          cursor: 'pointer',
          marginTop: '1em',
          marginBottom: '1em',
          backgroundColor: '#fff',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: { xs: 2, md: 0 },
          }}
        >
          <Button disabled={currentCampDataStatus === 3}>
            {currentCampDataStatus !== 3 ? (
              <DragIndicator fontSize='large' style={{ color: '#005170' }} />
            ) : (
              <DragIndicator fontSize='large' color='disabled' />
            )}
          </Button>

          <Box>
            <Grid container spacing={2}>
              {/* IMAGE CONTAINER */}
              <Grid
                item
                xs={12}
                md={6}
                container
                spacing={1}
                alignItems='center'
              >
                <Grid item xs={6} alignSelf='stetch'>
                  {mainImage && <CampaignPicturesView pic={mainImage} />}
                </Grid>

                {secondaryImages ? (
                  <Grid
                    item
                    xs={6}
                    container
                    spacing={1}
                  // direction={secondaryImages.length === 2 ? 'column' : 'row'}
                  >
                    {secondaryImages.map((img, index) => (
                      <Grid key={index} item xs={6}>
                        <CampaignPicturesView
                          pic={img}
                        // width={secondaryImages.length === 2 ? '50%' : '100%'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>

              {/* PRODUCT DETAILS CONTAINER */}
              <Grid item xs={12} md={6}>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  gap={1}
                  flexWrap='nowrap'
                >
                  <Typography variant='h6'>{item.descriptionTitle}</Typography>
                  <Typography variant='h6'>{item.pricingText}</Typography>
                  {campType === 'Consumer' && (
                    <Box display='flex'>
                      <Typography variant='h6'>
                        Product Feature Options:{' '}
                      </Typography>

                      {item?.pricingOptions?.values?.map((option, index) => (
                        <Box key={index}>
                          <Typography sx={{ ml: 1 }}>{`${option}${index === item.pricingOptions.values.length - 1
                            ? ''
                            : ', '
                            }`}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}

                  <Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    {trimmedDescription}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            display='flex'
            flexDirection={{ md: 'column', sx: 'row' }}
            gap={2}
            mt={{ xs: 2, md: 0 }}
          >
            <Button
              disabled={currentCampDataStatus === 3}
              onClick={handleEditModalOpen}
              style={{ padding: 0, color: '#005170' }}
            >
              {currentCampDataStatus !== 3 ? (
                <Edit fontSize='large' />
              ) : (
                <Edit fontSize='large' color='disabled' />
              )}
            </Button>
            <Button
              disabled={currentCampDataStatus === 3}
              onClick={handleDeleteModalOpen}
              style={{ padding: 0, color: '#005170' }}
            >
              {currentCampDataStatus !== 3 ? (
                <Delete fontSize='large' />
              ) : (
                <Delete fontSize='large' color='disabled' />
              )}
            </Button>
            <Button
              disabled={currentCampDataStatus === 3}
              onClick={() =>
                duplicateProduct({ ...productToDuplicate }, [...itemPictures])
              }
              style={{ padding: 0, color: '#005170' }}
            >
              {currentCampDataStatus !== 3 ? (
                <ControlPointDuplicate fontSize='large' />
              ) : (
                <ControlPointDuplicate fontSize='large' color='disabled' />
              )}
            </Button>
          </Box>
        </Card>

        <EditProduct
          openModal={openEditModal}
          handleClose={handleEditModalClose}
          item={item}
          editNewProduct={editNewProduct}
          priceOptions={priceOptions}
          campType={campType}
          enqueueSnackbar={enqueueSnackbar}
          products={products}
        />
        <DeleteProduct
          openModal={openDeleteModal}
          handleClose={handleDeleteModalClose}
          item={item}
          deleteNewProduct={deleteNewProduct}
        />
      </Reorder.Item>
    </>
  );
};
