import { Box, Button, Dialog, DialogTitle } from '@mui/material';

export const DeleteProduct = ({
  handleClose,
  openModal,
  deleteNewProduct,
  item,
}) => {
  const handleDelete = () => {
    deleteNewProduct(item);
    handleClose();
  };

  return (
    <Dialog open={openModal} onClose={handleClose}>
      <DialogTitle>Are you sure you want to delete this product?</DialogTitle>
      <Box display='flex' justifyContent='space-between' p={2}>
        <Button
          onClick={() => handleDelete()}
          variant='contained'
          color='error'
        >
          Delete Item
        </Button>
        <Button onClick={handleClose} variant='outlined' color='primary'>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
