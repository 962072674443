import { Box, Button, Card, Input } from '@mui/material';
import { Reorder } from 'framer-motion';
import { useEffect, useState } from 'react';

const { REACT_APP_CLOUD_BUCKET_URL } = process.env;

export const CampaignPictures = ({
  item,
  handleEditProduct,
  index,
  value,
  name,
  classes,
  removeItem,
}) => {
  return (
    <Reorder.Item
      value={item}
      id={item.id}
      key={item.id}
      style={{
        listStyleType: 'none',
        cursor: 'pointer',
        marginTop: '1em',
        marginBottom: '1em',
        backgroundColor: '#fff',
      }}
      onDragEnd={(e) => handleEditProduct(e)}
    >
      <Card style={{ minHeight: '300px' }} key={item.id}>
        <Button name={name}>Drag me</Button>
        <Button
          name={name}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '250px',
          }}
        >
          {(item.image || item.name) &&
            (item.imageNumber === 0 ? (
              <Box>
                {' '}
                <img
                  src={
                    item?.image
                      ? item.image
                      : `${REACT_APP_CLOUD_BUCKET_URL}${item.name}`
                  }
                  name={name}
                  className={classes.editLargePicture}
                  alt=''
                />
              </Box>
            ) : (
              <Box>
                <img
                  src={
                    item?.image
                      ? item.image
                      : `${REACT_APP_CLOUD_BUCKET_URL}${item.name}`
                  }
                  name={name}
                  className={classes.picture}
                  alt=''
                />
              </Box>
            ))}
          <Input
            id={item.id}
            type='file'
            name='image'
            onChange={handleEditProduct}
          />
        </Button>
        <Button onClick={(e) => removeItem(e, item)}>Remove</Button>
      </Card>
    </Reorder.Item>
  );
};
