import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { dobRegex, formatUserInputDate, formatMaxYearsForBirthday } from '../../helpers';
import { DatePicker } from '@mui/x-date-pickers';


export const RenderDateQuestion = ({
  question,
  setQuestions,
  allQuestions,
  setQuestionResults,
  questionResults,
  questionIndex,
  editable,
  resultsObj,
  formValidation,
  setFormValidation,
  checkForValidDate
}) => {

  const [value, setValue] = useState('');



  useEffect(() => {
    if (editable) {
      if (resultsObj && resultsObj.questionResults?.length > 0) {
        const match = resultsObj.questionResults.filter((item) => item.id === question.id)[0];
        setValue(match.answer);
      }
      setQuestionResults([...resultsObj.questionResults]);
    }
  }, [])

  const handleAnswer = (date) => {
    // once added, push selectedAnswer to a form array within the parent component. Then on save in the parent, it will be pushed to the API.
    const formattedDate = formatUserInputDate(date)

    if (formValidation.dobError) {
      setFormValidation({ ...formValidation, dobError: false })
    }

    // get all questions
    const questionResultsTemp =
      questionResults.length > 0 ? [...questionResults] : [];
    if (questionResultsTemp.length > 0) {
      // const resultsIndex = questionResultsTemp.indexOf((qu) => {return qu.id === question.id});
      const filterQuestionResults = questionResultsTemp.filter(
        (qu) => qu.id === question.id
      );
      if (filterQuestionResults.length > 0) {
        filterQuestionResults[0].answer = formattedDate;
        questionResultsTemp.forEach((item) => {
          if (item.id === filterQuestionResults[0].id) {
            item = { ...filterQuestionResults[0] };
            item.type = question.type;
            item.question = question.value;
          }
        });
      } else {
        const selectedAnswerBase = { questionNumber: 0, answer: '' };
        selectedAnswerBase.id = question.id;
        selectedAnswerBase.type = question.type;
        selectedAnswerBase.questionNumber = questionIndex;
        selectedAnswerBase.question = question.value;
        selectedAnswerBase.answer = formattedDate;
        selectedAnswerBase.answers = [];
        questionResultsTemp.push(selectedAnswerBase);
      }
    } else {
      const selectedAnswerBase = { questionNumber: 0, answer: '' };
      selectedAnswerBase.id = question.id;
      selectedAnswerBase.type = question.type;
      selectedAnswerBase.questionNumber = questionIndex;
      selectedAnswerBase.question = question.value;
      selectedAnswerBase.answer = formattedDate;
      selectedAnswerBase.answers = [];
      questionResultsTemp.push(selectedAnswerBase);
    }

    setQuestionResults([...questionResultsTemp]); // currently assigns data to the question results in parent.
  };

  useEffect(() => {
    handleAnswer(value);
  }, [value]);

  const { dobError } = formValidation

  const handleChange = (val) => {
    setValue(val._d)
  }


  return (
    // <TextField
    //   type='date'
    //   value={value}
    //   error={dobError}
    //   helperText={dobError ? 'Please select a date' : ''}
    //   // error={questionResults.filter((q) => q.id === question.id)[0]?.isDobValid === false ? true : false}
    //   onChange={(e) => setValue(e.target.value)}
    //   inputProps={question.variant === 'Date Of Birth' && { max: formatMaxYearsForBirthday(new Date(), 12) }}
    // />
    <DatePicker
      // views={['day', 'month']}
      views={question.variant === 'Birthday' ? ['day', 'month'] : question.variant === 'Month / Year' ? ['month', 'year'] : ['year', 'month', 'day']}
      label={question.variant === 'Date Of Birth' ? 'Select date of birth' : question.variant === 'Birthday' ? 'Select Birthday' : 'Date Picker'}
      inputFormat={question.variant === 'Birthday' ? 'DD - MMMM' : question.variant === 'Month / Year' ? 'MMMM YYYY' : 'DD/MM/YY'}
      value={value}
      onChange={(val) => handleChange(val)}
      maxDate={question.variant === 'Date Of Birth' ? formatMaxYearsForBirthday(new Date(), 12) : formatMaxYearsForBirthday(new Date(), -1)}
      renderInput={(params) =>
        <TextField
          type={question.variant === 'Birthday' ? 'text' : 'date'}
          error={dobError}
          helperText={(question.variant === 'Birthday' && !value && value.length === 0) || dobError ? 'Select month and day' : ''}
          onKeyDown={(e) => e.preventDefault()} {...params}
        />
      }
    />

  );
};
