import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const CampInactive = () => {
    return (
        <Box component={Paper} p={2} mt={2}>
            <Typography>Invalid Campaign</Typography>
        </Box>
    );
};
export default CampInactive;