import React from 'react';
import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import axios from 'axios';
import { params } from '../../helpers';

const { REACT_APP_API_BASE_URI } = process.env;

export const DeleteCampaign = ({
  handleClose,
  openModal,
  campData,
  resetCampaign,
  getCampaigns,
  enqueueSnackbar
}) => {
  const deleteCampaign = async () => {
    try {
      handleClose();
      const response = await axios.delete(
        `${REACT_APP_API_BASE_URI}deleteCampaign/${campData.campaignId}`,
        params
      );

      if (response.status === 200) {
        enqueueSnackbar('Campaign Deleted.', { variant: 'success' })
        resetCampaign();
      } else {
        enqueueSnackbar('Error when deleting campaign.', { variant: 'error' })
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error when deleting campaign.', { variant: 'error' })
    }
  };
  return (
    <>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>
          Are you sure? All associated data and reporting will be lost
        </DialogTitle>
        <Box display='flex' justifyContent='space-between' p={2}>
          <Button
            onClick={() =>
              deleteCampaign().then(() => {
                getCampaigns();
              })
            }
            variant='contained'
            color='error'
          >
            Confirm
          </Button>
          <Button onClick={handleClose} variant='outlined' color='primary'>
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
