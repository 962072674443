import { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Card,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CardContent,
  CardActions,
  Rating,
  Paper,
  TextField,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const { REACT_APP_CLOUD_BUCKET_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  imageGroup: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  mainImage: {
    [theme.breakpoints.down('md')]: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const CampViewProduct = ({
  currentProduct,
  campData,
  resultsObj,
  setResultsObj,
  remainingTokens,
  optionError,
  setOptionError,
  rankingError,
  setRankingError,
  enqueueSnackbar
}) => {
  const classes = useStyles();
  const [productImages, setProductImages] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [productResult, setProductResult] = useState(null);
  const [hover, setHover] = useState(-1);

  const [helperText, setHelperText] = useState('');
  const [rankingHelperText, setRankingHelperText] = useState('');

  const handleUpdateResults = () => {
    const updatedList = resultsObj.products.map((p) =>
      p.productID === currentProduct.productID ? productResult : p
    );

    setResultsObj((prev) => ({
      ...prev,
      products: updatedList,
    }));
  };

  const handleTokenChange = (newVal) => {
    const oldVal = productResult.tokenAnswer;
    if (remainingTokens + oldVal - newVal < 0) {
      enqueueSnackbar('Insufficent tokens remaining', { variant: 'error' })
      return;
    }
    setProductResult((prev) => ({
      ...prev,
      tokenAnswer: newVal,
    }));
  };

  useEffect(() => {
    setProductImages(currentProduct.images);
    //
  }, [currentProduct]);

  useEffect(() => {
    if (optionError) {
      setHelperText('Please select an option');
    } else {
      setHelperText('');
    }
  }, [optionError]);

  useEffect(() => {
    if (rankingError) {
      setRankingHelperText('Please provide a rating');
    } else {
      setRankingHelperText('');
    }
  }, [rankingError]);

  useEffect(() => {
    setProductResult(
      resultsObj?.products?.filter(
        (p) => p.productID === currentProduct.productID
      )[0]
    );
  }, [resultsObj, currentProduct.productID]);

  useEffect(() => {
    handleUpdateResults();
  }, [productResult]);

  useEffect(() => {
    //
    if (productImages && productImages[0]) {
      //
      setMainImage(productImages[0].name);
    } else {
      return;
    }
  }, [productImages]);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  });

  const StyledRatingStars = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: 'rgb(141, 209, 77)',
    },
    '& .MuiRating-iconHover': {
      color: 'rgb(141, 209, 77)',
    },
  });

  return (
    <>
      {currentProduct && productResult && (
        <Grid container maxWidth='xl' spacing={2}>
          <Grid item xs={12} md={6} sx={{ minHeight: '100%' }}>
            <Grid
              container
              spacing={2}
              sx={{
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} md={3} order={{ xs: 2, md: 1 }}>
                <Box
                  sx={{
                    // backgroundColor: '#fafafa',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  px={3}
                  className={classes.imageGroup}
                // height={380}
                >
                  {productImages &&
                    productImages.map((image, i) => (
                      <Box
                        height={100}
                        width={100}
                        key={i}
                        component={Paper}
                        m={1}
                        p={1}
                        sx={[
                          {
                            filter: 'grayscale(50%)',
                            transition: 'all 0.6s ease',
                            cursor: 'pointer',
                          },
                          {
                            '&:hover': { filter: 'grayscale(0)', boxShadow: 3 },
                          },
                          {
                            ...(image.name === mainImage && {
                              boxShadow: 4,
                              filter: 'grayscale(0)',
                            }),
                          },
                        ]}
                        onClick={() => setMainImage(image.name)}
                      >
                        <img
                          src={`${REACT_APP_CLOUD_BUCKET_URL}${image.name}`}
                          style={{ maxWidth: '100%' }}
                          alt='shoe'
                        />
                      </Box>
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={9} order={{ xs: 1, md: 2 }}>
                {mainImage && (
                  <Box component={Paper} p={2} className={classes.mainImage}>
                    <img
                      src={`${REACT_APP_CLOUD_BUCKET_URL}${mainImage}`}
                      style={{ maxHeight: '100%', maxWidth: '100%' }}
                      alt='main shoe'
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ p: 1 }}>
              <Typography sx={{ fontWeight: 'bold ' }}>
                {currentProduct.descriptionTitle}
              </Typography>
              <Typography sx={{ p: 2, color: 'primary.light' }}>
                {currentProduct.descriptionText}
              </Typography>

              {campData.type === 'Consumer' && (
                <Box>
                  <Box>
                    <Typography variant='h6' align='center'>
                      {campData.productFeatureQuestion}
                    </Typography>
                  </Box>
                  {currentProduct?.pricingOptions && (
                    <Box>
                      <FormControl
                        style={{ display: 'flex' }}
                        error={optionError}
                      >
                        <RadioGroup
                          value={productResult.chosenOption}
                          onChange={(e) => {
                            setOptionError(false);
                            setProductResult((prev) => ({
                              ...prev,
                              chosenOption: e.target.value,
                            }));
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            margin: '1em 0',
                          }}
                        >
                          {currentProduct.pricingOptions.values.map(
                            (price, i) => (
                              <FormControlLabel
                                key={i}
                                value={price}
                                control={<Radio size='medium' />}
                                label={price}
                                style={{
                                  flexDirection: 'column',
                                  margin: '0 1.25em',
                                }}
                              />
                            )
                          )}
                        </RadioGroup>
                        <FormHelperText
                          sx={{ fontSize: '17px', textAlign: 'center' }}
                        >
                          {helperText}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              )}

              {campData.type === 'Management' && (
                <Box mt={1}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Typography variant='h6'>Product Price</Typography>
                    <Typography variant='h5'>
                      {currentProduct.pricingText}
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <FormControl sx={{ display: 'flex' }} error={optionError}>
                      <RadioGroup
                        value={productResult.chosenOption}
                        onChange={(e) => {
                          setOptionError(false);
                          setProductResult((prev) => ({
                            ...prev,
                            chosenOption: e.target.value,
                          }));
                        }}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                          margin: '0 0 1em 0',
                        }}
                      >
                        {campData.priceRankingOptions.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            value={option}
                            control={<Radio size='medium' />}
                            label={option}
                            style={{
                              flexDirection: 'column',
                              margin: '0 1.25em',
                            }}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText
                        sx={{ fontSize: '17px', textAlign: 'center' }}
                      >
                        {helperText}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              )}
            </Card>

            {/* PRODUCT RANKING SECTION */}
            {campData.type === 'Consumer' && (
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '1.5em 0',
                  py: 2,
                }}
              >
                <CardContent>
                  <Box>
                    <Typography variant='h5' textAlign='center'>
                      What do you think of this product?
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Box>
                    <StyledRating
                      name='customized-color'
                      defaultValue={0}
                      value={productResult?.productRank}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? 's' : ''}, ${campData.productRankingOption[value]
                        }`
                      }
                      precision={1}
                      icon={<FavoriteIcon fontSize='inherit' />}
                      emptyIcon={<FavoriteBorderIcon fontSize='inherit' />}
                      max={campData.productRankingOption.length || 3}
                      onChange={(e) => {
                        setRankingError(false);
                        setProductResult((prev) => ({
                          ...prev,
                          productRank: Number(e.target.value),
                        }));
                      }}

                    // onChangeActive={(event, newHover) => {
                    //   setHover(newHover);
                    // }}
                    />
                    {productResult?.productRank !== null && (
                      <Box textAlign='center'>
                        {
                          campData.productRankingOption[
                          hover !== -1
                            ? hover - 1
                            : productResult?.productRank - 1
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </CardActions>
                {rankingError && (
                  <Box>
                    <Typography color='error.main'>
                      {rankingHelperText}
                    </Typography>
                  </Box>
                )}
              </Card>
            )}

            {/* TOKEN RATING SECTION */}
            {campData.type === 'Management' && (
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '1.5em 0',
                }}
              >
                <CardContent>
                  <Box>
                    <Typography variant='h5' textAlign='center'>
                      Positive Tokens
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Box display='flex'>
                    <StyledRatingStars
                      name='customized-10'
                      defaultValue={0}
                      max={campData.tokensPerProduct || 3}
                      value={productResult?.tokenAnswer}
                      onChange={(e, newVal) => handleTokenChange(newVal)}
                    />
                  </Box>
                </CardActions>
              </Card>
            )}
            {productResult && (
              <Box>
                <TextField
                  multiline
                  rows={2}
                  inputProps={{ maxLength: 255 }}
                  fullWidth
                  label='Comments'
                  value={productResult.comment}
                  onChange={(e) =>
                    setProductResult((prev) => ({
                      ...prev,
                      comment: e.target.value,
                    }))
                  }
                />
                <Typography align='right' sx={{ mt: '5px' }}>
                  {255 - productResult.comment.length}/255 characters remaining
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CampViewProduct;
