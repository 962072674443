import React, { useState } from 'react';
import './App.css';
import Home from './pages/Home';
import CampConfig from './pages/campConfig';
import CampOperational from './pages/campOperational';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Header from './components/header';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import Report from './pages/report';

import { default as theme } from './themes/paversTheme';
import './assets/fonts/fonts.css';
import { Container } from '@mui/material';

const Wrapper = ({ children, isAuthenticated }) => {
  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <Container maxWidth='lg'>{children}</Container>
    </>
  );
};

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(true); // FOR TESTING

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <div>
              <Routes>
                <Route
                  path='/'
                  element={
                    <Wrapper isAuthenticated={isAuthenticated}>
                      <Home
                        setIsAuthenticated={setIsAuthenticated}
                        isAuthenticated={isAuthenticated}
                      />
                    </Wrapper>
                  }
                />
                <Route path='/:url' element={<CampOperational />} />
                <Route
                  path='/build_campaign'
                  element={
                    <Wrapper isAuthenticated={isAuthenticated}>
                      {isAuthenticated ? (
                        <CampConfig />
                      ) : (
                        <Home
                          setIsAuthenticated={setIsAuthenticated}
                          isAuthenticated={isAuthenticated}
                        />
                      )}
                    </Wrapper>
                  }
                />
                <Route
                  path='/report_campaign'
                  element={
                    <Wrapper isAuthenticated={isAuthenticated}>
                      <Report />
                    </Wrapper>
                  }
                />
              </Routes>
            </div>
          </Router>
        </ThemeProvider>
      </SnackbarProvider >
    </LocalizationProvider>
  );
}

export default App;
