import moment from 'moment';
import axios from 'axios';


export const { REACT_APP_API_BASE_URI, REACT_APP_URL } = process.env;

export const params = {
  headers: {
    deviceToken: '0e07b48d-b30f-4e02-b554-f23aad4c4b35',
    'Access-Control-Allow-Origin': '*',
    'x-jwt-token':
      'eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds',
  },
};

export const externalParams = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-type'
  }
}

export const eurostopParams = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'x-jwt-token':
      'eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds',
  },
};

export const getCampaignTypes = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_API_BASE_URI}campaignTypes`,
      params
    );
  } catch (err) {
    console.error(err);
  }
};

export const getCampaignBrands = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_BASE_URI}getCampBrands`,
      params
    );
    return data.response;
  } catch (err) {
    console.error(err);
  }
};

export const getCampaignStatus = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_API_BASE_URI}campaignStatus`,
      params
    );

    // setStatuses([...response.data.response]);
    return response.data.response;
  } catch (err) {
    console.error(err);
  }
};

export const startResultsObj = {
  campName: '',
  campUrl: '',
  campType: '',
  branchCode: '',
  products: [],
  questionResults: [],
  completedAtDateTime: '',
};

export const addNewCampObject = {
  campaignId: '',
  campStatus: 4, // 1 - Ended - 2 Pending - 3 Active - 4 Auto, Based on times
  campMaxTokensMethod: '',
  campTokensPerProduct: 3,
  campPriceRankingOptions: [],
  //campProductRankingOptions: [''],
  campProductRankingOption: [],
  campQuestionLocation: 0,
  campSurveyOnly: false,
  campName: 'Add New Campaign',
  campType: '', // Consumer or Management
  typePriceOption: 3,
  typeProductOption: 3,
  questions: [],
  startDateTime: new Date(), // default
  endDateTime: new Date(), // default
  campUrl: '',
  campBrand: 1, // 1 - Pavers, 2 - Jones
  campRedirect: '',
  campProducts: [],
  campTokens: 0,
  maxTokensMethod: '',
  campEndingPageEnabled: false,
  campEndingPageText: '',
  campSurveyOnly: false
};

let currentDateSplit = new Date().toString().split(':');

export const currentDate = currentDateSplit[0] + ':' + currentDateSplit[1];

export const campDataBase = {     // default base
  campaignId: '',
  campMaxTokensMethod: '',
  campTokensPerProduct: 3,
  campStatus: 4, // 1 - ended, 2 - Pending, 3 - Started, 4 - Auto
  campName: '',
  campType: '', // Consumer or Management
  campPriceRankingOptions: [],
  typePriceOption: 3,
  typeProductOption: 3,
  campProductRankingOption: [],
  campQuestionLocation: 0,
  campSurveyOnly: false,
  // priceRankingOptions: new Array(3),
  questions: [],
  startDateTime: currentDate,
  endDateTime: currentDate,
  campUrl: '',
  campBrand: 1, // 1 for pavers / 2 for jones
  campRedirect: '',
  campProducts: [],
  campTokens: 0,
  maxTokensMethod: '',
  campEndingPageEnabled: false,
  campEndingPageText: '',
  campMaxResults: 0, // base is zero (or blank), if more then zero, then thats the cut off value point.
  campProductFeatureQuestion: 'What do you think this product is worth?',
  campEndingPageImageFileName: '',
};

export const validateEmail = (email) => {
  // const regexp =
  //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const isInValidDateRange = (startDate, endDate) => {
  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);
  return momentStart.isBefore() && momentEnd.isAfter();
};

export const convertImageUrlToBase64 = async (url) => {
  // url image to base64
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      //
      resolve(base64data);
      return base64data;
    };
  }).then((result) => {
    //
    return result;
  });
};

export const convertImage = async (image) => {
  let result;
  await convertImageUrlToBase64(image).then((response) => {
    result = response;
  });
  return result;
};

export const sorter = (a, b) => {
  const nameA = a.campName?.toUpperCase(); // ignore upper and lowercase
  const nameB = b.campName?.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const questionSorter = (a, b) => {
  const nameA = a.value.toUpperCase(); // ignore upper and lowercase
  const nameB = b.value.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const FORMFIELDWIDTH = 400;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ukPostcodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

export const postcodeFormat = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;

export const dobRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const emailRegex2 = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

// RFC 5322 Email format
// eslint-disable-next-line no-control-regex
const emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

export const randomId = function (length = 6) { return Math.random().toString(36) };

export const guidGenerator = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export const formatUserInputDate = (date) => {
  let formatDate = new Date(date)
  formatDate = formatDate.toLocaleDateString()
  return formatDate
}

export const formatMaxYearsForBirthday = (date, years) => {
  // 👇 make copy with "Date" constructor
  const dateCopy = new Date(date);

  dateCopy.setFullYear(date.getFullYear() - years);

  const [formattedDate] = dateCopy.toISOString().split('T');

  return formattedDate;
}

export const dateVariants = [
  {
    id: guidGenerator(),
    variant: 'Birthday'
  },
  {
    id: guidGenerator(),
    variant: 'Date Of Birth'
  },
  {
    id: guidGenerator(),
    variant: 'Month / Year'
  },
]


// export const dateVariants = [
//   'Birthday',
//   'Date Of Birth',
//   'Month / Year',
// ]

// Please leave these here - they will be useful later
// export const productRankingLabelsFive = [
//   {
//     label: 'Ranking One',
//     consumerPlaceholder: 'Strongly Dislike',
//     managementPlaceholder: 'Far Too Cheap',
//   },
//   {
//     label: 'Ranking Two',
//     consumerPlaceholder: 'Dislike',
//     managementPlaceholder: 'Too Cheap',
//   },
//   {
//     label: 'Ranking Three',
//     consumerPlaceholder: 'Neutral',
//     managementPlaceholder: 'Just Right',
//   },
//   {
//     label: 'Ranking Four',
//     consumerPlaceholder: 'Like',
//     managementPlaceholder: 'Too Expensive',
//   },
//   {
//     label: 'Ranking Five',
//     consumerPlaceholder: 'Love',
//     managementPlaceholder: 'Far Too Expensive',
//   },
// ];

// export const productRankingLabelsThree = [
//   {
//     label: 'Ranking One',
//     consumerPlaceholder: 'Dislike',
//     managementPlaceholder: 'Too Cheap',
//   },
//   {
//     label: 'Ranking Two',
//     consumerPlaceholder: 'Neutral',
//     managementPlaceholder: 'Just Right',
//   },
//   {
//     label: 'Ranking Three',
//     consumerPlaceholder: 'Like',
//     managementPlaceholder: 'Too Expensive',
//   },
// ];


// was on line 1438 in camp config, if moves, it was above the {values.campType === 'Consumer' ? ()} check.
{/* I AM, ONCE AGAIN, COMMENTING THIS OUT AT THE BEHEST OF OUR CAPRICIOUS OVERLORDS */ }
// eslint-disable-next-line no-lone-blocks
{/* <Box mt={2} display="flex" alignItems="center">
                <FormLabel sx={{ marginRight: 2 }}>
                  Questionnaire Location:
                </FormLabel>
                <RadioGroup
                  defaultValue={formik.values.campQuestionLocation}
                  value={formik.values.campQuestionLocation}
                  onChange={(e) => handleFormData(e, 'campQuestionLocation')}
                  row
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio disabled={currentCampDataStatus === 3} name="campQuestionLocation" />}
                    label="None"
                    id={formik.values.campaignId.toString()}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio disabled={currentCampDataStatus === 3} name="campQuestionLocation" />}
                    label="Start"
                    id={formik.values.campaignId.toString()}
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio name="campQuestionLocation" />}
                    label="End"
                    id={formik.values.campaignId.toString()}
                  />
                </RadioGroup>
              </Box> */}