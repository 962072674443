import React, { useEffect, useState } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';

export const SearchProducts = ({
  searchProduct,
  products,
  searchInputValue,
  setSearchInputValue,
  currentCampDataStatus,
}) => {
  return (
    <Box>
      {/* Search */}
      <Autocomplete
        freeSolo
        disabled={currentCampDataStatus === 3}
        fullWidth
        disablePortal
        onChange={(e, value) => {
          searchProduct(e, value);
        }}
        value={searchInputValue || ''}
        inputValue={searchInputValue}
        options={products.sort((a, b) =>
          a.descriptionTitle.toUpperCase() > b.descriptionTitle.toUpperCase()
            ? 1
            : -1
        )}
        getOptionLabel={(option) => option.descriptionTitle || ''}
        sx={{ width: 300 }}
        // clear
        renderInput={(params) => (
          <TextField
            onChange={(e) => setSearchInputValue(e.target.value)}
            {...params}
            label='Search for an Existing Product'
          />
        )}
      />
    </Box>
  );
};
