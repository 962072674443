import { Button, ButtonGroup } from "@mui/material"
import { Link } from "react-router-dom"

export const CampConfigFormButtonGroup = ({ values, setCampProdOpen, originalSurveyOnly, handleDeleteModalOpen, isANewCampaign, localQuestions }) => {
  return (
    <>
      <ButtonGroup
        fullWidth
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {(!Boolean(values.campSurveyOnly)) && (
          <Button
            variant='contained'
            sx={{ mr: 2 }}
            onClick={() => setCampProdOpen(true)}
          >
            Products ({values?.campProducts?.filter((product) => !product.removed).length})
          </Button>
        )}

        <Button
          component={Link}
          to={'/report_campaign'}
          state={{ campData: values, localQuestions: localQuestions }}
          variant='contained'
          sx={{ mr: 2 }}
        >
          View Report
        </Button>
        <Button
          component={Link}
          to={'/' + values.campUrl}
          state={{ preview: true }}
          variant='contained'
          disabled={!values.campaignId}
        >
          Preview Campaign
        </Button>
      </ButtonGroup>

      <Button
        sx={{ mt: 2 }}
        fullWidth
        variant='contained'
        onClick={handleDeleteModalOpen}
        disabled={!values.campaignId}
        color='error'
      >
        Delete Campaign
      </Button>
    </>
  )
}