/* General Imports */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/* MUI Imports */

import {
  Box,
  Button,
  Grid,
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* App Imports */
import CommentsModal from './Modals/CommentsModal';
import TokensModal from './Modals/TokensModal';
import {
  StyledRatingStars,
  tokenGreen,
  heartRed,
} from '../../styles/miscStyles';

const { REACT_APP_CLOUD_BUCKET_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  picture: {
    width: '80px',
    // height: '125px',
    '@media (max-width: 512px)': {
      width: '80px',
      // height: '125px',
    },
  },
}));

function ManagementReportRow({ product, campDocs }) {
  const location = useLocation();
  const { campData } = location.state;

  const classes = useStyles();

  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openTokenModal, setOpenTokenModal] = useState(false);

  const [pricingOptions, setPricingOptions] = useState([]);
  const [maxTokens, setMaxTokens] = useState(0);
  const [totalAssignedTokens, setTotalAssignedTokens] = useState(0);

  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    // create array of comment objects
    const comments = campDocs.map((doc) => {
      return {
        branchCode: doc.branchCode,
        comment: doc.products.filter(
          (p) => p.productID === product.productID
        )[0]?.comment,
      };
    });
    const filteredComments = comments.filter((c) => c.comment !== '');
    setComments(filteredComments);

    // tally up total no of tokens
    const assignedTokens = campDocs
      .map(
        (doc) =>
          doc.products.filter((prod) => prod.productID === product.productID)[0]
            ?.tokenAnswer
      )
      .reduce((a, c) => a + c, 0);
    // console.log(test, 'map results');
    setTotalAssignedTokens(assignedTokens);
  }, [campDocs, product]);

  useEffect(() => {
    setCommentCount(comments.length);
  }, [comments]);

  const handleCommentModalOpen = () => {
    setOpenCommentModal(true);
  };

  const handleCommentModalClose = () => {
    setOpenCommentModal(false);
  };

  const handleTokenModalOpen = () => {
    const temp = campDocs.map((doc) => {
      return {
        branchCode: doc.branchCode,
        tokens: doc.products.filter((p) => p.productID === product.productID)[0]
          .tokenAnswer,
      };
    });
    setTokens(temp);
    setOpenTokenModal(true);
  };

  const handleTokenModalClose = () => {
    setOpenTokenModal(false);
  };

  useEffect(() => {
    const pricingOptions = [...product.pricingOptions.values];
    // this will reverse the array in place
    pricingOptions.reverse();
    setPricingOptions(pricingOptions);
  }, [product]);

  useEffect(() => {
    setMaxTokens(campData.campTokensPerProduct);
  }, [campData]);

  return (
    <>
      <TableRow>
        <TableCell>
          <img
            src={`${REACT_APP_CLOUD_BUCKET_URL}${product.image}`}
            className={classes.picture}
            alt=''
          />
        </TableCell>
        <TableCell>{product.descriptionTitle}</TableCell>
        <TableCell>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h6'>{product.pricingText}</Typography>
            <Typography>Product Price</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box display='flex' justifyContent='space-between'>
            {pricingOptions.map((opt, i) => (
              <Grid item xs={4} key={i}>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  sx={{
                    color:
                      i === 0 ? heartRed : i === 1 ? 'inherit' : tokenGreen,
                  }}
                >
                  <Typography variant='h6'>
                    {product.optionResults[opt].toFixed(2)}%
                  </Typography>
                  <Typography>{opt}</Typography>
                </Box>
              </Grid>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            onClick={() => handleTokenModalOpen()}
            sx={{ cursor: 'pointer' }}
          >
            <StyledRatingStars
              max={maxTokens}
              value={product.tokenResult}
              readOnly
              precision={0.5}
            />
            <Box
              color={tokenGreen}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <Typography fontSize={12} sx={{ my: 0.5 }}>
                Average Positive Tokens
              </Typography>
              <Typography fontWeight='bold'>
                Total Tokens: {totalAssignedTokens}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Button onClick={() => handleCommentModalOpen()} variant='contained'>
            Comments ({commentCount})
          </Button>
        </TableCell>
      </TableRow>

      <CommentsModal
        open={openCommentModal}
        handleClose={handleCommentModalClose}
        comments={comments}
        type='Management'
      />
      <TokensModal
        open={openTokenModal}
        handleClose={handleTokenModalClose}
        tokens={tokens}
        max={maxTokens}
      />
    </>
  );
}

export default ManagementReportRow;
