/* GENERAL IMPORTS */

import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash'; // cool kids know _ is low-dash

/* MUI IMPORTS */

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Paper,
} from '@mui/material';

/* HEADERS FOR CSVLINK */

const consumerQuestionHeaders = [
  { label: 'Responder', key: 'branchCode' },
];

const consumerManagementHeaders = [
  { label: 'Branch Code', key: 'branchCode' },
  { label: 'Branch Name', key: 'branchName' }
];

const managementProductHeaders = [
  { label: 'Alt Code', key: 'altCode' },
  { label: 'Product Category', key: 'productCategory' },
  { label: 'Item Description', key: 'title' },
  { label: 'Colour', key: 'colour' },
  { label: 'Campaign Name', key: 'campName' },
  { label: 'Price', key: 'price' },
  { label: 'Option', key: 'option' },
  { label: 'Assigned Tokens', key: 'tokens' },
  { label: 'Comments', key: 'comment' },
  { label: 'Branch Code', key: 'branchCode' },
  { label: 'Branch Name', key: 'branchName' },
];

const consumerProductHeaders = [
  { label: 'Alt Code', key: 'altCode' },
  { label: 'Product Category', key: 'productCategory' },
  { label: 'Item Description', key: 'title' },
  { label: 'Colour', key: 'colour' },
  { label: 'Campaign Name', key: 'campName' },
  { label: 'Response Identity', key: 'responseCode' },
  { label: 'Price', key: 'price' },
  { label: 'Option', key: 'option' },
  { label: 'Assigned Hearts', key: 'rank' },
  { label: 'Comments', key: 'comment' },
];

const formatBranch = (branch) => {
  if (branch === null || branch === undefined) {
    return
  }

  const checkBranchCodeFormatting = branch.includes('-')

  if (checkBranchCodeFormatting) {
    const branchToSplit = branch.split('-')
    return branchToSplit;
  } else {
    return branch
  }
}

const ExportControl = ({ campDocs, campName, campType, campProducts, questionnaireOnly, questions, localQuestions }) => {
  const [downloadOption, setDownloadOption] = useState('questions');
  const [dataToDownload, setDataToDownload] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [fileName, setFileName] = useState('');

  const csvManageTableHeaders = (tHeaders, questionData) => {
    const headerExists = tHeaders.some((header) => header.key === questionData.question)
    if (!headerExists) {
      tHeaders.push({ label: `${questionData.question}`, key: `${questionData.question}` })
    }
  }

  const handleCsvClick = async (e, done) => {

    let formattedBranchCode = ''
    let formattedBranchName = ''

    if (downloadOption === 'questions') {
      let tempQuestionHeaders;
      if (campType === 'Management') {
        tempQuestionHeaders = [...consumerManagementHeaders];
      } else {
        tempQuestionHeaders = [...consumerQuestionHeaders];
      }


      const data = campDocs.map((doc) => {

        let resCode = parseInt(doc.responseCode.split('_')[1]);
        resCode = resCode === 0 ? resCode + 1 : resCode;

        const exportData = [...doc?.questionResults].map((q) => {
          const questionType = localQuestions.filter((u) => u.id === q.id);

          if (questionType && questionType.length > 0) {
            q.type = questionType[0].type
            q.question = questionType[0].value
          }

          if (q.answer.indexOf(',') > -1) q.answer = q.answer.split(',');

          return q
        })

        const dates = exportData.filter((d) => d.type === 7);
        const emails = exportData.filter((d) => d.type === 4);
        const multipleChoiceMultipleAnswer = exportData.filter((d) => d.type === 5)
        const rest = exportData.filter((d) => d.type !== 7 && d.type !== 4 && d.type !== 5);

        if (dates && dates.length > 0) dates.forEach((date) => {
          const questionType = localQuestions.filter((u) => u.id === date.id);

          if (questionType && questionType.length > 0) {
            date.variant = questionType[0].variant;
          }

          if (date.variant === 'Birthday') {
            const ans = date.answer.substring(0, date.answer.length - 5);
            date.answer = ans;
          } else if (date.variant === 'Month / Year') {
            const ans = date.answer.slice(3)
            date.answer = ans;
          }

          csvManageTableHeaders(tempQuestionHeaders, date)
        })

        if (emails && emails.length > 0) csvManageTableHeaders(tempQuestionHeaders, emails[0])

        if (rest && rest.length > 0) rest.forEach((r, _i) => csvManageTableHeaders(tempQuestionHeaders, r))

        if (multipleChoiceMultipleAnswer && multipleChoiceMultipleAnswer.length > 0) {
          for (const answerSet of multipleChoiceMultipleAnswer) {
            const selectableAnswers = answerSet.answers.answers;
            for (const a of selectableAnswers) {
              const headerExists = tempQuestionHeaders.some((header) => header.key === `${answerSet.question} (${a})`)
              if (!headerExists) {
                tempQuestionHeaders.push({ label: `${answerSet.question} (${a})`, key: `${answerSet.question} (${a})` })
              }
            }
          }
        }

        setHeaders(tempQuestionHeaders)

        let mergeMultiple = []

        const formattedBranch = formatBranch(doc?.branchCode);

        if (Array.isArray(formattedBranch)) {
          formattedBranchCode = formattedBranch[0].replace(/ /g, '')
          formattedBranchName = formattedBranch[1].trimStart()
        } else {
          formattedBranchCode = formattedBranch
          formattedBranchName = ''
        }

        const manageExportAnswers = exportData.map((exp, i) => {
          if (exp.type === 5) {
            const answers = [...exp.answers.answers]
            for (const a of answers) {
              let exists = false;
              const questionLink = { qu: `${exp.question} (${a})` };
              if (typeof exp.answer === 'string') {
                exists = a === exp.answer ? 1 : 0
              } else {
                exists = exp.answer.some((d) => d.includes(a))
              }
              mergeMultiple.push({ [questionLink.qu]: exists ? 1 : 0 })
            }
          }

          if (campType === 'Management') {
            return {
              [exp.question]: exp.answer,
              branchCode: formattedBranchCode ? formattedBranchCode : doc.branchCode,
              branchName: formattedBranchName && formattedBranchName?.length > 0 ? formattedBranchName : '',
            }
          } else {
            return {
              branchCode: resCode,
              [exp.question]: exp.answer,
            }
          }
        })

        mergeMultiple.forEach((m) => manageExportAnswers.push(m))

        const flattenData = _.assign.apply(_, manageExportAnswers)

        return flattenData
      })

      setFileName(`${campName}-questions.csv`);
      setDataToDownload(data);
    } else {
      const data = await campDocs.map((doc) => {

        const formattedBranch = formatBranch(doc?.branchCode);

        if (Array.isArray(formattedBranch)) {
          formattedBranchCode = formattedBranch[0].replace(/ /g, '')
          formattedBranchName = formattedBranch[1].trimStart()
        } else {
          formattedBranchCode = formattedBranch
          formattedBranchName = ''
        }

        let resCode = parseInt(doc.responseCode.split('_')[1]);
        resCode = resCode === 0 ? resCode + 1 : resCode;
        return doc.products.map((p) => {
          const campaignProducts = campProducts.filter((prod) => prod.productID === p.productID)[0];
          return {
            altCode: campaignProducts?.altCode,
            productCategory: campaignProducts?.productCategory,
            campName: doc.campName,
            colour: campaignProducts?.colour,
            responseCode: resCode,
            title: p.descriptionTitle,
            price: p.pricingText,
            option: p.chosenOption,
            tokens: p.tokenAnswer,
            rank: p.productRank,
            comment: p.comment,
            branchCode: formattedBranchCode ? formattedBranchCode : doc.branchCode,
            branchName: formattedBranchName && formattedBranchName?.length > 0 ? formattedBranchName : '',
          };
        });
      });
      if (campType === 'Management') {
        setHeaders(managementProductHeaders);
      } else {
        setHeaders(consumerProductHeaders);
      }
      setFileName(`${campName}-products.csv`);
      setDataToDownload(data.flat());
    }
  }

  const handleChange = (e) => {
    setDownloadOption(e.target.value);
  };

  return (
    <Box
      component={Paper}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      p={1}
    >
      <FormControl>
        <FormLabel>Export Data</FormLabel>
        <RadioGroup value={downloadOption} onChange={handleChange} row>
          <FormControlLabel
            value='questions'
            control={<Radio />}
            label='Questions'
          />
          <FormControlLabel
            value='products'
            control={<Radio />}
            label='Products'
            disabled={Boolean(questionnaireOnly)}
          />
        </RadioGroup>
      </FormControl>

      {campDocs.length > 0 ? (
        <CSVLink
          data={dataToDownload}
          headers={headers}
          filename={fileName}
          asyncOnClick={true}
          onClick={handleCsvClick}
          style={{ textDecoration: 'none' }}
        >
          <Button variant='contained'>Download</Button>
        </CSVLink>
      ) : (
        <Button variant='contained' disabled>
          Download
        </Button>
      )}
    </Box>
  );
};

export default ExportControl;