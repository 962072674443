import { Container, Box, Typography, Fab } from '@mui/material';
import logo from '../assets/perfectstyle.png';
import { makeStyles } from '@mui/styles';
import { Settings } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Login } from '../components/CampaignConfig/Modals/Login';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  settings: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Home = ({
  setIsAuthenticated,
  isAuthenticated
}) => {


  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [configModalOpen, setConfigModalOpen] = useState(false);

  const handleConfigModalOpen = () => setConfigModalOpen(true);
  const handleConfigModalClose = () => setConfigModalOpen(false);

  const classes = useStyles();

  useEffect(() => {
    if (!isAuthenticated) {
      enqueueSnackbar('You need to login to view the campaign page.', { variant: 'error' })
    }
  }, [isAuthenticated]);

  return (
    <>
      <Container maxWidth='md'>
        <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
          <img src={logo} alt='Pavers Logo' />
          <Box mt={2}>
            <Typography variant='h5'>
              Welcome to the Pavers Product Insight System.
            </Typography>
          </Box>
        </Box>
        <Login
          openModal={configModalOpen}
          handleClose={handleConfigModalClose}
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
          enqueueSnackbar={enqueueSnackbar}
        />
      </Container>

      <Box className={classes.settings}>
        <Fab
          color='primary'
          aria-label='add'
          style={{ padding: 1 }}
          onClick={() => !isAuthenticated ? handleConfigModalOpen() : navigate('/build_campaign')}
        >
          <Settings style={{ fontSize: '35px' }} />
        </Fab>
      </Box>
    </>
  );
};

export default Home;
