/* General Imports */

import { useState, useEffect } from 'react';
import axios from 'axios';

/* MUI Imports */

import { Autocomplete, TextField } from '@mui/material';

/* App Imports */

import { eurostopParams, params } from '../helpers';

const { REACT_APP_API_BASE_URI } = process.env;

const BranchCodeSelector = ({
  branchCode,
  setBranchCode,
  checkBranchCode,
  url,
}) => {
  const [branchData, setBranchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [branchesWithResults, setBranchesWithResults] = useState([]);
  const [branchHasCompleted, setBranchHasCompleted] = useState(false);

  // get list of branches from Eurostop Web API
  useEffect(() => {
    const getEuroStopAuthToken = async () => {
      setLoading(true);
      try {
        const res = await axios.post(
          `${REACT_APP_API_BASE_URI}postEurostopLogin`,
          {},
          eurostopParams
        );
        const { authToken } = res.data;
        getEuroStopBranchData(authToken);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };
    getEuroStopAuthToken();
  }, []);

  const getEuroStopBranchData = async (authToken) => {
    const { headers } = eurostopParams;
    try {
      axios
        .get(
          `${REACT_APP_API_BASE_URI}getEurostopBranchDetails?authToken=${authToken}`,
          { headers }
          // params
        )
        .then((res) => {
          setBranchData(res.data.tempBranchData);
        });
    } catch (e) {
      console.error(e);
    }
  };

  // get list of branches that have already completed the campaign
  useEffect(() => {
    const docFetcher = async () => {
      try {
        const res = await axios.get(
          `${REACT_APP_API_BASE_URI}getReportingList/${url}`,
          params
        );
        // console.log('in doc fetcher', res);
        const subBranches = res.data.response.map((r) => r.responseCode);
        setBranchesWithResults(subBranches);
      } catch (err) {
        // console.log(err.toJSON());
      }
    };
    docFetcher();
  }, [url]);

  // check to see if the selected branch has already completed the campaign
  useEffect(() => {
    if (branchesWithResults.includes(branchCode)) {
      setBranchHasCompleted(true);
    } else {
      setBranchHasCompleted(false);
    }
  }, [branchCode, branchesWithResults]);

  return branchData.length > 0 && !loading ? (
    <Autocomplete
      value={branchCode}
      id='branch-search'
      disableClearable
      onChange={(e, selectedValue) => {
        setBranchCode(selectedValue);
      }}
      // onInputChange
      options={branchData.map(
        (branch) => `${branch.branchCode} - ${branch.address}`
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label='What is your branch code?'
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
          error={!checkBranchCode() ? true : branchHasCompleted ? true : false}
          helperText={
            !checkBranchCode()
              ? 'Please enter your branch code.'
              : branchHasCompleted
                ? 'Data already exists for this branch'
                : ''
          }
        />
      )}
    />
  ) : (
    // provide textfield fallback in case there is no branchData.
    <TextField
      fullWidth
      label='What is your branch code?'
      value={branchCode}
      onChange={(e) => setBranchCode(e.target.value)}
      error={!checkBranchCode() ? true : false}
      helperText={!checkBranchCode() ? 'Please enter your branch code.' : ' '}
      inputProps={{ maxLength: 3 }}
    />
  );
};

export default BranchCodeSelector;
