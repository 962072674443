import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField, Typography } from '@mui/material';

export const CampConfigFormEndingPageOptions = ({ values,
  formik,
  setIsFormDirty,
  handleChange,
  currentCampDataStatus,
  handleAddEndingPageImage,
  duplicateCampaignSettings,
  setDuplicateCampaignSettings }) => {

  const { warningOptions } = duplicateCampaignSettings;

  return (
    values.campType === 'Consumer' ? (
      <Box>
        <Box mt={2} display='flex'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={currentCampDataStatus === 3}
                  checked={Boolean(values.campEndingPageEnabled)}
                  onChange={() => { formik.setFieldValue('campEndingPageEnabled', Boolean(!values.campEndingPageEnabled)); setIsFormDirty(true); }}
                />
              }
              label='Campaign Ending Page Enabled:'
              labelPlacement='start'
              sx={{ marginLeft: 0 }}
            />
          </FormGroup>
        </Box>
        {values?.campEndingPageEnabled && (
          <>
            <Box>
              <Box mt={2} display='flex'>
                <TextField
                  label='Campaign Ending Page Text'
                  multiline
                  rows={3}
                  value={values.campEndingPageText}
                  onChange={(e) => { handleChange('campEndingPageText')(e); setIsFormDirty(true); }}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  disabled={currentCampDataStatus === 3}
                />
              </Box>
              <Box mt={2} display='flex' border={warningOptions.toggleText && '1px solid #cf0a2c'} p={warningOptions.toggleText && 2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Box
                        display='flex'
                        alignItems={'center'}
                        justifyContent={'space-evenly'}
                        gap={'1em'}
                        marginLeft='1em'
                      >
                        <Button
                          size='small'
                          variant='outlined'
                          component='label'
                          color='primary'
                          disabled={currentCampDataStatus === 3}
                        >
                          Choose Image
                          <input
                            disabled={currentCampDataStatus === 3}
                            type='file'
                            // accept='.xlsx' // need to check file types
                            hidden
                            onChange={(e) => { handleAddEndingPageImage(e); setIsFormDirty(true); }}
                          />
                        </Button>
                        <Typography>
                          {values.campEndingPageImageFileName
                            ? values.campEndingPageImageFileName
                            : 'No File Selected'}
                        </Typography>
                      </Box>
                    }
                    label='Campaign Ending Page Image: '
                    labelPlacement='start'
                    sx={{ marginLeft: 0 }}
                  />
                </FormGroup>
              </Box>
            </Box>
            {warningOptions.toggleText && (
              <Box>
                <FormHelperText style={{ color: "#cf0a2c", marginLeft: "14px" }}>{warningOptions.warningDuplicateEndingPage}
                </FormHelperText>
              </Box>
            )}
          </>
        )}
      </Box>
    ) : (
      ''
    )
  )
}