import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const RenderMultipleQuestions = ({
  question,
  setQuestions,
  allQuestions,
  setQuestionResults,
  questionResults,
  questionIndex,
  type,
  editable,
  resultsObj
}) => {
  const [multipleAnswers, setMultipleAnswers] = useState(question?.answer?.answers?.length > 0 ? [...question?.answer?.answers] : []);

  const [checkboxAnswers, setCheckboxAnswers] = useState([{
    id: uuidv4(), toggled: false, answer: ''
  }]);

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {

    if (editable) {

      const match = resultsObj.questionResults.filter((item) => item.id === question.id)[0];
      const { answers, answer, type } = match;

      if (type === 2) {
        setMultipleAnswers([...answers])
        setValue(answer);
        setQuestionResults([...resultsObj.questionResults]);
        return;
      }

      // else if type === 5
      let tempCheckboxAnswers = [];

      answers.answers.forEach((answer) => {
        tempCheckboxAnswers.push({ toggled: false, answer: `${answer}`, id: uuidv4() });
      })

      const reestablishAnswerFormat = answer.split(', '); // string to array format

      tempCheckboxAnswers.map((ans) => { // loop through and if existing, then toggle for questionnaire
        reestablishAnswerFormat.forEach((i) => {
          if (ans.answer === i) {
            ans.toggled = true;
          }
        })

        return ans;
      })

      setCheckboxAnswers([...tempCheckboxAnswers])
      setQuestionResults([...resultsObj.questionResults]);

      return;
    }

    if (type === 5) {
      const { answers } = question;
      let tempCheckboxAnswers = [];
      answers.answers.forEach((answer) => {
        tempCheckboxAnswers.push({ toggled: false, answer: `${answer}`, id: uuidv4() });

      })

      setCheckboxAnswers([...tempCheckboxAnswers])
    }
  }, [])

  const handleCheckboxOnChange = (e, index) => {

    const questionResultsTemp =
      questionResults.length > 0 ? [...questionResults] : [];

    const tempCheckboxAnswers = [...checkboxAnswers].map((answer) => {
      if (answer.answer === e.target.value) {
        answer.toggled = e.target.checked;
      }
      return answer;
    })

    setCheckboxAnswers([...tempCheckboxAnswers]) // set checkbox answers to toggled/untoggled locally

    const filterCheckboxAnswers = [...tempCheckboxAnswers].map(({ toggled, id, answer }) => toggled && answer).filter((filt) => filt !== false).join(', '); // appending all answers together

    const formatAnswers = { ...question, questionNumber: questionIndex, question: question.value, answer: filterCheckboxAnswers }

    questionResultsTemp.length === 0 && (questionResultsTemp.push(formatAnswers));

    if (questionResultsTemp.length > 0) {

      const idViaIndex = questionResultsTemp.findIndex((ind) => ind.id === question.id);

      idViaIndex === -1 && (questionResultsTemp.push(formatAnswers)) // if question with result doesn't exist in the array..

      if (idViaIndex > -1) {
        questionResultsTemp.map((item) => {
          if (item.id === question.id) {
            item.type = question.type;
            item.question = question.value;
            item.answer = filterCheckboxAnswers;
            delete item.value;
          }
          return item;
        })
      }
    }

    setQuestionResults([...questionResultsTemp]); // currently assigns data to the question results in parent.
  }

  const handleRadioChange = (event, index) => {

    setValue(event.target.value);

    // get all questions
    const questionResultsTemp =
      questionResults.length > 0 ? [...questionResults] : [];
    if (questionResultsTemp.length > 0) {
      // const resultsIndex = questionResultsTemp.indexOf((qu) => {return qu.id === question.id});
      const filterQuestionResults = questionResultsTemp.filter(
        (qu) => qu.id === question.id
      );
      if (filterQuestionResults.length > 0) {
        filterQuestionResults[0].answer = event.target.value;
        questionResultsTemp.forEach((item) => {
          if (item.id === filterQuestionResults[0].id) {
            item = { ...filterQuestionResults[0] };
            item.type = question.type;
            item.question = question.value;
          }
        });
      } else {
        const selectedAnswerBase = { questionNumber: 0, answer: '' };
        selectedAnswerBase.id = question.id;
        selectedAnswerBase.type = question.type;
        selectedAnswerBase.questionNumber = questionIndex;
        selectedAnswerBase.question = question.value;
        selectedAnswerBase.answer = event.target.value;
        selectedAnswerBase.answers = question.answer.answers;
        questionResultsTemp.push(selectedAnswerBase);
      }
    } else {
      const selectedAnswerBase = { questionNumber: 0, answer: '' };
      selectedAnswerBase.id = question.id;
      selectedAnswerBase.type = question.type;
      selectedAnswerBase.questionNumber = questionIndex;
      selectedAnswerBase.question = question.value;
      selectedAnswerBase.answer = event.target.value;
      selectedAnswerBase.answers = question.answer.answers;
      questionResultsTemp.push(selectedAnswerBase);
    }
    setQuestionResults([...questionResultsTemp]); // currently assigns data to the question results in parent.
  };

  return (
    <FormControl error={error} variant='standard'>
      {type === 2 && (
        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
          {multipleAnswers?.map((answer, index) => (
            <FormControlLabel
              key={index}
              label={answer}
              value={answer}
              control={<Radio name={answer} checked={value === answer ? true : false} />}
              id={index}
              onClick={(e) => handleRadioChange(e, index)}
            />
          ))}
        </RadioGroup>
      )}

      {type === 5 && (
        <Box display={"flex"}>
          {checkboxAnswers?.map((answer, index) => (
            <FormControlLabel
              key={index}
              label={answer.answer}
              value={answer.answer}
              control={<Checkbox name={answer.answer} label={answer.answer} checked={answer.toggled} />}
              id={index}
              onClick={(e) => handleCheckboxOnChange(e, index)}
            />
          ))}
        </Box>
      )}
    </FormControl>
  );
};