import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Fab,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { ManageQuestions } from '../components/CampaignConfig/Modals/manageQuestions';
import moment from 'moment';
import _ from 'lodash'; // cool kids know _ is low-dash
import { DeleteCampaign } from '../components/CampaignConfig/Modals/deleteCampaign';
import HelpIcon from '@mui/icons-material/Help';
import { makeStyles } from '@mui/styles';
import Pdf from '../assets/PPIUserGuide.pdf';
import { params, convertImage, campDataBase, getCampaignBrands, getCampaignStatus } from '../components/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CampConfigForm } from '../components/CampaignConfig/campConfigForm';
import { useSnackbar } from 'notistack';

const { REACT_APP_API_BASE_URI } = process.env;

const CampConfig = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [campaignBrands, setCampaignBrands] = useState([]);

  const [statuses, setStatuses] = useState([]);
  const [campDataList, setCampDataList] = useState([]);
  const [localQuestions, setLocalQuestions] = useState([]);
  const [currentCampDataStatus, setCurrentCampDataStatus] = useState(0);
  const [previousProducts, setPreviousProducts] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isANewCampaign, setIsANewCampaign] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [disableSaveCampaign, setDisableSaveCampaign] = useState(false);
  const [campaignNameList, setCampaignNameList] = useState([]);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [gatheredPreviousProducts, setGatheredPreviousProducts] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [duplicateCampaignSettings, setDuplicateCampaignSettings] = useState({
    selectedCampaign: false,
    duplicated: false,
    warningOptions: {
      warningDuplicateNameText: 'Add a new campaign name.',
      warningDuplicateUrlText: 'Add a new campaign url.',
      warningDuplicateEndingPage: 'Add a new ending page image.',
      toggleText: false
    }
  });
  const [originalCampStatus, setOriginalCampStatus] = useState(0);
  const [originalCampEndDate, setOriginalCampEndDate] = useState("");
  const [originalCampStartDate, setOriginalCampStartDate] = useState("");
  const [originalSurveyOnly, setOriginalSurveyOnly] = useState(0);
  const [newCampaignId, setNewCampaignId] = useState(0);
  const [campId, setCampId] = useState('');

  const campaignValidationSchema = Yup.object().shape({
    campName: Yup.string().required().test('test campName length', 'Campaign name is too short!', (value) => value?.length < 2 ? false : true).required()
      .test('check for duplicate campNames', 'This campaign name already exists, try another!', (value) => {
        if (isANewCampaign) {
          return checkForDuplicateCampaignName() ? false : true
        } else { return checkForDuplicateCampaignName(value) ? false : true }
      }).required(),
    campType: Yup.string().required(),
    campUrl: Yup.string().required().test('test campUrl length', 'Campaign url is too short!', (value) => value?.length < 2 ? false : true).test('check for duplicate campUrls', 'This campaign url already exists, try another!', (value) => !checkForDuplicateCampaignUrl(value)
    ),
  });

  const formik = useFormik(({
    enableReinitialize: true,
    initialValues: { ...campDataBase },
    validationSchema: campaignValidationSchema,
    change: (e) => {
      setIsFormDirty(true);
    },
    onSubmit: (values) => {
      setDisableSaveCampaign(true);
      setTimeout(async () => {
        await saveCampaign();
      }, 800);
    },
  }))

  // destructure formik properties
  const { values, touched, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setValues } = formik;

  const createCampaign = async (campaignData) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}createCampaign`,
        campaignData,
        params
      );
      if (response.status === 200) {
        enqueueSnackbar('Changes Saved.', { variant: 'success' })
      } else if (response.status !== 200) {
        enqueueSnackbar('Error when creating a campaign, please try again later.', { variant: 'error' })
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error when creating a campaign, please try again later.', { variant: 'error' })
    }
  };

  const updateCampaign = async (campaignData) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_BASE_URI}updateCampaign`,
        campaignData,
        params
      );
      if (response.status === 200) {
        enqueueSnackbar('Changes Saved.', { variant: 'success' })
      } else if (response.status !== 200) {
        enqueueSnackbar('Error when updating a campaign, please try again later.', { variant: 'error' })
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error when updating a campaign, please try again later.', { variant: 'error' })
    }
  };

  const getCampaigns = async () => {
    if (!fetching) {
      setFetching(true);
    }
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}campaigns`,
        params
      );

      if (response.status === 200) {
        setCampDataList(response.data.newResponse);
        setCampaignNameList(response.data.allCampaignNames);
      }

      if (values.campName !== '') {
        setIsANewCampaign(false);
        const filteredCampaign = response.data.newResponse.filter(
          (campaign) => campaign.campName === values.campName
        )[0];

        // if no filteredCampaign found, return and the user should proceed to click on a campaign from the drop down.
        // then the campaign will be filtered out and managed that way.

        if (!filteredCampaign) {
          setFetching(false);
          return setIsANewCampaign(true); // a fix for deleting a campaign
        } else {
          setNewCampaignId(filteredCampaign.campaignId);
        }

        if (typeof filteredCampaign?.questions === 'string') {
          filteredCampaign.questions = JSON.parse(filteredCampaign.questions);
        }

        if (typeof filteredCampaign?.campProducts === 'string') {
          filteredCampaign.campProducts = JSON.parse(
            filteredCampaign.campProducts
          );
        }

        if (typeof filteredCampaign?.campPriceRankingOptions === 'string') {
          filteredCampaign.campPriceRankingOptions = JSON.parse(
            filteredCampaign.campPriceRankingOptions
          );
        }

        if (typeof filteredCampaign?.campProductRankingOption === 'string') {
          filteredCampaign.campProductRankingOption = JSON.parse(
            filteredCampaign.campProductRankingOption
          );
        }

        if (filteredCampaign.startDateTime && filteredCampaign.endDateTime) {
          filteredCampaign.startDateTime = moment(
            filteredCampaign.startDateTime
          ).format('YYYY-MM-DDTHH:mm');
          filteredCampaign.endDateTime = moment(
            filteredCampaign.endDateTime
          ).format('YYYY-MM-DDTHH:mm');
        }

        filteredCampaign.campaignId === '' &&
          (filteredCampaign.campaignId = uuidv4()); // if no campaign id generate a random one.

        const tempCampData = {
          ...filteredCampaign,
          typePriceOption: filteredCampaign?.campPriceRankingOptions?.length,
          typeProductOption: filteredCampaign?.campProductRankingOption?.length,
        };

        setOriginalCampStatus(tempCampData.campStatus);
        setOriginalCampEndDate(tempCampData.endDateTime);
        setOriginalCampStartDate(tempCampData.startDateTime);

        setValues({ ...tempCampData }); // after update, then set the campaign values...

        handleIncomingSelectedQuestions([...filteredCampaign.questions]); // curent questions already set for an existing campaign
        getProductsFromPreviousCampaigns(tempCampData);
      }
    } catch (err) {
      console.error(err);
      if (fetching) {
        setFetching(false);
      }
      setTimeout(() => {
        enqueueSnackbar('Error when fetching campaigns, Try again later.', { variant: 'error' })
      }, 2000);
    }
  };
  const [campaignQuestionTypes, setCampaignQuestionTypes] = useState([]);
  const getCampaignQuestionTypes = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}campaignQuestionTypes`,
        params
      );

      setCampaignQuestionTypes([...response.data.response]);
    } catch (err) {
      console.error(err);
    }
  };

  const getCampaignQuestions = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}campaignQuestions`,
        params
      );

      response.data.response.forEach((item) => {
        item.answers = JSON.parse(item.answer);
        item.answer = JSON.parse(item.answer);
      });

      if (localQuestions.length === 0) {
        setLocalQuestions(response.data.response);
      } else {
        const tempLocalQuestions = [];
        response.data?.response?.forEach((localQuestion) => {
          tempLocalQuestions.push(localQuestion); // push questions from global to local state.
        });

        setLocalQuestions([...tempLocalQuestions]); // global questions already added if they dont exist already.
      }
    } catch (err) {
      console.error(err);
      setTimeout(() => {
        enqueueSnackbar('Error when fetching campaigns, Try again later.', { variant: 'error' })
      }, 4000);
    }
  };

  const getConsumerProducts = async (tempCampData) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}getConsumerProducts?brand=${values.campBrand}`,
        params
      );

      if (response.data.response.length > 0) {
        if (tempCampData !== undefined) {
          parseDataForCampaign(response.data.response, tempCampData);
        } else {
          parseDataForCampaign(response.data.response);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getManagementProducts = async (tempCampData) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}getManagementProducts?brand=${values.campBrand}`,
        params
      );

      if (response.data.response.length > 0) {
        if (tempCampData !== undefined) {
          parseDataForCampaign(response.data.response, tempCampData);
        } else {
          parseDataForCampaign(response.data.response);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const parseDataForCampaign = (response, tempCampData) => {
    const tempResponseProducts = [...response]; // all consumer / management products.

    tempResponseProducts.forEach((item) => {
      // loop through each product and parse
      typeof item.images === 'string' && (item.images = JSON.parse(item.images));
      typeof item.pricingOptions === 'string' && (item.pricingOptions = JSON.parse(item.pricingOptions));
    });


    // get the current product filtered by URL.
    const productsFromCurrentCampaign = [...tempResponseProducts]?.filter(products => products?.campaignUrl === values.campUrl && products);


    const tempProducts = [];

    productsFromCurrentCampaign?.length > 0 && (productsFromCurrentCampaign?.forEach((product) => {
      tempProducts.push(product);
    }));

    values.campProducts?.length > 0 && (values.campProducts?.forEach((product) => {
      tempProducts.push(product);
    }))

    // this keeps data on the config when updating a campaign, unsure if the iternary check is needed, will need to test
    tempCampData === undefined ? setFieldValue('campProducts', productsFromCurrentCampaign.sort((a, b) => a.prodOrder - b.prodOrder))
      : setFieldValue('campProducts', productsFromCurrentCampaign.sort((a, b) => a.prodOrder - b.prodOrder))

    // // remove dups based on descriptionTitle
    const filteredPreviousProducts = [...new Map(tempResponseProducts.map((item) => [item['descriptionTitle'], item])).values(),];

    setPreviousProducts(filteredPreviousProducts);
  };

  const getProductsFromPreviousCampaigns = async (tempCampData) => {
    if (values?.campType === 'Consumer') {
      tempCampData ? getConsumerProducts(tempCampData) : getConsumerProducts();
      setGatheredPreviousProducts(true);
    } else if (values?.campType === 'Management') {
      tempCampData ? await getManagementProducts(tempCampData) : await getManagementProducts();
      setGatheredPreviousProducts(true);
    } else {
      setGatheredPreviousProducts(false);
    }

    setFetching(false);
    // setGatheredPreviousProducts(false);
  };

  const handleQuestionModalOpen = () => {
    setOpenQuestionModal(true);
  };
  const handleQuestionModalClose = () => {
    setOpenQuestionModal(false);
  };

  const getUpdates = async () => {
    setFetching(true);
    try {
      await getCampaignStatus().then(res => setStatuses(res))
        .then(async () => await getCampaignBrands().then((res) => setCampaignBrands(res)))
        .then(async () => await getCampaignQuestions())
        .then(async () => await getCampaignQuestionTypes())
        .then(async () => {
          await getCampaigns();
          handleCurrentStatus(values);
          handleCurrentStatusLabel(values);
        });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error when fetching updates, Try again later.', { variant: 'error' })
    }
  };

  const managePriceAndProductOptionsOnUpdate = () => {
    const tempPriceRankingOptionsLength =
      values?.campPriceRankingOptions?.length;
    if (
      (values.typePriceOption === 0 ||
        values.typePriceOption === undefined) &&
      tempPriceRankingOptionsLength > 1
    ) {
      setFieldValue('typePriceOption', tempPriceRankingOptionsLength);
    }

    if (
      (values.typeProductOption === 0 ||
        values.typeProductOption === undefined) &&
      values?.campProductRankingOption?.length > 0
    ) {
      setFieldValue('typeProductOption', values?.campProductRankingOption?.length);
    }

    if (gatheredPreviousProducts === false) {
      getProductsFromPreviousCampaigns();
    }

    if (gatheredPreviousProducts === true && previousProducts.length === 0) {
      setGatheredPreviousProducts(false);
    }
  }

  const handleCampaignDataForPost = async (tempCampaign) => {

    // copy of the products in the camp data
    const tempProducts = [...tempCampaign.campProducts];

    // loop through to add a new image
    for (let item of tempProducts) {
      for (const image of item.images) {
        if (image.image) {
          await convertImage(image.image).then((result) => {
            image.image = result;
          });
        }
      }
    }

    const manageProducts = [...tempProducts].map((item, index) => {
      if (isNaN(item.productID)) { // if guid is a uuid string for product then remove from object...
        const { productID, ...newItem } = item;
        item = { ...newItem };
      }

      item.prodOrder = index; // used to remember current product order when re-loading campaign.
      return item;

    });

    tempCampaign.campProducts = manageProducts;

    tempCampaign.questions = [...selectedQuestions];

    const { typePriceOption, typeProductOption, ...newTempCampaign } =
      tempCampaign; // destructure items not needed.

    return { ...newTempCampaign };
  };

  const manageSaveCampaign = async () => {
    setNewCampaignId(0);
    setFetching(true);

    const tempCampaign = { ...values }; // fix for saving products (stops others from changing)
    const arr = [];
    let tempCampaignListData = campDataList?.length > 0 ? [...campDataList] : [];

    tempCampaign.campaignId === '' && (tempCampaign.campaignId = uuidv4()) // provide local id

    // if the id already exists for a campaign, then update the campaign locally
    tempCampaignListData.length === 0 && tempCampaignListData.push(tempCampaign); // push if no campaign already there

    if (tempCampaignListData.length > 0) {
      // update campaign if exists and if there are changes
      tempCampaignListData = [...campDataList].map((campaign) => campaign.campaignId === tempCampaign.campaignId ? (campaign = tempCampaign) : campaign);

      // if the id does not exist within campaign list, then add it. (updating locally so the list is kept up to date)
      const campaignDoesntExist = !tempCampaignListData.some(item => item.campaignId === tempCampaign.campaignId)

      campaignDoesntExist && (tempCampaignListData.push(tempCampaign));
    }

    setCampDataList([...tempCampaignListData]);

    const campaign = await handleCampaignDataForPost(tempCampaign); // campaign after handling post data, returns a shallow copy.

    campaign.startDateTime = moment.utc(moment(campaign.startDateTime)).format();

    campaign.endDateTime = moment.utc(moment(campaign.endDateTime)).format();

    campaign.campType === 'Management' && campaign.campMaxTokensMethod === 'Calculated' && (campaign.campTokens = campaign?.campProducts?.length * campaign.campTokensPerProduct);

    if (values.campType === 'Management') {
      for (let i = 0; i < values.typePriceOption; i++) {
        if (values.campPriceRankingOptions[i] === '' || values.campPriceRankingOptions[i] === undefined) {
          arr.push('');
        } else {
          arr.push(values.campPriceRankingOptions[i]);
        }
      }
      campaign.campPriceRankingOptions = _.cloneDeep(arr);
    }

    if (values.campType === 'Consumer') {
      for (let i = 0; i < values.typePriceOption; i++) {
        arr.push('');
      }

      campaign.campPriceRankingOptions = _.cloneDeep(arr);
      arr.length = 0;

      for (let i = 0; i < values.typeProductOption; i++) {
        if (
          values.campProductRankingOption[i] === '' || values.campProductRankingOption[i] === undefined) {
          arr.push('');
        } else {
          arr.push(values.campProductRankingOption[i]);
        }
      }
      campaign.campProductRankingOption = _.cloneDeep(arr);
    }

    campaign.campEndingPageEnabled = Boolean(campaign.campEndingPageEnabled);

    if (
      campaign.campProductFeatureQuestion === '' ||
      values.campProductFeatureQuestion === undefined ||
      values.campProductFeatureQuestion === null
    ) {
      campaign.campProductFeatureQuestion =
        'What do you think this product is worth?';
    }

    const { campaignId } = campaign;
    const allCampaigns = [...campDataList];
    const campaignExists = allCampaigns.some((camp) => camp.campaignId === campaignId)

    campaignExists
      ? await updateCampaign(campaign)
        .then(async () => {
          setDisableSaveCampaign(true);
          await getUpdates();
        })
        .then(() => {
          setDisableSaveCampaign(false);
          setIsFormDirty(false);
          setDuplicateCampaignSettings({ ...duplicateCampaignSettings, selectedCampaign: true, duplicated: false });
        })
      : await createCampaign(campaign)
        .then(async () => {
          setDisableSaveCampaign(true);
          await getUpdates();
        })
        .then(() => {
          setDisableSaveCampaign(false);
          setIsFormDirty(false);
          setDuplicateCampaignSettings({ ...duplicateCampaignSettings, selectedCampaign: true, duplicated: false });
          // copy current campaign
          if (isANewCampaign) {
            setIsANewCampaign(false); // because current campaign once saved will not be a new campaign anymore.
          }
        });
  };

  const saveCampaign = async () => await manageSaveCampaign();

  const resetCampaign = () => {
    setLocalQuestions([]);
    setCurrentCampDataStatus(0);
    setGatheredPreviousProducts(false); // will refetch the once when required.
    getCampaignQuestions();
    setSelectedQuestions([]);
    setCampId('');
    setValues({ ...campDataBase });
  };

  const handleIncomingSelectedQuestions = (questions) => {
    const tempQuestions = questions.length > 0 ? [...questions] : [];
    setSelectedQuestions([...tempQuestions]);
  };

  const checkForDuplicateCampaignUrl = (currentValue) => {
    let campaignUrlExists;

    if (values.campUrl.length === 0) {
      return false;
    }

    if (campDataList.length === 0) {
      return false;
    }

    isANewCampaign
      && (campaignUrlExists = currentValue?.length > 0 ? campaignNameList.some(campD => campD.campUrl === currentValue)
        : campaignNameList.some((campD) => campD.campUrl === values.campUrl))

    if (!isANewCampaign) {
      // remove current campaign based on url so it can be changed/adapted freely.
      const filterCurrentCampaign = campaignNameList.filter((currentCamp) => currentCamp.campaignId !== values.campaignId && currentCamp.campUrl !== values.campUrl
      ); // may break if url ever needs changing in the future (because of formik) - note for the future...

      campaignUrlExists = currentValue?.length > 0 ? filterCurrentCampaign.some((campD) => campD.campUrl === currentValue)
        : filterCurrentCampaign.some((campD) => campD.campUrl === values.campUrl);
    }

    return campaignUrlExists;
  };

  const checkForDuplicateCampaignName = (currentValue) => {
    let duplicateFound;

    if (values.campName.length === 0 && !fetching) {
      return false;
    }

    if (campDataList?.length === 0 && !fetching) {
      return false;
    }

    if (fetching) {
      return false;
    }

    if (isANewCampaign) {
      if (currentValue?.length > 0) {
        duplicateFound = campaignNameList.some(campD => campD.campName === currentValue)
      } else {
        duplicateFound = campaignNameList.some(campD => campD.campName === values.campName)
      }
    }


    if (!isANewCampaign) {
      if (newCampaignId > 0 && currentValue?.length > 0) {
        duplicateFound = [...campaignNameList].filter(currentCamp => currentCamp.campaignId !== newCampaignId).some(campD => campD.campName === currentValue);
      } else if (newCampaignId === 0 && currentValue?.length > 0) {
        duplicateFound = [...campaignNameList].filter(currentCamp => currentCamp.campaignId !== values.campaignId).some(campD => campD.campName === currentValue);
      }
      else {
        duplicateFound = campaignNameList.filter(currentCamp => currentCamp.campaignId !== values.campaignId).some(campD => campD.campName === values.campName);
      }
    }

    return duplicateFound;
  };

  const handleDeleteModalOpen = () => setOpenDeleteModal(true);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);


  const handleCurrentStatus = ({ campStatus, startDateTime, endDateTime }) => {
    if (campStatus === 1 || campStatus === 3) {
      setCurrentCampDataStatus(campStatus);
    } else if (
      campStatus === 4 &&
      new Date() >= new Date(startDateTime) &&
      new Date() < new Date(endDateTime)
    ) {
      setCurrentCampDataStatus(3);
    } else if (
      campStatus === 4 &&
      new Date() > new Date(endDateTime)
    ) {
      setCurrentCampDataStatus(1);
    } else {
      setCurrentCampDataStatus(2);
    }
  };

  const handleCurrentStatusLabel = ({ startDateTime, endDateTime, campStatus }) => {
    if (
      (campStatus === 4 &&
        new Date() >= new Date(startDateTime) &&
        new Date() < new Date(endDateTime)) ||
      campStatus === 3
    ) {
      return 'Active';
    } else if (
      (campStatus === 4 && new Date() > new Date(endDateTime)) ||
      campStatus === 1
    ) {
      return 'Ended';
    } else {
      return 'Pending';
    }
  };

  useEffect(() => {
    getUpdates();
  }, []);

  useEffect(() => {
    managePriceAndProductOptionsOnUpdate();
  }, [values]);

  useEffect(() => {
    getProductsFromPreviousCampaigns();
  }, [values.campType, values.campBrand]);

  useEffect(() => {
    if (values.campPriceRankingOptions.length > 0) {
      return;
    }

    const arr = [];
    for (let i = 0; i < values.typePriceOption; i++) {
      arr.push('');
    }
    setFieldValue('campPriceRankingOptions', arr);
  }, [values.typePriceOption]);

  useEffect(() => {

    if (values.campProductRankingOption.length > 0) {
      return;
    }

    const arr = [];

    for (let i = 0; i < values.typeProductOption; i++) {
      arr.push('');
    }
    setFieldValue('campProductRankingOption', arr);

  }, [values.typeProductOption]);

  const useStyles = makeStyles((theme) => ({
    help: {
      position: 'fixed',
      bottom: theme.spacing(12),
      right: theme.spacing(2),
      zIndex: 10
    },
  }));

  const classes = useStyles();

  return (
    <Container maxWidth='xl' sx={{ mt: 4 }}>

      <Box className={classes.help}>
        <a href={Pdf} rel='noopener noreferrer' target='_blank'>
          <Fab color='primary' aria-label='add' style={{ padding: 1 }}>
            <HelpIcon style={{ fontSize: '35px' }} />
          </Fab>
        </a>
      </Box>

      <CampConfigForm
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        formik={formik}

        currentCampDataStatus={currentCampDataStatus}
        statuses={statuses}
        previousProducts={previousProducts}
        campDataList={campDataList}
        setCampDataList={setCampDataList}
        campaignNameList={campaignNameList}
        setCampaignNameList={setCampaignNameList}

        isFormDirty={isFormDirty}
        setIsFormDirty={setIsFormDirty}
        resetCampaign={resetCampaign}
        setCurrentCampDataStatus={setCurrentCampDataStatus}
        handleCurrentStatus={handleCurrentStatus}
        handleCurrentStatusLabel={handleCurrentStatusLabel}
        isANewCampaign={isANewCampaign}
        setIsANewCampaign={setIsANewCampaign}
        campaignBrands={campaignBrands}
        enqueueSnackbar={enqueueSnackbar}

        localQuestions={localQuestions}
        campaignQuestionTypes={campaignQuestionTypes}
        selectedQuestions={selectedQuestions}
        setSelectedQuestions={setSelectedQuestions}
        handleIncomingSelectedQuestions={handleIncomingSelectedQuestions}
        handleQuestionModalOpen={handleQuestionModalOpen}

        handleDeleteModalOpen={handleDeleteModalOpen}

        saveCampaign={saveCampaign}
        disableSaveCampaign={disableSaveCampaign}
        setDisableSaveCampaign={setDisableSaveCampaign}

        duplicateCampaignSettings={duplicateCampaignSettings}
        setDuplicateCampaignSettings={setDuplicateCampaignSettings}

        originalCampStatus={originalCampStatus}
        setOriginalCampStatus={setOriginalCampStatus}
        originalCampEndDate={originalCampEndDate}
        setOriginalCampEndDate={setOriginalCampEndDate}
        originalCampStartDate={originalCampStartDate}
        setOriginalCampStartDate={setOriginalCampStartDate}
        originalSurveyOnly={originalSurveyOnly}
        setOriginalSurveyOnly={setOriginalSurveyOnly}
        newCampaignId={newCampaignId}
        setNewCampaignId={setNewCampaignId}

        campId={campId}
        setCampId={setCampId}
      />

      <ManageQuestions
        open={openQuestionModal}
        handleClose={handleQuestionModalClose}
        campData={values}
        localQuestions={localQuestions}
        fetchAllQuestions={getCampaignQuestions}
        setFormikFields={setFieldValue}
        enqueueSnackbar={enqueueSnackbar}
      />

      <DeleteCampaign
        openModal={openDeleteModal}
        handleClose={handleDeleteModalClose}
        campData={values}
        resetCampaign={resetCampaign}
        getCampaigns={getCampaigns}
        enqueueSnackbar={enqueueSnackbar}
      />

    </Container>
  );
};
export default CampConfig;
