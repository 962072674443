import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export const RenderSingleQuestion = ({
  question,
  setQuestions,
  allQuestions,
  setQuestionResults,
  questionResults,
  questionIndex,
  editable,
  resultsObj
}) => {

  const [value, setValue] = useState('');

  useEffect(() => {
    if (editable) {
      if (resultsObj.questionResults?.length > 0) {
        const match = resultsObj.questionResults.filter((item) => item.id === question.id)[0];
        setValue(match.answer);
      }
      setQuestionResults([...resultsObj.questionResults]);
    }
  }, [])

  const handleAnswer = (currentValue) => {
    // once added, push selectedAnswer to a form array within the parent component. Then on save in the parent, it will be pushed to the API.

    // get all questions
    const questionResultsTemp =
      questionResults.length > 0 ? [...questionResults] : [];
    if (questionResultsTemp.length > 0) {
      // const resultsIndex = questionResultsTemp.indexOf((qu) => {return qu.id === question.id});
      const filterQuestionResults = questionResultsTemp.filter(
        (qu) => qu.id === question.id
      );
      if (filterQuestionResults.length > 0) {
        filterQuestionResults[0].answer = currentValue;
        questionResultsTemp.forEach((item) => {
          if (item.id === filterQuestionResults[0].id) {
            item = { ...filterQuestionResults[0] };
            item.type = question.type;
            item.question = question.value;
          }
        });
      } else {
        const selectedAnswerBase = { questionNumber: 0, answer: '' };
        selectedAnswerBase.id = question.id;
        selectedAnswerBase.type = question.type;
        selectedAnswerBase.questionNumber = questionIndex;
        selectedAnswerBase.question = question.value;
        selectedAnswerBase.answer = currentValue;
        selectedAnswerBase.answers = [];
        questionResultsTemp.push(selectedAnswerBase);
      }
    } else {
      const selectedAnswerBase = { questionNumber: 0, answer: '' };
      selectedAnswerBase.id = question.id;
      selectedAnswerBase.type = question.type;
      selectedAnswerBase.questionNumber = questionIndex;
      selectedAnswerBase.question = question.value;
      selectedAnswerBase.answer = currentValue;
      selectedAnswerBase.answers = [];
      questionResultsTemp.push(selectedAnswerBase);
    }
    setQuestionResults([...questionResultsTemp]); // currently assigns data to the question results in parent.
  };

  useEffect(() => {
    handleAnswer(value);
  }, [value]);

  return (
    <TextField
      value={value}
      error={value.length === 0}
      helperText={value.length === 0 && question.type === 1 ? 'Please enter an answer' : ' '}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
