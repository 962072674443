import {
  Box,
  Typography,
  Modal,
  Button,
  Input,
  TextField,
  Dialog,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ExpandedImage = ({ handleClose, openModal, picture }) => {
  return (
    <Dialog open={openModal} onClose={handleClose} maxWidth='md'>
      <Box display='flex' flexDirection='column' p={2}>
        <Box maxWidth='500px' mb={1}>
          <img src={picture} alt='' style={{ width: '100%' }} />
        </Box>
        <Button variant='outlined' onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Dialog>
    // <Modal open={openModal} onClose={handleClose}>
    //   <Box sx={style}>
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       gap="1em"
    //       alignItems="center"
    //     >
    //       <Typography>Picture</Typography>
    //       <Box display="flex">
    //         <Box display="flex">
    //           <img src={picture} width="80%" alt="" />
    //         </Box>
    //         <Box>
    //           <Button onClick={handleClose} variant="contained" color="primary">
    //             Close
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   </Box>
    // </Modal>
  );
};
