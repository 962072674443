import React from 'react';
import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import axios from 'axios';
import { params } from '../../helpers';

const { REACT_APP_API_BASE_URI } = process.env;

export const DeleteQuestion = ({
  handleClose,
  openModal,
  fetchAllQuestions,
  resetQuestionInput,
  question,
  enqueueSnackbar,
}) => {

  const deleteQuestionById = async (id) => {
    try {
      const response = await axios
        .delete(`${REACT_APP_API_BASE_URI}deleteQuestion/${id}`, params)
        .then(() => {
          fetchAllQuestions();
          resetQuestionInput();
          enqueueSnackbar('Question deleted.', { variant: 'success' })
          handleClose();
          console.log('delete successful');
        });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error when deleting question.', { variant: 'success' })
    }
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>
          Are you sure you want to delete this question?
        </DialogTitle>
        <Box display='flex' justifyContent='space-between' p={2}>
          <Button
            onClick={() => deleteQuestionById(question.id)}
            variant='contained'
            color='error'
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant='outlined' color='primary'>
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
