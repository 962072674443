import { makeStyles } from '@mui/styles';

export const AddProductStyles = makeStyles((theme) => ({
  editLargePicture: {
    width: '175px',
    height: '175px',
    '@media (max-width: 512px)': {
      width: '175px',
      height: '175px',
    },
  },
  picture: {
    width: '125px',
    height: '125px',
    '@media (max-width: 512px)': {
      width: '125px',
      height: '125px',
    },
  },
  regroupContainer: {
    maxHeight: '70vh',
    minWidth: '350px',
    padding: '1em 2em 0',
  },
}));
