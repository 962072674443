import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Rating,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableHeaderCell } from '../../styles/miscStyles';

const { REACT_APP_CLOUD_BUCKET_URL } = process.env;

const StyledRatingStars = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: 'rgb(141, 209, 77)',
  },
});

const ProductsTable = ({
  resultsObj,
  tokenLimit,
  goToEditProduct,
  inReporting,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableHeaderCell sx={{ minWidth: '150px' }}>Name</TableHeaderCell>
            <TableHeaderCell>Price</TableHeaderCell>
            <TableHeaderCell sx={{ minWidth: '150px' }}>
              Price Rating
            </TableHeaderCell>
            <TableHeaderCell>Tokens</TableHeaderCell>
            <TableHeaderCell sx={{ minWidth: 300 }}>Comment</TableHeaderCell>
            {!inReporting && <TableHeaderCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {resultsObj.products.map((prod) => (
            <TableRow key={prod.productID}>
              <TableCell sx={{ width: '80px' }}>
                <img
                  src={`${REACT_APP_CLOUD_BUCKET_URL}${prod.image}`}
                  alt=''
                  style={{ maxWidth: '100%' }}
                />
              </TableCell>
              <TableCell>{prod.descriptionTitle}</TableCell>
              <TableCell>{prod.pricingText}</TableCell>
              <TableCell>{prod.chosenOption}</TableCell>
              <TableCell>
                <StyledRatingStars
                  max={tokenLimit}
                  value={prod.tokenAnswer}
                  readOnly
                />
              </TableCell>
              <TableCell>{prod.comment}</TableCell>
              {!inReporting && (
                <TableCell>
                  <Button
                    onClick={() => {
                      goToEditProduct(prod);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
