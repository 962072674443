import { Button, Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import CampEnding from '../components/CampaignConfig/campEnding';
import ManagementSummary from './ManagementSummary';
import CampViewProduct from '../components/CampaignJourney/CampViewProduct';

const CampView = ({
  campData,
  resultsObj,
  setResultsObj,
  questionResults,
  isPreview,
  postOperationalResult,
  maxReached,
  campEndingOpen,
  setCampEndingOpen,
  productPage,
  setProductPage,
  editable,
  setEditable,
  backToSummary,
  enqueueSnackbar
}) => {
  const [prods, setProds] = useState();
  const [currentProduct, setCurrentProduct] = useState({});
  const [productCounter, setProductCounter] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(campData.tokens);
  // const [campEndingOpen, setCampEndingOpen] = useState(false);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [summaryVisited, setSummaryVisited] = useState(false);
  const [optionError, setOptionError] = useState(false);
  const [rankingError, setRankingError] = useState(false);

  useEffect(() => {
    if (campData.type === 'Management') {
      setResultsObj({
        ...resultsObj,
        questionResults: [...questionResults.questionResults],
        branchCode: questionResults.branchCode,
      });
    } else {
      setResultsObj({
        ...resultsObj,
        questionResults: [...questionResults.questionResults],
      });
    }
  }, [questionResults]);

  useEffect(() => {
    if (campData.type === 'Management') {
      const totalTokensUsed = resultsObj.products.reduce(
        (ac, cu) => ac + cu.tokenAnswer,
        0
      );
      setRemainingTokens(campData.tokens - totalTokensUsed);
    }
  }, [resultsObj, campData]);

  useEffect(() => {
    if (prods) {
      setCurrentProduct(prods[0]);
    }
  }, [prods]);

  useEffect(() => {
    let localProducts = campData.products ? [...campData.products] : [];

    if (localProducts?.length > 0) {
      localProducts?.forEach((item) => {
        if (typeof item.images === 'string') {
          item.images = JSON.parse(item.images);
        }
        item.comment = '';

        if (campData.type === 'Consumer') {
          if (typeof item.pricingOptions === 'string') {
            item.pricingOptions = JSON.parse(item.pricingOptions);
          }
        }
      });
    }

    if (typeof campData.productRankingOption === 'string') {
      campData.productRankingOption = JSON.parse(campData.productRankingOption);
    }

    setProds([...localProducts]);
    // setCurrentProduct(prods[0]);
    // setCloudCampaignUrl(campData.url);
  }, [campData]);

  // function handed to ManagementSummary component to allow user to go back and edit product details
  const goToEditProduct = (prod) => {
    const prodToEdit = prods.findIndex((p) => p.productID === prod.productID);
    setCurrentProduct(prods[prodToEdit]);
    setProductCounter(prodToEdit);
    setSummaryOpen(false);
  };

  const submitResults = async () => {
    const tempResultsObj = { ...resultsObj, questionResults: resultsObj.questionResults.map(({ type, ...res }) => res) };
    const updateResultWithTime = {
      ...tempResultsObj,
      completedAtDateTime: new Date(),

    };
    await postOperationalResult(updateResultWithTime);
  };

  // check to see if the user has filled in the required fields
  const validateNextStep = () => {
    const currentProductResults = resultsObj.products.filter(
      (p) => p.productID === currentProduct.productID
    )[0];
    let goToNextStep = true;

    if (!isPreview) {
      if (campData.type === 'Consumer') {
        if (!currentProductResults.productRank) {
          setRankingError(true);
          goToNextStep = false;
        }
        if (!currentProductResults.chosenOption) {
          setOptionError(true);
          goToNextStep = false;
        }
        return goToNextStep;
      }

      if (!currentProductResults.chosenOption) {
        setOptionError(true);
        goToNextStep = false;
      }
      return goToNextStep;
    } else return true;
  };

  const handleNextClick = () => {
    if (validateNextStep()) {
      setCurrentProduct(prods[productCounter + 1]);
      setProductCounter((prev) => prev + 1);
    }
  };

  const handleReviewSurveyClick = () => {
    if (validateNextStep()) {
      setSummaryVisited(true);
      setSummaryOpen(true);
    }
  };

  const handleCompleteSurveyClick = () => {
    if (validateNextStep()) {
      if (!isPreview) {
        submitResults();
      }
      campData.endingPageEnabled
        ? setCampEndingOpen(true)
        : (window.location.href = campData.redirect)
    }
  };

  if (!campData.products.length) {
    return (
      <Box mt={4} display='flex' justifyContent='center'>
        <Typography variant='h5' sx={{ color: 'error.main' }}>
          No products to display
        </Typography>
      </Box>
    );
  }

  return campEndingOpen ? (
    <CampEnding
      handlePageOpen={setCampEndingOpen}
      campData={campData}
      maxReached={maxReached}
    />
  ) : (
    <>
      <ManagementSummary
        resultsObj={resultsObj}
        summaryOpen={summaryOpen}
        setSummaryOpen={setSummaryOpen}
        questions={campData.questions}
        tokenLimit={campData.tokensPerProduct}
        redirect={campData.redirect}
        goToEditProduct={goToEditProduct}
        submitResults={submitResults}
        isPreview={isPreview}
        productPage={productPage}
        setProductPage={setProductPage}
        editable={editable}
        setEditable={setEditable}
      />
      <Box my={2}>
        {isPreview && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              width: '100%',
              borderRadius: 1,
              height: '50px',
              backgroundColor: '#FF4D4D',
              margin: '1em 0',
            }}
          >
            <Typography variant='h5'>You Are In Preview Mode</Typography>
          </Box>
        )}
        <CampViewProduct
          currentProduct={currentProduct}
          campData={campData}
          resultsObj={resultsObj}
          setResultsObj={setResultsObj}
          remainingTokens={remainingTokens}
          optionError={optionError}
          setOptionError={setOptionError}
          rankingError={rankingError}
          setRankingError={setRankingError}
          enqueueSnackbar={enqueueSnackbar}
        />

        <Grid
          container
          maxWidth='xl'
          spacing={2}
          alignItems='center'
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} md={6}>
            <Box display='flex' justifyContent='space-between'>
              <Typography>
                Progress: {productCounter + 1} of {prods?.length}
              </Typography>
              {campData.type === 'Management' && (
                <Typography>Tokens Remaining: {remainingTokens}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                disabled={productCounter === 0}
                onClick={() => {
                  setOptionError(false);
                  setCurrentProduct(prods[productCounter - 1]);
                  setProductCounter((prev) => prev - 1);
                }}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={6}>
              {productCounter === prods?.length - 1 &&
                campData.type === 'Management' ? (
                <Button
                  variant='contained'
                  fullWidth
                  onClick={handleReviewSurveyClick}
                // onClick={() => {
                //   setSummaryVisited(true);
                //   setSummaryOpen(true);
                // }}
                >
                  Review Survey
                </Button>
              ) : productCounter === prods?.length - 1 &&
                campData.type === 'Consumer' ? (
                <Button
                  variant='contained'
                  fullWidth
                  onClick={handleCompleteSurveyClick}
                // onClick={() => {
                //   // need a check to see if this is a preview campaign. If it is not a preview campaign, then submit results.... else proceed without submitting
                //   if (!isPreview) {
                //     submitResults();
                //   }
                //   // submitResults();
                //   campData.endingPageEnabled
                //     ? setCampEndingOpen(true)
                //     : (window.location.href = campData.redirect);
                // }}
                >
                  Complete Survey
                </Button>
              ) : (
                <Button
                  variant='contained'
                  fullWidth
                  disabled={productCounter === prods?.length - 1}
                  // onClick={() => {
                  //   setCurrentProduct(prods[productCounter + 1]);
                  //   setProductCounter((prev) => prev + 1);
                  // }}
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {(summaryVisited || backToSummary) && (
          <Box mt={4}>
            <Button
              fullWidth
              variant='contained'
              onClick={() => {
                setSummaryOpen(true);
              }}
            >
              Back to Campaign Summary
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CampView;
