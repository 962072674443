import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material"

export const CampConfigFormStatusBannerAndName = ({
  isANewCampaign,
  currentCampDataStatus,
  values,
  handleChange,
  setIsFormDirty,
  touched,
  errors,
  newCampaignRef,
  formik,
  setFieldValue,
  duplicateCampaignSettings }) => {
  return (
    <>
      {isANewCampaign === false ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={
            currentCampDataStatus === 3
              ? {
                width: '100%',
                borderRadius: 1,
                height: '50px',
                backgroundColor: '#4BD28F',
                margin: '1em 0',
              }
              : currentCampDataStatus === 2
                ? {
                  width: '100%',
                  height: '50px',
                  backgroundColor: '#F2C800',
                  margin: '1em 0',
                }
                : currentCampDataStatus === 1
                  ? {
                    width: '100%',
                    height: '50px',
                    backgroundColor: '#FF4D4D',
                    margin: '1em 0',
                  }
                  : {}
          }
        >
          <Typography variant='h5'>
            {currentCampDataStatus === 3
              ? 'Campaign is active'
              : currentCampDataStatus === 2
                ? 'Campaign is pending'
                : currentCampDataStatus === 1
                  ? 'Campaign has ended'
                  : ''}
          </Typography>
        </Box>
      ) : (
        ''
      )}

      <TextField
        fullWidth
        value={formik.values.campName}
        onChange={(e) => { handleChange('campName')(e); setIsFormDirty(true); }}
        error={(formik.touched.campName && Boolean(formik.errors.campName)) || duplicateCampaignSettings.warningOptions.toggleText}
        helperText={(formik.touched.campName && formik.errors.campName) || (duplicateCampaignSettings.warningOptions.toggleText && duplicateCampaignSettings.warningOptions.warningDuplicateNameText)}
        label='Campaign Name'
        variant='outlined'
        inputRef={newCampaignRef}
        // sx={{ width: FORMFIELDWIDTH }}
        disabled={currentCampDataStatus === 3}
      />

      {isANewCampaign && (
        <Box mt={2} display='flex'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={values.campSurveyOnly}
                  checked={Boolean(values.campSurveyOnly)}
                  onChange={() => setFieldValue('campSurveyOnly', !values.campSurveyOnly)}
                />
              }
              label={<Typography>Questionnaire Only?:</Typography>}
              labelPlacement='start'
              sx={{ marginLeft: 0 }}
            />
          </FormGroup>
        </Box>
      )}
    </>
  )
}