/* General Imports */

import { Link } from 'react-router-dom';

/* MUI Imports */

import { AppBar, Box, Button, Container, Toolbar } from '@mui/material';

/* App Imports */

import jonesLogo from '../assets/Jones_Header_Logo.png';
import paversLogo from '../assets/Pavers_Header_Logo.png';

const ActiveCampaignHeader = ({ brand, isPreview }) => {
  return (
    <AppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            py={1}
            sx={{
              '@media (max-width: 800px)': {
                height: '50px',
              },
            }}
          >
            <img src={brand === 'Pavers' ? paversLogo : jonesLogo} alt='' />
          </Box>
          {isPreview && (
            <Box sx={{ position: 'absolute' }}>
              <Link to={'/build_campaign'} style={{ textDecoration: 'none' }}>
                <Button
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                  }}
                >
                  Back to Config
                </Button>
              </Link>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ActiveCampaignHeader;
