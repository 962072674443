import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import logoPavers from '../../../src/assets/perfectstyle.png';
import logoJones from '../../../src/assets/Jones_Black.jpg';
import CampInactive from '../../pages/CampInactive';
import { EmailLine } from '../globals/EmailLine';

const { REACT_APP_CLOUD_BUCKET_URL, REACT_APP_SUPPORT_EMAIL } = process.env;

const CampEnding = ({ campData, maxReached, isPreview }) => {
  const [failed, setFailed] = useState(false);
  const imageError = () => {
    setFailed(true);
  };

  const { type, endingPageEnabled, endingPageText, redirect, brand, url } = campData;

  return maxReached ? (
    <CampInactive brand={brand} redirect={redirect} />
  ) : (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {/* <h1> */}
      {/* <span style={{ display: 'block', whiteSpace: 'pre-line', tex }}> */}
      <Typography variant='h3' align='center' sx={{ display: 'block', whiteSpace: 'pre-line', my: 2 }}>{endingPageText}</Typography>
      {/* </span> */}
      {/* </h1> */}

      <Button
        variant='contained'
        onClick={() => window.location.href = redirect}
      >
        Shop Now
      </Button>
      {!failed ? (
        <img
          onError={imageError}
          src={`${REACT_APP_CLOUD_BUCKET_URL}${url}/EndingPageImage`}
          style={{ paddingTop: '20px', minWidth: '300px', maxWidth: '300px' }}
          alt='logo'
        />
      ) : (
        ''
      )}
      {brand === 1 ? (
        <img style={{ marginTop: '20px' }} src={logoPavers} alt='Pavers Logo' />
      ) : (
        <img style={{ marginTop: '20px' }} src={logoJones} alt='Jones Logo' />
      )}
      {type === 'Consumer' && endingPageEnabled === 1 && <EmailLine email={REACT_APP_SUPPORT_EMAIL} />}
    </Box>
  );
};
export default CampEnding;
