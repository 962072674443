/* MUI IMPORTS */

import { Box, Button, Container, Dialog, Typography } from '@mui/material';

/* APP IMPORTS */

import ProductsTable from '../ManagementSummary/ProductsTable';
import QuestionsTable from '../ManagementSummary/QuestionsTable';

const SingleBranchView = ({
  open,
  handleClose,
  campData,
  questions,
  resultsObj,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <Container maxWidth='lg'>
        <Box my={4}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='h4'>{resultsObj?.branchCode}</Typography>
          </Box>
          {resultsObj && (
            <>
              <Box mt={2}>
                <Typography variant='h6' sx={{ my: 2 }}>
                  Questionnaire Summary
                </Typography>
                <QuestionsTable
                  questions={questions}
                  answers={resultsObj?.questionResults}
                />
              </Box>

              <Box mt={2}>
                <Typography variant='h6' sx={{ my: 2 }}>
                  Product Rating Summary
                </Typography>
                <ProductsTable
                  resultsObj={resultsObj}
                  tokenLimit={campData.campTokensPerProduct}
                  inReporting={true}
                />
              </Box>
            </>
          )}

          <Box display='flex' gap={2} mt={2}>
            <Button fullWidth variant='outlined' onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default SingleBranchView;
