import { FormControlLabel, Radio, RadioGroup, Slider } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';

export const RenderSliderQuestion = ({
  question,
  setQuestions,
  allQuestions,
  setQuestionResults,
  questionResults,
  questionIndex,
  editable,
  resultsObj
}) => {

  const [sliderValues, setSliderValues] = useState([
    {
      value: 0,
      label: '0',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 100,
      label: '100',
    },
  ]);

  const currentAriaValue = (value) => `${value}`;

  const [currentSliderValue, setCurrentSliderValue] = useState(5);

  useEffect(() => {
    if (editable) {
      const manageQuestion = resultsObj.questionResults.filter((sliderQ) => sliderQ.id === question.id)[0];
      setCurrentSliderValue(parseInt(manageQuestion.answer))
      setQuestionResults([...resultsObj.questionResults]);
    }
  }, [])

  const handleAnswer = (currentValue, index) => {
    // once added, push selectedAnswer to a form array within the parent component. Then on save in the parent, it will be pushed to the API.

    const questionResultsTemp =
      questionResults.length > 0 ? [...questionResults] : [];
    if (questionResultsTemp.length > 0) {
      // const resultsIndex = questionResultsTemp.indexOf((qu) => {return qu.id === question.id});
      const filterQuestionResults = questionResultsTemp.filter(
        (qu) => qu.id === question.id
      );
      if (filterQuestionResults.length > 0) {
        filterQuestionResults[0].answer = currentValue.toString();
        questionResultsTemp.forEach((item) => {
          if (item.id === filterQuestionResults[0].id) {
            item = { ...filterQuestionResults[0] };
            item.type = question.type;
          }
        });
      } else {
        const selectedAnswerBase = { questionNumber: 0, answer: '' };
        selectedAnswerBase.id = question.id;
        selectedAnswerBase.type = question.type;
        selectedAnswerBase.questionNumber = questionIndex;
        selectedAnswerBase.answer = currentValue.toString();
        questionResultsTemp.push(selectedAnswerBase);
      }
    } else {
      const selectedAnswerBase = { questionNumber: 0, answer: '' };
      selectedAnswerBase.id = question.id;
      selectedAnswerBase.type = question.type;
      selectedAnswerBase.questionNumber = questionIndex;
      selectedAnswerBase.answer = currentValue.toString();
      questionResultsTemp.push(selectedAnswerBase);
    }
    setQuestionResults([...questionResultsTemp]); // currently assigns data to the question results in parent.
  };

  useEffect(() => {
    handleAnswer(currentSliderValue);
  }, [currentSliderValue]);

  return (
    <Box sx={{ width: '60%' }}>
      <Slider
        aria-label="answer-slider"
        defaultValue={50}
        step={10}
        marks={sliderValues}
        getAriaValueText={currentAriaValue}
        onChange={(e) => setCurrentSliderValue(e.target.value)}
        value={currentSliderValue}
      />
    </Box>
  );
};
