import { Box, Card, Grid } from '@mui/material';
import { useState } from 'react';
import { ExpandedImage } from '../Modals/expandedImage';
import { v4 as uuidv4 } from 'uuid';
import { DragIndicator } from '@mui/icons-material';

const { REACT_APP_CLOUD_BUCKET_URL } = process.env;

export const CampaignPicturesView = ({ pic, width = '100%' }) => {
  const [openExpandedImageModal, setOpenExpandedImageModal] = useState(false);

  const handleExpandedImageModalOpen = () => {
    setOpenExpandedImageModal(true);
  };

  const handleExpandedImageModalClose = () => {
    setOpenExpandedImageModal(false);
  };

  return (
    <>
      <Card sx={{ p: 2, width: width }}>
        <img
          src={
            pic?.image ? pic.image : `${REACT_APP_CLOUD_BUCKET_URL}${pic?.name}`
          }
          // style={{ minWidth: '150px', maxWidth: '150px' }}
          width='100%'
          alt=''
          onClick={handleExpandedImageModalOpen}
        />
      </Card>

      <ExpandedImage
        picture={
          pic?.image ? pic.image : `${REACT_APP_CLOUD_BUCKET_URL}${pic?.name}`
        }
        handleClose={handleExpandedImageModalClose}
        openModal={openExpandedImageModal}
      />
    </>
  );
};
