import React, { useState, createRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CampProducts from '../../pages/campProducts';
import CampHeader from '../CampaignConfig/campHeader';
import Report from '../../pages/report';
import { QuestionManager } from '../CampaignConfig/questionManager';
import SaveBar from '../CampaignConfig/SaveBar';
import { usePrompt } from '../CampaignConfig/Routing';
import { convertImage } from '../helpers';
import { CampConfigFormFeatureOptions } from './campConfigFormFeatureOptions';
import { useEffect } from 'react';
import { CampConfigFormStatusBannerAndName } from './campConfigFormStatusBannerAndName';
import { CampConfigFormBrand } from './campConfigFormBrand';
import { CampConfigFormDateStatusMaxResultsOptions } from './campConfigFormDateStatusMaxResultsOptions';
import { CampConfigFormButtonGroup } from './campConfigFormButtonGroup';

export const CampConfigForm = (props) => {

  const {
    values,
    touched,
    errors,
    handleChange,
    formik,
    currentCampDataStatus,
    statuses,
    previousProducts,
    setIsFormDirty,
    campDataList,
    setCampDataList,
    handleIncomingSelectedQuestions,
    resetCampaign,
    setCurrentCampDataStatus,
    setIsANewCampaign,
    isFormDirty,
    handleCurrentStatus,
    handleCurrentStatusLabel,
    isANewCampaign,
    campaignBrands,
    localQuestions,
    campaignQuestionTypes,
    selectedQuestions,
    setSelectedQuestions,
    handleQuestionModalOpen,
    handleDeleteModalOpen,
    saveCampaign,
    disableSaveCampaign,
    setDisableSaveCampaign,
    duplicateCampaignSettings,
    setDuplicateCampaignSettings,
    originalCampStatus,
    setOriginalCampStatus,
    originalCampEndDate,
    originalCampStartDate,
    setOriginalCampStartDate,
    setOriginalCampEndDate,
    originalSurveyOnly,
    setOriginalSurveyOnly,
    setNewCampaignId,
    campaignNameList,
    setCampaignNameList,
    enqueueSnackbar,
    campId,
    setCampId
  } = props;

  const newCampaignRef = createRef();

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave this page?',
    isFormDirty
  );

  const focusOnNewCampaignName = () => newCampaignRef.current.focus();

  const [campProdOpen, setCampProdOpen] = useState(false);
  const [reportingScreenOpen, setReportingScreenOpen] = useState(false);

  const handleAddEndingPageImage = async (e) => {
    setIsFormDirty(true);
    let convertedImage = '';
    if (e.target.files) {
      const file = e.target.files[0];
      await convertImage(URL.createObjectURL(file)).then((result) => {
        convertedImage = result;
      });
      formik.setFieldValue('campEndingPageImage', convertedImage);
      formik.setFieldValue('campEndingPageImageFileName', file.name);
    }
    if (duplicateCampaignSettings.warningOptions.toggleText) {
      setDuplicateCampaignSettings({ ...duplicateCampaignSettings, warningOptions: { ...duplicateCampaignSettings.warningOptions, toggleText: false } })
    }
  };

  const handleDuplicateCampaignOnToggle = (e) => {

    // if there is a campaign ID, remove some key data that will require changing...
    if (!isNaN(values.campaignId)) {

      const { duplicated, warningOptions } = duplicateCampaignSettings;

      formik.setFieldValue('campName', '');
      formik.setFieldValue('campUrl', '');
      formik.setFieldValue('campaignId', '');
      formik.setFieldValue('campMaxResults', 0);

      if (Boolean(values.campEndingPageEnabled)) {
        formik.setFieldValue('campEndingPageImage', '');
        formik.setFieldValue('campEndingPageImageFileName', '');
      }

      if (duplicated) {
        setDuplicateCampaignSettings({ ...duplicateCampaignSettings, selectedCampaign: false, duplicated: !duplicated })
        resetCampaign();
      } else {
        // resets campaign config to new campaign when clicking off campaign, could filter the specific campaignId (if to store in state) and filter to get original back?
        setDuplicateCampaignSettings({ ...duplicateCampaignSettings, warningOptions: { ...warningOptions, toggleText: true }, duplicated: !duplicated })
        enqueueSnackbar('Duplication selected, please input a new campaign name and url', { variant: 'warning' })
        // setTimeout(() => {
        // set duplication to show that this campaign name needs adding as well as campaign url.
        // }, [3000])
      }
    }

    setIsANewCampaign(true);

  }

  useEffect(() => {
    const { warningOptions } = duplicateCampaignSettings;
    if (warningOptions?.toggleText && (values.campName.length > 0 || values.campUrl.length > 0)) {
      setDuplicateCampaignSettings({ ...duplicateCampaignSettings, warningOptions: { ...warningOptions, toggleText: false } })
    }
  }, [duplicateCampaignSettings, setDuplicateCampaignSettings, values.campName, values.campUrl])

  const manageCampEndingPage = (e) => {
    if (e.target.value === 'Management') {
      formik.setFieldValue('campEndingPageEnabled', false);
      formik.setFieldValue('campEndingPageText', "");
      formik.setFieldValue('campEndingPageImage', "");
      formik.setFieldValue('campEndingPageImageFileName', "");
    } else if ((values.campType === 'Management' || values.campType === "") && e.target.value === 'Consumer') {
      formik.setFieldValue('campEndingPageEnabled', false);
    } else { // if e.target.value === 'Consumer'
      formik.setFieldValue('campEndingPageEnabled', Boolean(!values.campEndingPageEnabled));
    }

    formik.setFieldValue('campType', e.target.value);
    setIsFormDirty(true);
  }

  return (
    <Box sx={{ pb: 10, mb: 4 }}>
      {campProdOpen ? (
        <CampProducts
          handleProdOpen={setCampProdOpen}
          campData={values}
          campaignType={values.campType}
          campaignUrl={values.campUrl}
          setFormikFields={formik.setFieldValue}
          previousProducts={previousProducts}
          currentCampDataStatus={currentCampDataStatus}
          setIsFormDirty={setIsFormDirty}
          enqueueSnackbar={enqueueSnackbar}
        />
      ) : reportingScreenOpen ? (
        <Report handlePageOpen={setReportingScreenOpen} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Box marginBottom={"1em"}>
            <CampHeader
              setCampId={setCampId}
              data={values}
              campDataList={campDataList}
              setCampDataList={setCampDataList}

              campaignNameList={campaignNameList}
              setCampaignNameList={setCampaignNameList}

              handleIncomingSelectedQuestions={
                handleIncomingSelectedQuestions
              }
              resetCampaign={resetCampaign}
              setCurrentCampDataStatus={setCurrentCampDataStatus}
              setIsANewCampaign={setIsANewCampaign}
              focusOnNewCampaignName={focusOnNewCampaignName}
              handleCurrentStatus={handleCurrentStatus}
              handleCurrentStatusLabel={handleCurrentStatusLabel}
              isFormDirty={isFormDirty}
              setIsFormDirty={setIsFormDirty}
              setFormikValues={formik.setValues}
              setFormikFields={formik.setFieldValue}
              formikSetErrors={formik.setErrors}
              formikSetTouched={formik.setTouched}
              duplicateCampaignSettings={duplicateCampaignSettings}
              setDuplicateCampaignSettings={setDuplicateCampaignSettings}

              originalCampStatus={originalCampStatus}
              setOriginalCampStatus={setOriginalCampStatus}
              originalSurveyOnly={originalSurveyOnly}
              setOriginalSurveyOnly={setOriginalSurveyOnly}
              setNewCampaignId={setNewCampaignId}
              originalCampStartDate={originalCampStartDate}
              setOriginalCampStartDate={setOriginalCampStartDate}
              setOriginalCampEndDate={setOriginalCampEndDate}
            />
          </Box>

          <Box marginBottom={"1em"}>
            <CampConfigFormStatusBannerAndName
              isANewCampaign={isANewCampaign}
              currentCampDataStatus={currentCampDataStatus}
              newCampaignRef={newCampaignRef}
              values={values}
              touched={touched}
              handleChange={handleChange}
              setIsFormDirty={setIsFormDirty}
              formik={formik}
              setFieldValue={formik.setFieldValue}
              duplicateCampaignSettings={duplicateCampaignSettings}
              setDuplicateCampaignSettings={setDuplicateCampaignSettings}
              enqueueSnackbar={enqueueSnackbar}
            />
          </Box>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Campaign Configuration</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <CampConfigFormBrand
                values={values}
                touched={touched}
                handleChange={handleChange}
                setIsFormDirty={setIsFormDirty}
                errors={errors}
                newCampaignRef={newCampaignRef}
                currentCampDataStatus={currentCampDataStatus}
                duplicateCampaignSettings={duplicateCampaignSettings}
                setDuplicateCampaignSettings={setDuplicateCampaignSettings}
                handleDuplicateCampaignOnToggle={handleDuplicateCampaignOnToggle}
                originalCampStatus={originalCampStatus}
                originalCampEndDate={originalCampEndDate}
                originalCampStartDate={originalCampStartDate}
                campaignBrands={campaignBrands}
              />

              <CampConfigFormFeatureOptions
                values={values}

                currentCampDataStatus={currentCampDataStatus}
                handleChange={handleChange}
                setIsFormDirty={setIsFormDirty}
                formik={formik}
                touched={touched}
                errors={errors}
                manageCampEndingPage={manageCampEndingPage}
                campaignBrands={campaignBrands}
                handleAddEndingPageImage={handleAddEndingPageImage}
                duplicateCampaignSettings={duplicateCampaignSettings}
                setDuplicateCampaignSettings={setDuplicateCampaignSettings}
                campId={campId}
              />

              <QuestionManager
                localQuestions={localQuestions}
                campaignQuestionTypes={campaignQuestionTypes}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                handleQuestionModalOpen={handleQuestionModalOpen}
                disabled={currentCampDataStatus === 3}
                setIsFormDirty={setIsFormDirty}
              />

              <CampConfigFormDateStatusMaxResultsOptions
                values={values}
                handleChange={handleChange}
                setIsFormDirty={setIsFormDirty}
                touched={touched}
                errors={errors}
                statuses={statuses}
                enqueueSnackbar={enqueueSnackbar}
                duplicateCampaignSettings={duplicateCampaignSettings}
                setDuplicateCampaignSettings={setDuplicateCampaignSettings}
                handleDuplicateCampaignOnToggle={handleDuplicateCampaignOnToggle}
              />
            </AccordionDetails>
          </Accordion>

          <Box marginTop={"1em"}>
            <CampConfigFormButtonGroup
              values={values}
              setCampProdOpen={setCampProdOpen}
              originalSurveyOnly={originalSurveyOnly}
              handleDeleteModalOpen={handleDeleteModalOpen}
              isANewCampaign={isANewCampaign}
              localQuestions={localQuestions}
            />
          </Box>

          <SaveBar
            disableSaveCampaign={disableSaveCampaign}
            isFormDirty={isFormDirty}
          />
        </form>
      )}
    </Box>
  )
}